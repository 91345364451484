<template>
  <div>
    <ScrollTop />

    <PageTitle title="Мои пары"></PageTitle>
    <div class="pure-g padding container-sm" :class="{ 'empty-block-wrapper': isEmpty }">
      <div class="pure-u-1-2 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4" v-for="p in pairs" :key="p.from_id">
        <router-link :to="{ name: 'Profile', params: { uid: p.from_id } }">
          <ProfileCard class="margin-b" :uid="p.from_id" :date="p.date" />
        </router-link>
      </div>
      <div class="pure-u-1 loading-block" v-if="isPageLoading">
        <VueElementLoading :active="true" size="40" />
      </div>
      <div class="pure-u-1 empty" v-if="isEmpty">
        <div class="txt-c">
          <p>
            <img src="@/assets/img/empty/hearts1.svg" />
          </p>
          <p>
            Здесь будут те, кто поставил вам взаимный лайк, а пока тут пусто.
            Как можно больше ставьте лайки в игре и ждите взаимных!
          </p>
          <router-link :to="{ name: 'Near' }" class="btn btn-red">
            Поиск
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/empty-list.css'

import { mapGetters } from "vuex";

import { apiCall, SERVER_ADDRESSES } from "@/utils/api";

import PageTitle from '@/components/PageTitle.vue'
import ProfileCard from '@/components/ProfileCard.vue'
import ScrollTop from '@/components/ScrollTop.vue'

export default {
  name: 'Pairs',
  components: {
    PageTitle, ProfileCard, ScrollTop
  },
  data: function () {
    return {
      pairs: [],
      pageSize: 50,
      isPageLoading: false,
      isLoadedToEnd: false,
      scrollElement: null
    }
  },
  mounted: function () {
    this.loadPage()
    this.scrollElement = document.querySelector('#app > .kvisto-app')
    this.scrollElement.addEventListener("scroll", this.handleScroll)
  },
  unmounted: function () {
    this.scrollElement.removeEventListener("scroll", this.handleScroll)
  },
  computed: {
    ...mapGetters(["myEvents"]),
    isEmpty() {
      return this.pairs.length == 0 && !this.isPageLoading
    }
  },
  watch: {
    myEvents: {
      handler(newVal) {
        if (!newVal || !newVal.pairs) {
          return
        }
        this.$store.dispatch('DECREMENT_MY_EVENT', {
          type: 'pairs',
          value: newVal.pairs
        });
      },
      immediate: true
    }
  },
  methods: {
    loadPage() {
      this.isPageLoading = true
      this.loadPeople().then(pairs => {
        if (pairs.length < this.pageSize) {
          this.isLoadedToEnd = true
        }
        let uids = pairs.map(p => p.from_id)
        this.$store.dispatch("LOAD_USERS", {uids})
        this.pairs = [...this.pairs, ...pairs]
        this.isPageLoading = false
      })
    },
    async loadPeople() {
      let url = SERVER_ADDRESSES.users + "pairs?limit=" + this.pageSize,
        fromDate = this.pairs.length > 0 ? this.pairs[this.pairs.length - 1].date : ""
      if (fromDate) {
        url += '&from_date=' + encodeURIComponent(fromDate)
      }
      var resp = await apiCall({ url: url, method: "GET" })
      return resp.swipes
    },

    handleScroll() {
      if (this.isLoadedToEnd) {
        return
      }
      let body = this.scrollElement.children[0],
        p = body.parentNode,
        bottomOffset = (p.scrollHeight - p.clientHeight) - (body.scrollTop || p.scrollTop)
      if (!this.isPageLoading && bottomOffset < 1000) {
        this.loadPage()
      }
    }
  }
}
</script>


<style scoped>
.loading-block {
  min-height: 5rem;
}
</style>