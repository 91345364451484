<template>
  <form @submit.prevent="submit">
    <button class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <h4 v-if="hasEmail">Изменить email</h4>
      <h4 v-else>Добавить email</h4>

      <div v-if="hasEmail" class="form-field" v-bind:class="{ error: (!email || errData.email) && showErrors }">
        <label>Ваш текущий email</label>
        <input type="email" v-model="email" />
      </div>

      <div class="form-field" v-bind:class="{ error: (!new_email || errData.new_email) && showErrors }">
        <label>Новый email</label>
        <input type="email" v-model="new_email" />
      </div>

      <div class="form-field" v-bind:class="{ error: (!password || errData.password) && showErrors }">
        <label>Пароль</label>
        <PasswordInput v-model="password" />
      </div>

      <div class="padding-b txt-c text-red" v-if="showErrors && errCode != 0">
        {{ errMessage }}
      </div>

      <div class="footer-modal">
        <button class="btn full-width btn-link" type="submit">Изменить</button>
        <VueElementLoading :active="loading" />
      </div>
    </CustomScrollbar>

  </form>
</template>

<script>
import { popModal } from "jenesius-vue-modal"

import { apiCall, SERVER_ADDRESSES } from "@/utils/api"

import PasswordInput from '@/components/inputs/PasswordInput.vue'

import { apiErrCodes } from "@/utils/api"

export default {
  name: 'FormEditEmail',
  components: {
    PasswordInput
  },
  emits: ['updated'],
  data: function () {
    return {
      showErrors: false,
      errCode: 0,
      errData: {},
      email: "",
      new_email: "",
      password: "",
      loading: false
    }
  },
  props: {
    hasEmail: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errMessage() {
      if (this.errCode == apiErrCodes.DUPLICATE_ERROR) {
        return "Указанный email уже используется"
      } else if (this.errCode == apiErrCodes.THE_SAME_INPUT_ERROR) {
        return "Новый email совпадает с текущим"
      } else if (this.errCode == apiErrCodes.PASSWORD_ERROR) {
        return "Введен неверный пароль"
      } else if (this.errCode == apiErrCodes.INVALID_VALUE_ERROR && this.errData.email) {
        return "Введен неверный текущий email"
      } else if (this.errCode == apiErrCodes.INVALID_VALUE_ERROR) {
        return "Введен некорректный email или его использование недоступно"
      }
      return "Неизвестная ошибка. Попробуйте позже или обратитесь к технической поддержке."
    }
  },
  methods: {
    close() {
      popModal()
    },
    submit() {
      this.errCode = 0
      this.errData = {}
      this.showErrors = false
      if ((this.hasEmail && !this.email) || !this.new_email || !this.password) {
        this.showErrors = true
        return
      }

      this.loading = true
      apiCall({
        url: SERVER_ADDRESSES.auth + "update/email", method: "POST", data: {
          email: this.email,
          new_email: this.new_email,
          password: this.password
        }
      }).then((resp) => {
        this.loading = false
        if (!resp.success || resp.err_code) {
          this.errCode = resp.err_code ?? -1
          this.errData = resp.err_data ?? {}
          this.showErrors = true
        } else {
          this.$store.dispatch("AUTH_REQUEST", {
            "grant_type": "password",
            "username": this.new_email,
            "password": this.password
          }).then(() => {
            this.$emit('updated')
            this.close()
          }).catch(() => {
            this.errCode = -1
            this.showErrors = true
          })
        }
      }).catch((e) => {
        this.loading = false
        this.errCode = e.err_code ?? -1
        this.errData = e.err_data ?? {}
        this.showErrors = true
      })
    }
  }
}
</script>


<style scoped>
form {
  width: 30rem;
}

h4 {
  margin: 0 0 1.5rem;
  font-weight: 500;
  color: #222020;
  font-size: 1.5rem;
}

.form-field {
  margin: .5em 0;
}

label {
  display: block;
  position: relative;
  font-weight: 600;
  font-size: .8em;
  margin-bottom: .5em;
  color: #222020;
  background-color: #fff;
  top: 1em;
  left: .5em;
  padding: 0 .5em;
  z-index: 1;
}

.form-field label,
.form-field .hint {
  display: inline-block;
  margin: .5em;
}

.modal-container .footer-modal {
  margin-top: 2rem;
}

a {
  font-weight: 500;
}
</style>