<template>
  <div class="hidden">
    <img :src="url" v-for="url in photos" :key="url" rel="preload" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'PreloaderAvatars',
  props: {
    "uids": {
      type: Array,
      required: true
    },
    "type": { // orig, face_sm, face_md
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters(["avatarsStore"]),
    photos() {
      this.$store.dispatch("LOAD_AVATARS", this.uids)
      let list = []
      for(var uid of this.uids) {
        if (uid in this.avatarsStore && this.avatarsStore[uid]) {
          list.push(this.avatarsStore[uid].thumbs[this.type].url)
        }
      }
      return list
    }
  },
}
</script>