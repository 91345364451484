<template>
  <div class="logout-modal">
    <button class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <h4>Выход из Kvisto</h4>
      <p>Вы точно хотите выйти?</p>
      <p>Вам заново придется входить через социальные сети или вспоминать логин и пароль.</p>
      <div class="footer-modal pure-g">
        <button class="btn pure-u-1-2 btn-link btn-red" type="submit" @click="logout">Да, выйти</button>
        <button class="btn pure-u-1-2 btn-link" type="submit" @click="close">Остаться</button>
      </div>
    </CustomScrollbar>

  </div>
</template>

<script>
import { popModal } from "jenesius-vue-modal"

export default {
  name: 'LogoutModal',
  methods: {
    close() {
      popModal()
    },
    logout() {
      this.close()
      this.$store.dispatch("AUTH_LOGOUT")
      this.$router.push('/')
    }
  }
}
</script>


<style scoped>
.logout-modal {
  width: 30rem;
}

h4 {
  margin: 0 0 1.5rem;
  font-weight: 500;
  color: #222020;
  font-size: 1.5rem;
}

.footer-modal {
  margin-top: 2.5rem;
}
</style>