<template>
  <div>
    <button v-if="!isUploading" class="btn btn-modal-close" type="button" @click="close"><i
        class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <h4 class="no-margin-t txt-c">{{ title }}</h4>
      <h5 class="txt-c">Загрузить фотографии из</h5>
      <div class="pure-g container-sm">
        <div class="pure-u-1 pure-u-sm-1-2 margin-b">
          <a href="#" class="btn btn-border btn-vk full-width">
            <i class="icon-vk-solid"></i> <span>ВКонтакте</span>
          </a>
        </div>
        <div class="pure-u-1 pure-u-sm-1-2 margin-b">
          <a href="#" class="btn btn-border btn-odnoklassniki full-width">
            <i class="icon-odnoklassniki"></i> <span>Одноклассники</span>
          </a>
        </div>
      </div>

      <CustomScrollbar :style="{ 'max-height': '60vh' }">
        <div class="drop-active padding-half-h">
          <label :for="uploadInputId" class="drop-files block-vcenter txt-c" v-if="files.length == 0">
            <div class="title"><i class="icon-plus smaller margin-half-r"></i> Загрузить с комьютера</div>
            <div class="drag-title">Или просто перетащите сюда</div>
          </label>

          <div v-show="files.length > 0" class="pure-g container-sm">
            <template v-for="file in files">
              <div class="pure-u-1-3 pure-u-sm-1-5 margin-half-v file uploading"
                v-if="!file.success || file.response.err_code != 0" :key="file.id + 'uploading'">
                <div class="photo photo-cover" :style="{ 'background-image': 'url(' + file.url + ')' }" :class="{
                  'error': file.response.err_code && file.response.err_code != 0
                }">
                  <div class="top padding-half" v-if="!isUploading && file.response.err_code == 2">
                    <i class="icon-exclamation-circle"></i>
                  </div>
                  <div class="top txt-r" v-if="!isUploading && file.progress == 0">
                    <button @click="removeFile(file)" class="btn btn-transparent btn-sm"><i
                        class="icon-times"></i></button>
                  </div>

                </div>
                <div class="progressbar margin-half-t" :class="{
                  'error': file.response.err_code && file.response.err_code != 0
                }" v-if="file.progress > 0">
                  <div class="bar" v-bind:style="{ 'width': + file.progress + '%' }"></div>
                </div>
              </div>
            </template>

            <div class="pure-u-1-3 pure-u-sm-1-5 margin-half-v">
              <file-upload class="upload photo block-vcenter txt-c" name="file" :post-action="uploadUrl" :multiple="true"
                :drop="true" :thread="1" :headers="headers" :input-id="uploadInputId" accept="image/png,image/jpeg"
                v-model="files" ref="upload" @input-filter="inputFile">

                <span class="upload-icon">
                  <i class="icon-plus"></i>
                </span>

              </file-upload>
            </div>
          </div>
        </div>
      </CustomScrollbar>

      <div class="txt-c hint padding">
        Допустимы форматы файлов JPG и PNG. Размер файлов не должен превышать 32МБ.
      </div>

      <div class="txt-c error padding" v-if="imagesWithError.length">
        <i class="block margin-v icon-exclamation-circle"></i>

        Эти изображения не могут быть фотографиями профиля.
        <br />
        Пожалуйста, загрузите фотографии, где вас хорошо видно

      </div>

      <div class="footer-modal">
        <div class="pure-g" v-show="!isUploading">
          <button class="pure-u-1-2 btn" @click="close" v-if="notUploadedImages.length == files.length">Не сейчас</button>
          <label class="pure-u-1-2 btn btn-link" :for="uploadInputId" v-if="!files.length">Загрузить фото</label>
          <button class="btn btn-link" :class="{
            'pure-u-1-2': notUploadedImages.length == files.length,
            'pure-u-1': notUploadedImages.length != files.length
          }" @click="startUpload" v-else-if="notUploadedImages.length">Загрузить фото</button>
          <button class="pure-u-1 btn btn-link" @click="close" v-else>Завершить</button>
        </div>
        <button class="btn disabled full-width" disabled v-if="isUploading">
          Дождитесь завершения загрузки...
        </button>
      </div>
    </CustomScrollbar>

  </div>
</template>

<script>
import { popModal } from "jenesius-vue-modal"

import FileUpload from 'vue-upload-component'
import { apiAuthHeaders, SERVER_ADDRESSES } from '@/utils/api'

export default {
  name: 'UploadPhotos',
  components: {
    FileUpload
  },
  props: {
    "title": {
      type: String,
      default: "Загрузите фото профиля",
      required: false,
    },
    "uid": {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      uploadInputId: "upload_input",
      isUploading: false,
      files: []
    }
  },
  computed: {
    uploadUrl: function () {
      return SERVER_ADDRESSES.photos + this.uid + "/add?type=file"
    },
    headers: function () {
      return apiAuthHeaders()
    },
    notUploadedImages() {
      return this.files.filter(f => !f.success)
    },
    imagesWithError() {
      return this.files.filter(f => f.response.err_code && f.response.err_code != 0)
    }
  },
  // beforeModalClose(){
  //   if (this.isUploading) {
  //     return false;
  //   }
  // },
  methods: {
    close() {
      popModal()
    },
    startUpload() {
      this.isUploading = this.notUploadedImages.length
      if (this.notUploadedImages.length) {
        this.$refs.upload.update(this.notUploadedImages[0], { active: true })
      }
    },
    removeFile(file) {
      this.$refs.upload.remove(file)
    },
    createTemporaryUrl(file) {
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        return URL.createObjectURL(file)
      }
      return ''
    },
    inputFile(newFile, oldFile/*, prevent*/) {
      if (newFile && !oldFile) { // file added
        newFile.url = this.createTemporaryUrl(newFile.file)
      }

      if (newFile && oldFile) {  // file updated
        if (newFile.success !== oldFile.success) {  // Uploaded successfully
          this.$nextTick(() => {
            this.startUpload()  // start upload net image
            if (!this.isUploading) {
              this.$emit('update')
              this.$store.dispatch("REQUEST_MY_PHOTOS")
              this.$store.dispatch("REQUEST_MY_PROFILE")
            }
          })
        }
      }
    },
  }
}
</script>


<style>
.drop-active label {
  cursor: pointer !important;
}
</style>


<style scoped>
h4 {
  margin: 0 0 1.5rem;
  font-weight: 500;
  color: #222020;
  font-size: 1.5rem;
}

h5 {
  font-weight: 400;
  color: #646363;
}

.title {
  color: #FF426F;
  font-size: 1.4rem;
  line-height: 120%;
}

.drag-title {
  padding: 1rem 0 0;
  font-size: .95rem;
  color: #8a8989;
}

.drop-active {
  background-color: #F2F2F2;
  border-radius: 7px;

  transition: background-color .2s ease-in;
}
.drop-active:hover {
  background-color: #e9e9e9;
}

.drop-active .drop-files {
  cursor: pointer;
  min-height: 10rem;
  flex-direction: column;
}

.drop-active .photo {
  position: relative;
  padding: 50% 0;
  border-radius: .5rem;
}

.drop-active .photo.error {
  border: 1px solid #FF3131;
}

.drop-active .photo.photo-cover {
  box-shadow: inset -1rem 0 5rem -15rem rgba(0, 0, 0, 0.5), inset -1rem 15rem 5rem -15rem rgba(0, 0, 0, 0.5);
}

.drop-active .upload.photo {
  display: flex;
  width: 100%;
  border: 1px solid #CFCFCF;
}

.drop-active .upload.photo .upload-icon {
  position: absolute;
  font-size: 1.4rem;
  color: #FF426F;
}

.progressbar {
  height: .25rem;
  background-color: #FFF;
  border-radius: 1rem;
  overflow: hidden;
}

.progressbar .bar {
  height: 100%;
  background-color: #00C667;
  border-radius: 1rem;
}

.progressbar.error .bar {
  background-color: #FF3131;
}

.icon-exclamation-circle,
.error {
  color: #FF3131;
}

.top,
.bottom {
  display: block;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  width: 100%;
  color: #FFF;
}

.top {
  top: 0;
  font-size: .8rem;
}

.bottom {
  bottom: 0;
}

.btn-vk i, .btn-odnoklassniki i {
  display: inline-block;
  text-align: center;
  position: relative;
  top: .1em;
  font-size: 1em;
  width: 2em;
  margin-left: -1em;
  height: 1em;
}
</style>