import 'floating-vue/dist/style.css'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import './assets/css/kvisto-font.css'
import './assets/css/grids.css'
import './assets/css/fw.css'
import './assets/css/controls.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CustomScrollbar from 'custom-vue-scrollbar';
import 'custom-vue-scrollbar/dist/style.css';

import Maska from 'maska'  // masked inputs
import VueElementLoading from "vue-element-loading";
import FloatingVue from 'floating-vue'  // tooltips, menus, popups, etc

// import VueSelect from 'vue-next-select'  // custom dropdown
// import 'vue-next-select/dist/index.min.css'



import VueNativeSock from "vue-native-websocket-vue3";

import moshaToast from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'

import Sticky from 'vue3-sticky-directive'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Maska)
app.use(FloatingVue)
app.use(moshaToast)
app.use(Sticky)

import {SERVER_ADDRESSES} from "@/utils/api"
app.use(VueNativeSock, SERVER_ADDRESSES.ws, {
  connectManually: true,
  reconnection: true,
  reconnectionAttempts: 15,
  reconnectionDelay: 10000,
  format: "json"
});

app.component("CustomScrollbar", CustomScrollbar)
// app.component("VueSelect", VueSelect)
app.component('VueSelect', vSelect)
app.component("VueElementLoading", VueElementLoading)

app.mount('#app')