<template>
  <GridOffset>
    <Header/>
    <article class="max-width">
      <h1>Политика конфиденциальности персональных данных пользователей Jeempo</h1>
      <p>
        Jeempo.com уважает право своих пользователей на конфиденциальность и разработало данную Политику конфиденциальности, чтобы продемонстрировать свое обязательство по защите конфиденциальности. В данной Политике конфиденциальности описывается собираемая информация  и то, каким образом информация используется, кому она может передаваться и выбор пользователей касательно такого использования и раскрытия. 
      </p>
      <p>
        <strong>Пожалуйста, внимательно прочитайте данные условия политики конфиденциальности, прежде чем начнете пользоваться интернет-сервисом jeempo.com.</strong>
      </p>
      <p>
        Пользуясь интернет-сервисом jeempo.com вы подтверждаете, что прочитали, поняли, приняли, акцептовали и полностью согласны соблюдать настоящую политику конфиденциальности. Любой пользователь считается таковым с момента регистрации на сайте и (или) совершения иных действий, связанных с использованием сервисов или возможностей сайта и до момента прекращения обязательств сторон по основаниям, предусмотренным законодательством РФ.
      </p>
      <p>
        <strong>Если вы не согласны с условиями политики конфиденциальности, пожалуйста, не используйте интернет-сервис jeempo.com</strong>. Если вы не согласны с условиями политики конфиденциальности, Вы не можете пользоваться сайтом.
      </p>
      <h2>1. Термины и определения.</h2>
      <p>
        <strong>Политика конфиденциальности персональных данных пользователей</strong> (далее политика конфиденциальности, соглашение, оферта) – оферта, адресованная неограниченному кругу лиц и представленная в настоящем документе.
      </p>
      <p>
        <strong>Сторона политики конфиденциальности</strong> – сайт, пользователь.
      </p>
      <p>
        <strong>Сайт (веб-сайт, интернет-сервис jeempo.com, jeempo.com)</strong> -  сайт располагающийся по интернет-адресу: www.jeempo.com. Интернет-сервис jeempo.com является сложным объектом интеллектуальной собственности, представляющей собой совокупность программ для ЭВМ, баз данных, текстовой, графической и иной информации и/или объектов доступных для пользователя.  Сайт является информационным посредником, вспомогательным ресурсом предоставляющим пользователям возможность дистанционного общения и знакомства, не заменяющим собой непосредственное личное общение между пользователями.
      </p>
      <p>
        <strong>Пользователь</strong> – дееспособное физическое лицо, достигшее 18 лет, пользующееся сайтом на условиях, определенных Пользовательским соглашением и настоящей политикой конфиденциальности. Пользователь подтверждает и гарантирует, что достиг восемнадцатилетнего возраста, является полностью дееспособным физическим лицом, полностью оценивает риски и самостоятельно принимает решение пользоваться сайтом. Пользователь не ограничен в дееспособности по законодательству государства, гражданином которого является.
      </p>
      <p>
        <strong>Данные пользователя</strong> – персональные данные.
      </p>
      <p>
        <strong>Персональные данные</strong> – личная информация (в том числе фамилия, имя, отчество, дата рождения, адрес, контактный телефон, адрес электронной почты), добровольно и осознанно предоставляемая пользователем-физическим лицом при регистрации на сайте и необходимая для доступа пользователя к возможностям интернет-сервиса jeempo.com.
      </p>
      <p>
        <strong>Регистрация</strong> – процедура передачи пользователем информации о себе, а также своих персональных данных, необходимая для предоставления доступа к возможностям сайта. Порядок регистрации (создание учетной записи) устанавливается с учетом технических особенностей и возможностей сайта.
      </p>
      <p>
        <strong>Авторизация</strong> – процедура ввода пары логин/пароль на сайте, направленная на идентификацию авторизующегося лица в качестве конкретного пользователя сайта и необходимая для использования возможностей сайта. Пользователь обязан проходить авторизацию при каждом новом посещении сайта для использования его возможностей. Подробный порядок авторизации устанавливается с учетом технических возможностей сайта. 
      </p>
      <p>
        <strong>Логин</strong> – придуманная при регистрации пользователем комбинация латинских букв и/или цифр.
      </p>
      <p>
        <strong>Пароль</strong> - придуманная при регистрации пользователем комбинация латинских букв и/или цифр.
      </p>
      <p>
        <strong>Учетная запись пользователя</strong> – аккаунт, состоящий из данных пользователя, предоставленных пользователем фотоизображений, информации о пользователе, логина/пароля, используемых для идентификации пользователя и присваиваемых ему в ходе регистрации, другого контента, размещаемого пользователем.
      </p>
      <p>
        <strong>Счет учетной записи</strong> - счет пользователя в соответствующем разделе учетной записи, на котором учитываются платежи пользователя для доступа к платным возможностям сайта. Счет является элементом программно-аппаратного комплекса, интегрированного сайтом, предназначенным в том числе для осуществления сайтом внутреннего учета обязательств настоящего Соглашения сайта перед пользователем и пользователем перед сайтам. Счет учетной записи не является счетом, открытыми кредитными организациями (банками) и/или платежными системами, а также счетами депозитария.
      </p>
      <h2>2. Гарантия и заверения.</h2>
      <p>
        Сайт и пользователь настоящим заявляет и гарантирует другому, что на дату заключения соглашения и в период его действия:
      </p>
      <ul>
        <li>является субъектом, действующим в соответствии с российским законодательством, обладает всеми правами и всеми полномочиями, необходимыми для ведения деятельности на территории РФ;</li>
        <li>обладает всеми полномочиями для заключения настоящего соглашения и исполнения обязательств, принимаемых на себя по соглашению;</li>
        <li>предпринял все действия, необходимые для заключения и исполнения соглашения, и для обеспечения того, чтобы соглашение являлось законным, обязательным для исполнения им и подлежащим исполнению в отношении его контрагента;</li>
        <li>не существует, насколько известно, нерешенных вопросов правового характера, угрозы судебного разбирательства или иных обстоятельств, которые могут иметь негативные последствия для стороны соглашения или его деятельности, стороной соглашения не допущено нарушения каких-либо существенных положений какого-либо заключенного им договора или иного соглашения, нет постановлений, определений и решений суда, арбитражного суда или другого государственного органа, которые могут оказать отрицательное воздействие на положение стороны соглашения или их деятельность;</li>
        <li>не существует никаких положений в российском законодательстве или иных нормативных актах, в договорах, имеющих обязательную силу для стороны соглашения или имущества, которые могут нарушать или препятствовать подписанию или исполнению соглашения;</li>
        <li>исполнение обязательств по соглашению является безусловным обязательством стороны соглашения, которое, как минимум, имеет такой же приоритет, как и остальные обязательства стороны соглашения;</li>
        <li>вся информация и документы, предоставленные стороной соглашения другой стороне в связи с настоящим соглашением, соответствуют действительности, являются достоверными, полными и точными во всех отношениях, и сторона соглашения не скрывает никаких фактов, которые, если бы они стали известны, могли бы оказать неблагоприятное влияние на решение другой стороны соглашения о заключение данного соглашения;</li>
        <li>обладает достаточной информацией о своем контрагенте в объеме, необходимом для выбора его в качестве такого, понимания наличия у него достаточных ресурсов для исполнения своих обязательств по соглашению;</li>
        <li>обязательства стороны соглашения по настоящему соглашению являются законными, действительными, действующими и обязательными для исполнения стороной соглашения, подлежащими исполнению в соответствии с положениями соглашения.</li>
      </ul>
      <p>
        Каждая из сторон настоящим признает, что заключает соглашение, полагаясь на заверения и гарантии, предоставленные другой стороной и имеющие для нее существенное значение.
      </p>
      <h2>3. Общие положения.</h2>
      <p>
        Порядок обработки персональных данных пользователей, в том числе с целью обеспечения безопасности обработки персональных данных пользователей, обеспечения прав и интересов пользователей при обработке их персональных данных регулируется настоящей Политикой конфиденциальности.
      </p>
      <p>
        Любой пользователь, используя сайт или воспользовавшись любой его функциональной возможностью, выражает тем самым свое безоговорочное согласие со всеми условиями соглашения и всеми иными условиями, изложенными на страницах сайта, обязуется соблюдать их, а в случае несогласия с любым из условий обязан немедленно прекратить использование сервисов и покинуть сайт.
      </p>
      <p>
        Пользователь признает и соглашается, что ничто в Политике конфиденциальности не может пониматься как установление между пользователем и jeempo.com каких-то иных отношений, прямо не предусмотренных настоящей Политикой конфиденциальности.
      </p>
      <p>
        Настоящая Политика конфиденциальности регулируется законодательством Российской Федерации.
      </p>
      <p>
        Все возможные споры по поводу настоящей Политики конфиденциальности и отношений между пользователем и сайтом будут разрешаться по нормам российского права в суде по месту нахождения Администрации сайта, если иное прямо не предусмотрено законодательством РФ.
      </p>
      <p>
        Признание судом какого-либо положения настоящей Политики конфиденциальности недействительным или не подлежащим принудительному исполнению не влечет недействительности или неисполнимости иных положений Политики конфиденциальности.
      </p>
      <p>Бездействие со стороны сайта в случае нарушения пользователем положений Политики конфиденциальности не лишает права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает отказ jeempo.com от своих прав в случае совершения в последующем подобных либо сходных нарушений.</p>
      <p>
        Jeempo.com оставляет за собой право изменять и (или) дополнять настоящую Политику конфиденциальности без какого-либо специального уведомления пользователя. Новая редакция Политики конфиденциальности вступает в силу с момента ее размещения на странице сайта kvisto.com/privacy, если иное не предусмотрено новой редакцией Политики конфиденциальности. Действующая редакция Политики конфиденциальности всегда находится на странице сайта по адресу kvisto.com/privacy.
      </p>
      <p>
        Пользователь дает своё согласие с положениями настоящей Политики конфиденциальности нажатием кнопки «Принять Политику конфиденциальности» или «Продолжить», проставлением соответствующей отметки в поле при Регистрации, в том числе на любом этапе такой регистрации и (или) в любой момент пользования сайтом.
      </p>
      <p>
        Регистрация и авторизация на сайте осуществляется с подтверждением по СМС на мобильный телефон пользователя. Пользователю на указанный им номер мобильного телефона направляется одноразовый пароль, ввод которого обеспечивает Пользователю возможность использовать сервисы сайта.
      </p>
      <p>
        Пользователь также подтверждает, что не имеет права передавать свой логин (номер телефона) и полученный при подтверждении регистрации или авторизации по СМС одноразовый пароль третьим лицам, а также не имеет права получать логин и (или) одноразовый пароль от третьих лиц. 
      </p>
      <p>
        Сайт не несет никакой ответственности по сделкам между пользователями.
      </p>
      <p>
        Пользователь обязан незамедлительно уведомить сайт о любом нарушении безопасности своей учетной записи на сайте.
      </p>
      <p>
        Jeempo.com не несёт никакой ответственности за данные пользователя, которые он предоставляет сторонним ресурсам и/или иным третьим лицам в случае перехода на них с сайта.
      </p>
      <p>
        Jeempo.com не отвечает за возможную потерю и/или порчу данных, которая может произойти из-за нарушения пользователем положений настоящего соглашения, а также неправильного доступа и/или использования сайта.
      </p>
      <p>
        Пользователь самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с использованием сайта под учетной записью (логином) пользователя, включая случаи добровольной передачи пользователем данных для доступа к учетной записи пользователя третьим лицам на любых условиях (в том числе по договорам или соглашениям) без согласования подобного с сайтом и (или) в нарушение условий соглашения. При этом все действия в рамках или с использованием сайта под учетной записью пользователя считаются произведенными самим пользователем, за исключением случаев, когда пользователь уведомил сайт о несанкционированном доступе к сайту с использованием учетной записи пользователя.
      </p>
      <p>
        Для использования возможностей сайта, пользователь обязуется предоставить достоверную и полную информацию по вопросам, предлагаемым при Регистрации, и необходимую для выполнения обязательств со стороны сайта (в зависимости от ситуации) в отношении возможностей сайта. Пользователь обязуется поддерживать эту информацию в актуальном состоянии. По окончанию регистрации Пользователю предоставляется доступ к аккаунту по учетным данным.
      </p>
      <p>
        Jeempo.com вправе заблокировать или удалить учетную запись пользователя, а также запретить доступ с использованием какой-либо учетной записи к определенным сервисам и возможностям сайта, и удалить любой контент и информацию без объяснения причин, в том числе в случае нарушения пользователем условий соглашения и/или законодательства РФ. Факт нарушения пользователем условий соглашения и/или законодательства РФ устанавливается jeempo.com в одностороннем порядке, самостоятельно. Пользователь соглашается с таким порядком и обязуется исполнять решение сайта.
      </p>
      <p>
        Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием сайта, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение законодательства РФ при использовании сайта.
      </p>
      <p>
        Jeempo.com принимает соответствующие меры предосторожности (включая физические, электронные и процедурные меры), чтобы помочь обезопасить личную информацию пользователей от несанкционированного доступа и разглашения. Jeempo.com хочет, чтобы пользователи чувствовали себя уверенно, пользуясь сайтом. 
      </p>
      <p>
        Несмотря на предпринимаемые сайтом меры, направленные на обеспечение безопасности и конфиденциальности данных пользователей, последние обязаны также самостоятельно заботиться о том, к кому и как они обращаются и раскрывают свои личные данные.
      </p>
      <h2>4. Персональные данные и порядок их обработки.</h2>
      <p>
        В соответствии с ФЗ от 27.07.2006г. №152-ФЗ «О персональных данных» пользователь в случае, когда таковой является физическим лицом, настоящим дает свое согласие на обработку сайтом (включая получение от пользователя и/или любых третьих лиц, с учетом требований, определенных данным законом) персональных данных пользователя и подтверждает, что, давая такое согласие, действует своей волей и в своем интересе. Согласие дается Пользователем с момента регистрации на сайте и (или) совершения иных действий, связанных с использованием возможностей сайта.
      </p>
      <p>
        Согласие дается пользователем для целей заключения с jeempo.com соглашения, иных договоров, прямо предусмотренных настоящей Политикой конфиденциальности, иных соглашений, размещенных на страницах сайта, и их дальнейшего исполнения, участия в проводимых акциях, принятия решений или совершения иных действий, порождающих юридические последствия в отношении пользователя или других лиц и распространяется на фамилию, имя, отчество, адрес, номер телефона и любую иную информацию, относящуюся к личности пользователя, доступную либо известную в любой конкретный момент времени jeempo.com (далее — персональные данные), а также для принятия, обработки запроса, обработки и получения платежей; информирования о состоянии запроса, услугах сайта посредством электронных и SMS, PUSH-уведомлений; улучшения качества работы сайта; проведения статистических и иных исследований на основе обезличенных данных.
      </p>
      <p>
        Настоящее согласие дается пользователем до истечения сроков хранения соответствующей информации или документов, содержащих вышеуказанную информацию, определяемых в соответствии с законодательством РФ, после чего оно может быть отозвано пользователем путем направления соответствующего письменного уведомления jeempo.com менее чем за 3 месяца до момента отзыва согласия по адресу jeempo.com, указанному в настоящем соглашении и/или на сайте. 
      </p>
      <p>
        В случае отзыва согласия пользователя на обработку персональных данных jeempo.com вправе не прекращать обработку персональных данных и не уничтожить их, если предусмотренные законодательством РФ сроки хранения документов на момент отзыва не истекли. При этом соответствующие действия при продолжении обработки персональных данных пользователя считаются акцептом. 
      </p>
      <p>
        Настоящее согласие предоставляется на осуществление любых действий в отношении персональных данных, которые необходимы или желаемы для достижения указанных выше целей, включая, без ограничения: сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, распространение (в т.ч. передача), обезличивание, блокирование, уничтожение, трансграничную передачу персональных данных, а также осуществление любых иных действий с персональными данными пользователя с учетом действующего законодательства РФ. 
      </p>
      <p>
        Обработка персональных данных осуществляется jeempo.com с применением следующих основных способов (но, не ограничиваясь ими): хранение, запись на электронные носители и их хранение, составление перечней.
      </p>
      <p>
        Сайт обеспечивает безопасность персональных данных при их обработке, в том числе путем их шифрования и хранения в зашифрованном виде.
      </p>
      <p>
        Настоящим пользователь признает и подтверждает, что в случае необходимости предоставления персональных данных для достижения указанных выше целей третьему лицу, jeempo.com вправе в необходимом объеме раскрывать для совершения вышеуказанных действий информацию о пользователе лично (включая персональные данные) таким третьим лицам и их уполномоченным лицам, а также предоставлять таким лицам соответствующие документы, содержащие такую информацию. 
      </p>
      <p>
        Настоящим пользователь признает и подтверждает, что настоящее согласие считается данным им любым третьим лицам, указанным выше, с учетом соответствующих изменений, и любые такие третьи лица имеют право на обработку персональных данных на основании настоящего согласия.
      </p>
      <h2>5. Заключительные положения.</h2>
      <p>
        Все споры, разногласия или требования сторон, возникающие из настоящей Политики конфиденциальности или в связи с ней, в том числе касающиеся ее исполнения, нарушения, прекращения или недействительности, разрешаются путем переговоров между Сторонами. В случае невозможности урегулирования разногласий в досудебном порядке они подлежат рассмотрению в суде по месту нахождения jeempo.com если иное прямо не предусмотрено действующим законодательством РФ. Досудебный порядок разрешения споров рассматривается пользователями как обязательный претензионный и его несоблюдение препятствует непосредственному обращению любого из пользователей в соответствующий суд, срок ответа на претензию – 10 (десять) рабочих дней со дня получения.
      </p>
      <p>
        Стороны настоящей Политики конфиденциальности признают в качестве письменных доказательств электронную переписку между собой, переписку посредством смс-сообщений и/или с использованием сервисов и возможностей сайта.
      </p>
      <p>
        Любые из сторон данной Политики конфиденциальности вправе в любое время оформить собственные отношения путем подписания на бумажном носителе необходимых документов, в том числе настоящей Политики конфиденциальности.
      </p>
      <p>
        Любые обращения Пользователя к jeempo.com, прямо или косвенно связанные с обработкой его персональных данных, должны быть представлены в письменном виде и направлены по адресу Администрации, указанному в настоящем соглашении и/или на сайте.
      </p>
      <p>
        Любые обращения, уведомления пользователей рассматриваются jeempo.com при их направлении на почтовый адрес последней, указанный в настоящем соглашении и/или на сайте. Сообщение пользователя, направленное jeempo.com с использованием сервисов и возможностей сайта (например, через личный профиль) не будет рассмотрено последней, если из содержания такого сообщения не усматривается его направление на адрес сайта (в сообщении не указан номер почтового идентификатора или номер накладной при отправке с курьером; не приложены сканированные копии документов, подтверждающих направление обращения почтой и/или с курьером).
      </p>
      <h2>6. Связанная информация.</h2>
      <p>
        Сайт вправе автоматически собирать информацию браузера пользователя, когда пользователь посещает сайт. Такая информация включает IP-адрес, тип и язык браузера, место и время доступа, содержимое любых не удалённых куки-файлов, которые браузер пользователя предварительно собрал с сайта и адрес сайта, с которого перешел пользователь.
      </p>
      <p>
        Когда пользователь заходит на сайт или в мобильное приложение сайта jeempo.com может назначить устройству пользоваеля один или несколько куки-файлов, чтобы упростить доступ к сайту и персонализировать доступ пользователя. За счет использования куки-файлов сайт может также автоматически собирать информацию об онлайн-действиях на сайте, например, какие страницы посещает пользователь, время и дату посещений, ссылки, на которые нажимает пользователь, поисковые запросы по сайту. 
      </p>
      <p>
        Куки-файл – это небольшой текстовый файл, который хранится на компьютере пользователя в целях ведения учета. Куки-файлы могут быть сессионными или постоянными. Сессионный куки-файл действует до закрытия браузера и используется для упрощения навигации по сайту. Постоянный куки-файл остается на жестком диске на протяжении длительного времени. Куки на сайте не связаны с вашей личной информацией и не предназначены для ее сохранения.
      </p>
      <p>
        Во время посещений других сайтов, на которых размещена реклама сайта, jeempo.com может использовать определенные данные, собранные на сайте, чтобы показывать пользователю рекламу jeempo.com, которая может быть ему интересна. Несмотря на то, что пользователь не может отключить получение онлайн-рекламы в общем, пользователь может контролировать на сайте сбор данных, используемых для целевой рекламы jeempo.com во время посещения других сайтов. Большинство браузеров автоматически принимают куки-файлы, но пользователь, как правило, может изменять настройки своего браузера, чтобы отклонять куки-файлы. Если пользователь решит отклонить куки-файлы, jeempo.com обращает внимание, что пользователь, возможно, не сможет авторизоваться или использовать некоторые интерактивные функции, предлагаемые на сайте.
      </p>
      <p>
        Пользователь может удалить или отклонить куки, изменив настройки своего браузера (Инструкции можно найти, кликнув на «Помощь» на панели инструментов большинства браузеров).
      </p>
      <p>
        Сайт может позволить третьим лицам, рекламным компания и рекламным сетям отображать рекламные сообщения на сайте. Такие компании могут также использовать отслеживающие технологии, такие как куки, чтобы собирать информацию о пользователях, которые просматривают их рекламу или взаимодействуют с ней. 
      </p>
      <p>
        Сайт не предоставляет какую-либо личную информацию таким третьим лицам, но они могут собирать информацию о том, где пользователь или другие лица, использующие компьютер ползователя, увидели и/или кликнули на рекламу, предоставленную ими (например, информацию о потоке кликов, тип браузера, время и дата, тема рекламы, на которую нажали или которую пролистнули, и пр.), и, возможно, связывать такую информацию с вашими последующими посещениями рекламируемых сайтов. Также они могут комбинировать такую информацию с личными данными, собранными о пользователе, чтобы предоставлять рекламу о товарах и услугах, которые могут быть интересны пользователям. Сбор и использование такой информации регулируется политикой конфиденциальности третьих лиц. Такая информация позволяет им создавать целевую рекламу и оценивать ее эффективность.
      </p>
      <p>
        Jeempo.com может использовать стандартные интернет-технологии, такие как веб-маяки и прочие подобные технологии, чтобы отслеживать то, как пользователь использует сайт. Сайт может включать веб-маяки в рекламу или сообщения электронной почты для определения, были ли эти сообщения открыты и прочитаны. Информация, которую jeempo.com получает таким образом, позволяет jeempo.com настраивать услуги, которые предлагаются пользователям сайта, создавать целевую рекламу и измерять общую эффективность онлайн-рекламы, контента, программного кода и прочих мероприятий.
      </p>
      <p>
        Веб-маяки (также известные как «прозрачные гифки», «пиксельные теги» или «веб-жучки») представляют собой крошечные графические элементы с уникальными идентификаторами, по функциям похожие на куки, которые используются для отслеживания онлайн-перемещений веб-пользователей или для доступа к куки-файлам. В отличие от куки, которые сохраняются на жестком диске компьютера пользователя, веб-маяки встраиваются невидимым образом в веб-страницы (или в сообщения электронной почты), и имеют размер примерно как точка в конце этого предложения.
      </p>
      <p>
        Веб-маяки могут использоваться для доставки или связи с куки-файлами, для подсчета пользователей, которые посетили определенные страницы, и для понимания характера использования. Также сайт может получать анонимные идентификационные номера, если пользователь заходит на сайт через онлайн-рекламу, показанную на стороннем сайте. Третьи лица могут использовать анонимную информацию о ваших посещениях сайта и других сайтов в целях улучшения своей продукции и услуг, а также предоставления рекламы о товарах и услугах, которые вам интересны.
      </p>
      <p>
        Соглашаясь с настоящей политикой конфиденциальности, пользователь соглашается, что jeempo.com вправе позволять третьим лицам, включая уполномоченных поставщиков услуг, рекламным компаниям и сетям, демонстрировать рекламу на сайте. Такие компании могут использовать отслеживающие технологии, такие как куки-файлы, для сбора информации о пользователях, которые просматривают их рекламу или взаимодействуют с ней. Сайт не предоставляет никакую личную информацию таким третьим лицам. Такая информация позволяет им создавать целевую рекламу и измерять ее эффективность.
      </p>
    </article>
    <Footer/>
  </GridOffset>
</template>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import GridOffset from '@/components/GridOffset.vue'

export default {
  name: 'Privacy',
  components: {
    GridOffset, Header, Footer
  },
}
</script>