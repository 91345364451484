<template>
  <div class="pure-g container-sm">
    <div class="pure-u-6-24 form-field block-vcenter">
      <label>Имя</label>
    </div>
    <div class="pure-u-18-24 form-field" v-bind:class="{error: !profile.name && showErrors}">
      <input type="text" v-model="profile.name" placeholder="Введите имя..." />
    </div>

    <div class="pure-u-6-24 form-field block-vcenter">
      <label>Дата рождения</label>
    </div>
    <div class="pure-u-18-24 form-field block-vcenter">
      <BirthdayPicker :showErrors="showErrors" v-model="profile.birthday" />
    </div>

    <div class="pure-u-6-24 form-field block-vcenter">
      <label for="profile_edit__gender">Пол</label>
    </div>
    <div class="pure-u-18-24 form-field block-vcenter" v-bind:class="{error: !profile.gender && showErrors}">
      <GenderPicker v-model="profile.gender" />
    </div>

    <div class="pure-u-6-24 form-field block-vcenter">
      <label>Город</label>
    </div>
    <div class="pure-u-18-24 form-field block-vcenter" v-bind:class="{error: !profile.city_id && showErrors}">
      <CityPicker v-model="profile.city_id" />
    </div>

    <div class="pure-u-1-2 pure-u-lg-1-3">
      <button class="btn btn-grey full-width" @click="$emit('cancel')">Назад</button>
    </div>
    <div class="pure-u-1-2 pure-u-lg-2-3 form-field">
      <button class="btn btn-blue full-width" @click="$emit('confirm', profile)">Сохранить</button>
    </div>
  </div>
</template>

<script>
import CityPicker from '@/components/inputs/CityPicker.vue'
import GenderPicker from '@/components/inputs/GenderPicker.vue'
import BirthdayPicker from '@/components/inputs/BirthdayPicker.vue'


export default {
  name: 'ProfileEditBase',
  components: {
    CityPicker,
    GenderPicker,
    BirthdayPicker
  },
  props: {
    "user": {
      required: true
    },
  },
  data: function() {
    return {
      showErrors: false,
      genders: [
        {gender: "male", label: "Парень"},
        {gender: "female", label: "Девушка"},
      ],
      profile: {
        name: "",
        gender: "male",
        birthday: "",
        city_id: null
      }
    }
  },
  mounted: function() {
    this.setDefaults()
  },
  methods: {
    setDefaults: function() {
      if (!this.user) {
        return
      }
      this.profile.name = this.user.profile.name
      this.profile.gender = this.user.profile.gender
      this.profile.birthday = this.user.profile.birthday
      this.profile.city_id = this.user.profile.city_id
    }
  }
}
</script>

<style scoped>
.form-field {
  margin-bottom: 1rem;
}

.form-field label {
  font-size: .9em;
}
</style>
