<template>
  <Avatar
    :thumb="photo ? photo.thumbs.face_sm : null"
    :loading="photo === null"
    :hidden="photo ? photo.is_hidden : false"
    radius=".4rem"
    class="avatar gallery-toggle"
    style="font-size: .8em">
    
    <div class="top sm"></div>
    <div class="bottom sm">
      <template v-if="editing">
        <div class="edit-buttons txt-c">
          <button class="btn btn-link btn-xs" type="button" title="Сделать главной"
            v-if="photo && photo.has_face && !photo.is_avatar" @click.stop="$emit('setAvatar', photo)">
            <i class="icon-photo-main"></i>
          </button>
          <button class="btn btn-link btn-xs" type="button" title="Удалить фотографию" @click.stop="$emit('delPhoto', photo)">
            <i class="icon-photo-del"></i>
          </button>
        </div>
      </template>
    </div>
  </Avatar>
</template>

<script>
import Avatar from '@/components/Avatar.vue'

export default {
  name: 'ProfilePhotoMiniature',
  emits: ['setAvatar', 'delPhoto'],
  components: {
    Avatar
  },
  props: {
    "photo": {
      default: null
    },
    "editing": {
      type: Boolean,
      default: false
    }
  }
}
</script>


<style scoped>
.top,
.bottom {
  display: block;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  right: 0;
  margin: 1rem;
  color: #FFF;
  z-index: 1;
}

.top.sm,
.bottom.sm {
  margin: .25rem 0;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.avatar {
  transition: box-shadow .2s ease-in-out;
}

.avatar:not(.photo-bigger):hover {
  box-shadow: inset 0 0 20em rgba(0, 0, 0, 0.1);
}

.photo.photo-bigger {
  width: 100%;
}


.photo .edit-buttons .btn {
  padding: .2em .3em .05em;
  margin: 0 .15em;
  font-size: 1.25em;
}

.photo .edit-buttons {
  width: 100%;
  opacity: 0;
  transition: all .2s ease-in-out;
}

.photo:hover .edit-buttons {
  opacity: 1;
}
</style>