<template>
  <div class="timer-modal">
    <button class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <h4 class="txt-c no-margin-t" v-if="timeout > 0">
        <span class="timer">{{ timer }}</span>
        - На сегодня все
      </h4>

      <p class="txt-c padding-b">Вы сможете создавать новые диалоги через указанное времяю Своим парам вы можете писать без ограничений.</p>
      <div class="footer-modal">
        <button class="btn full-width" @click="close">Закрыть</button>
      </div>
    </CustomScrollbar>

  </div>
</template>

<script>
import { popModal } from "jenesius-vue-modal"

export default {
  name: 'TimerModal',
  props: {
    timeout: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      timerSeconds: 0,
    }
  },
  computed: {
    timer() {
      return new Date(this.timerSeconds * 1000).toISOString().slice(11, 19);
    }
  },
  watch: {
    timeout: {
      immediate: true,
      handler(timeout) {
        this.timerSeconds = timeout
      }
    }
  },
  mounted() {
    setInterval(this.updateTimeout, 1000)
  },
  methods: {
    updateTimeout() {
      this.timerSeconds -= 1
    },
    close() {
      popModal()
    },
  }
}
</script>


<style scoped>
.timer-modal {
  width: 30rem;
}

h4 {
  font-weight: 600;
  font-size: 1.2rem;
}

/* md+ */
@media screen and (min-width: 48em) {
  p {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}
</style>