<template>
  <div>
    <InterestPicker class="margin-half-t" :uid="uid" v-if="editing" @selected="addInterest" />
    <div class="interests" :class="{'empty': interests !== null && interests.length == 0}">
      <VueElementLoading :active="interests === null" spinner="bar-fade-scale" color="#BBB" size="30"/>
      <span v-if="interests !== null && interests.length == 0 && !editing">{{ emptyInterests }}</span>
      <span v-else class="interest" v-for="i in interests" :key="i.id">
        <span>{{ i.name }}</span>
        <button class="btn btn-remove" v-if="editing" @click="delInterest(i.id)">
          <i class="icon-times"></i>
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import {apiCall, SERVER_ADDRESSES} from "@/utils/api";
import { mapGetters } from "vuex";

import InterestPicker from '@/components/inputs/InterestPicker.vue'

const format = require('string-format')
import LOCALIZATIONS from "@/localizations/ru"

export default {
  name: 'ProfileInterests',
  components: {
    InterestPicker
  },
  props: {
    "uid": {
      type: String,
    },
    "editing": {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["usersStore"]),
    user() {
      return this.uid in this.usersStore ? this.usersStore[this.uid] : null
    },
    gender() {
      return this.user ? this.user.profile.gender : 'male'
    },
    emptyInterests() {
      const text = LOCALIZATIONS['no_interests_' + this.gender]
      return format(text, this.user ? this.user.profile.name : LOCALIZATIONS.user_no_name)
    }
  },
  data: function() {
    return {
      interests: null,
    }
  },
  mounted() {
    this.loadInterests().then(interests => {
      this.interests = interests
    })
  },
  methods: {
    async loadInterests() {
      var resp = await apiCall({ url: SERVER_ADDRESSES.interests + this.uid, method: "GET" })
      return resp.interests
    },
    addInterest(interest) {
      const isExists = this.interests.find(i => i.id == interest.id)
      if (isExists) {
        return
      }
      this.interests.push(interest)
      apiCall({ url: SERVER_ADDRESSES.interests + this.uid + "/add/" + interest.id, method: "POST" })
    },
    delInterest(id) {
      this.interests = this.interests.filter(i => i.id != id)
      apiCall({ url: SERVER_ADDRESSES.interests + this.uid + "/del/" + id, method: "POST" })
    }
  }
}
</script>

<style scoped>
.interests {
  position: relative;
  min-height: 4rem;
  margin: 1rem 0;
}
.interests.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #908f8f;
}

.interests .interest {
  position: relative;
  display: inline-flex;
  margin: .5rem .5rem .25rem 0;
  border: 1px solid #E0E0E0;
  border-radius: .5rem;
  font-size: .9em;
  font-weight: 500;
  color: #222020;
}

.interests .interest span {
  display: inline-block;
  padding: .5rem 1rem;
}

.btn.btn-remove {
  font-size: .5rem;
  color: #666;
  font-weight: 600;
  margin-left: -.75rem;
  border-radius: 0;
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
  line-height: 1
}
.btn.btn-remove:hover {
  background-color: #F0F0F0;
}

.btn.btn-remove i {
  position: relative;
  top: .05rem;
}
</style>
