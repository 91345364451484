<template>
  <span>
    Рейтинг:
    <span class="rating">
      <svg class="rating__progress" viewBox="0 0 36 36" width="30" xmlns="http://www.w3.org/2000/svg">
        <path d="M 2 16 a 15.9155 15.9155 0 0 1 31 0" fill="none" stroke="#EFEFEF" />
        <path d="M 2 16 a 15.9155 15.9155 0 0 1 31 0" fill="none" :stroke="ratingColor.color" :stroke-dasharray="strokeDashArray" />
      </svg>
      <svg class="rating__arrow" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg" :style="{transform: 'rotate(' + arrowDeg + 'deg)'}">
        <path d="M2.76821 4.19494C2.04351 4.76246 1.04196 4.69378 0.531197 4.04154C0.0204327 3.38931 0.193867 2.4005 0.918574 1.83299C1.64328 1.26547 3.90853 0.92046 4.46779 0.95881C4.37094 1.51095 3.49292 3.62743 2.76821 4.19494Z" fill="#222020"/>
      </svg>
    </span>
    <span class="rating__name" :style="{color: ratingColor.color}">{{ ratingColor.label }}</span>
  </span>
</template>

<script>
import LOCALIZATIONS from "@/localizations/ru";

export default {
  name: 'RatingWidget',
  props: {
    percent: {
      type: Number,
      required: true
    }
  },
  data: function() {
    return {
      colors: [
        {lte: 40, label: LOCALIZATIONS.rating.low, color: "#FF676C"},
        {lte: 70, label: LOCALIZATIONS.rating.average, color: "#FEAB0C"},
        {lte: 100, label: LOCALIZATIONS.rating.high, color: "#00C667"}
      ]
    }
  },
  computed: {
    strokeDashArray() {
      return this.percent/2.5 + ", 100"
    },
    arrowDeg() {
      return -100 + this.percent
    },
    ratingColor() {
      for (let color of this.colors) {
        if (this.percent <= color.lte) {
          return color
        }
      }
      return this.colors[0]
    }
  },
}
</script>

<style scoped>
.rating {
  position: relative;
  display: inline-block;
  top: .75rem;
  margin: 0 .5rem;
}

.rating__progress path {
  stroke-linecap: round;
  stroke-width: 4;
}

.rating__arrow {
  position: absolute;
  width: .5rem;
  left: calc(50% - .25rem);
  bottom: 45%;
  transform-origin: 50% 75%;
}
</style>
