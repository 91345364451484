<template>
  <div class="profile pure-g container">
    <div class="pure-u-1 pure-u-md-13-24">
      <ProfilePhotos :uid="uid" :gender="user ? user.profile.gender : 'male'"
        :photoCount="user ? user.profile.photo_count : null">
        <template #top="{ index }">
          <span class="photo-count" v-if="user && user.profile.photo_count">
            <i class="icon-photo"></i>
            {{ index }}/{{ user.profile.photo_count }}
          </span>
        </template>
      </ProfilePhotos>
      <div class="pure-g container-sm">
        <div class="pure-u-18-24 margin-t">
          <router-link :to="{ name: 'Messages', params: { uid: uid } }" class="btn btn-blue full-width">
            Написать
          </router-link>
        </div>
        <div class="pure-u-6-24 margin-t">
          <button class="btn btn-grey-stroke full-width like" :class="{ active: user && user.relations.is_liked }"
            @click="doLike"><i class="icon-like"></i></button>
        </div>
      </div>
    </div>

    <div class="pure-u-1 pure-u-md-11-24 margin-t" v-if="user">
      <div class="flex-row">
        <h1 class="flex-expand name" :class="{ online: user.is_online }">
          {{ user.profile.name }},&nbsp;{{ user.profile.age }}
        </h1>
        <MenuProfile :uid="uid" @blacklisted="blacklistChanged" @favorited="favoriteChanged">
          <button class="btn btn-grey btn-more"><i class="icon-dots"></i></button>
        </MenuProfile>
      </div>
      <span class="city">
        <i class="icon-map-marker"></i> &nbsp;
        {{ city ? city.name : '.......' }}
        <VueElementLoading :active="city == null" spinner="bar-fade-scale" color="#BBB" size="20" />
      </span>
      
      <span class="last-online" v-if="lastOnline">{{ lastOnline }}</span>
      <span class="last-online online" v-else-if="user.is_online">Сейчас онлайн</span>

      <hr />

      <p v-if="user.profile.about_me">{{ user.profile.about_me }}</p>
      <ProfileProperties class="margin-v" v-if="user" :properties="user.profile.properties" :gender="user.profile.gender"
        :showEmpty="true" />

      <hr class="margin-v" />
      <h2>Интересы</h2>
      <ProfileInterests :uid="uid" class="margin-half-v" />
    </div>
  </div>
</template>

<script>
const format = require('string-format')
import moment from 'moment'

import LOCALIZATIONS from "@/localizations/ru"

import { apiCall, SERVER_ADDRESSES } from "@/utils/api";
import { mapGetters } from "vuex";

import MenuProfile from '@/components/MenuProfile.vue'
import ProfilePhotos from '@/components/ProfilePhotos.vue'
import ProfileProperties from '@/components/ProfileProperties.vue'
import ProfileInterests from '@/components/ProfileInterests.vue'

export default {
  name: 'Profile',
  components: {
    MenuProfile,
    ProfilePhotos, ProfileProperties, ProfileInterests
  },
  props: {
    "uid": {
      type: String,
      required: true
    }
  },
  watch: {
    uid: {
      handler(uid) {
        this.loadPage(uid)
      },
      immediate: true
    }
  },
  data: function () {
    return {
      user: null
    }
  },
  computed: {
    ...mapGetters(["usersStore", "avatarsStore", "cities"]),
    city: function () {
      if (!this.user) {
        return null
      }
      if (!this.cities[this.user.profile.city_id]) {
        this.$store.dispatch("LOAD_CITIES", [this.user.profile.city_id])
        return null
      }
      return this.cities[this.user.profile.city_id]
    },
    lastOnline() {
      if (!this.user || this.user.is_online) {
        return false
      }
      if (!this.user.last_online) {
        return 'Не в сети'
      }
      const text = LOCALIZATIONS['was_online_' + this.user.profile.gender]
      return format(text, moment.utc(this.user.last_online).local().calendar().toLowerCase())
    }
  },
  created() {
    moment.locale('ru')
  },
  methods: {
    loadPage(uid) {
      this.user = uid in this.usersStore ? this.usersStore[uid] : null
      this.$store.dispatch("LOAD_USERS", {uids: [uid]})
      this.loadUser(uid).then(user => {
        this.user = user
      })
    },
    async loadUser(uid) {
      var scopes = ["base", "profile", "relation", "favorite"]
      var resp = await apiCall({ url: SERVER_ADDRESSES.users + uid + "?scopes=" + scopes.join(','), method: "GET" })
      return resp.user
    },
    blacklistChanged(isBlacklisted) {
      this.user.relations.is_blacklisted = isBlacklisted
    },
    favoriteChanged(isFavorite) {
      this.user.is_favorite = isFavorite
    },
    doLike() {
      if (this.user.relations.is_liked) {
        return
      }
      const type = this.user.relations.is_liked ? -10 : 10
      apiCall({ url: SERVER_ADDRESSES.users + this.user.id + '/swipe/' + type, method: "POST" })
      this.user.relations.is_liked = !this.user.relations.is_liked
    }
  }
}
</script>


<style scoped>
.profile {
  padding: 1rem 0;
}

h1 {
  margin: 0 0 .2rem;
  font-size: 2rem;
  font-weight: 600;
}

h2 {
  margin: 0 0 .2rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.name.online:after {
  content: "";
  display: inline-block;
  width: .5rem;
  height: .5rem;
  margin: 0 0 .5rem .5rem;
  border-radius: 50%;
  background-color: #00d26d;
}

.city, .last-online {
  display: block;
  color: #908f8f;
  font-size: 1rem;
}
.city i {
  font-size: .8rem;
}

.last-online {
  margin-top: .5rem;
}
.last-online.online {
  color: #359EFF;
}

button.like {
  transition: all .2s;
  color: #FF676C;
}

button.like i {
  transition: all .05s;
}

button.like i {
  position: relative;
  font-size: 1.2em;
  top: .15rem;
}

button.like.active {
  background-color: #FF676C;
  border-color: #FF676C;
  color: #FFF;
}

.photo-count {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  display: inline-block;
  font-size: .8rem;
  padding: .5rem .5rem;
  border-radius: .25rem;
}

.photo-count i {
  font-size: .8em;
  margin-right: .33rem;
}
</style>