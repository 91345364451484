<template>
  <div class="pure-g container-sm">
    <h5 class="pure-u-1 margin-b">Топ пользователей</h5>
    <div class="pure-u-2-4 pure-u-md-1-3 margin-b" v-for="uid, i in topUsersStore" :key="'profile_' + i">
      <router-link :to="{ name: 'Profile', params: { uid: uid } }" v-if="uid">
        <ProfileCircle :uid="uid" />
      </router-link>
      <ProfileCircle :uid="uid" v-else />
    </div>
    <div class="pure-u-1 txt-c">
      <button type="button" class="btn btn-sm btn-red btn-top" @click="pushToTop" v-if="!isMeInTopUsers">Хочу сюда</button>
      <div class="hint" v-else>Ваша анкета сейчас находится в этом списке</div>
    </div>
  </div>
</template>

<script>
import { pushModal } from "jenesius-vue-modal"

import { mapGetters } from "vuex";

import ProfileCircle from '@/components/ProfileCircle.vue'
import UploadPhotos from '@/components/modals/UploadPhotos.vue'
import ConfirmAnything from '@/components/modals/ConfirmAnything.vue'
import PaymentModal from '@/components/modals/payment/PaymentModal.vue'

export default {
  name: 'TopUsers',
  components: {
    ProfileCircle
  },
  data() {
    return {
      currentCityId: null,
      limit: 9,
      intervalHandlerId: null,
      users: []
    }
  },
  computed: {
    ...mapGetters(["me", "myId", "myAvatar", "topUsersStore"]),
    isLoading() {
      return this.topUsersStore.length === 0
    },
    isMeInTopUsers() {
      return this.topUsersStore.length && this.topUsersStore.indexOf(this.myId) !== -1
    }
  },
  watch: {
    "me.profile.city_id": {
      handler(city_id) {
        if (!city_id || city_id == this.currentCityId) {
          return
        }
        clearInterval(this.intervalHandlerId)
        this.currentCityId = city_id
        
        this.$store.dispatch("LOAD_TOP_USERS", {force: true, cityId: this.currentCityId})
        this.intervalHandlerId = setInterval(() => {
          this.$store.dispatch("LOAD_TOP_USERS", {force: false, cityId: this.currentCityId})
        }, 60 * 1000)
      },
      immediate: true
    }
  },
  beforeUnmount() {
    clearInterval(this.intervalHandlerId)
  },
  methods: {
    async pushToTop() {
      if (!this.myAvatar) {
        pushModal(UploadPhotos, {
          uid: this.myId,
        })
        return
      }
      if (this.me && this.me.privacy.hide_photos) {
        const modal = await pushModal(ConfirmAnything, {
          title: "Сделать фото видимыми?",
          text: "Чтобы попасть в ТОП пользователей, пожалуйста, сделайте видимыми ваши фото для всех. Сейчас ваши фотографии скрыты.",
          confirmText: "Сделать видимыми",
          denyText: "Отмена"
        })
        modal.on('confirmed', () => {
          this.$store.dispatch("UPDATE_PRIVACY", {data: {hide_photos: false} })
          this.openPayment()
        })
        return
      }
      this.openPayment()
    },
    openPayment() {
      pushModal(PaymentModal, {
        type: "push_top"
      })
    }
  }
}
</script>


<style scoped>
h5 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 500;
  color: #757373;
}

.btn.btn-top {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
</style>