<template>
  <div class="pure-g">
    <div class="pure-u-1 hidden">
      {{ properties }}
    </div>
    <template v-for="(title, prop) in allProps" :key="prop">
      <div class="pure-u-8-24 block-vcenter margin-b">
        <span>{{ title }}</span>
      </div>
      <div class="pure-u-16-24 margin-b">
        <VueSelect
          v-if="prop=='height'"
          :modelValue="values[prop]"
          @update:modelValue="$emit('update:property', {[prop]: $event.value})"
          :options="allValues[prop]"
          :clearable="false"
          :searchable="false"
          append-to-body
          :calculate-position="dropdownPosition" />
        
        <VueSelect
          v-else-if="prop=='weight'"
          :modelValue="values[prop]"
          @update:modelValue="$emit('update:property', {[prop]: $event.value})"
          :options="allValues[prop]"
          :clearable="false"
          :searchable="false"
          append-to-body
          :calculate-position="dropdownPosition" />

        <VueSelect
          v-else
          :modelValue="values[prop]"
          @update:modelValue="$emit('update:property', {[prop]: $event.value})"
          :options="allValues[prop]"
          :clearable="false"
          :searchable="false"
          append-to-body
          :calculate-position="dropdownPosition" />
      </div>
    </template>
  </div>
</template>

<script>
const format = require('string-format')

import LOCALIZATIONS from "@/localizations/ru";

import { createPopper } from '@popperjs/core'


export default {
  name: 'ProfileEditProperties',
  props: {
    "properties": {
      type: Object,
      default: null
    },
    "gender": {
      type: String,
      default: "male"
    },
  },
  data: function() {
    return {
      values: [],

      height: 0,
      weight: 0,

      minHeight: 120,
      maxHeight: 250,

      minWeight: 35,
      maxWeight: 220
    }
  },
  watch: {
    properties: {
      immediate: true,
      handler(props) {
        for (let p of Object.keys(props)) {
          this.values[p] = this.allValues[p].find(v => v.value == props[p])
          // this.value = this.genders.find(g => g.value == this.modelValue)
        }
      }
    }
  },
  computed: {
    allProps: function() {
      return LOCALIZATIONS.properties.names
    },
    allValues: function() {
      let allValues = {}
      for (var p in this.allProps) {
        var values = LOCALIZATIONS.properties[p + "_values_" + this.gender]
        allValues[p] = []
        for (var i in values) {
          allValues[p].push({value: +i, label: values[i]})
        }
        if (p == 'height') {
          for (var height = this.minHeight; height <= this.maxHeight; height++) {
            allValues[p].push({value: allValues[p].length, label: format(LOCALIZATIONS.n_sm, height)})
          }
        }
        if (p == 'weight') {
          for (var weight = this.minWeight; weight <= this.maxWeight; weight++) {
            allValues[p].push({value: allValues[p].length, label: format(LOCALIZATIONS.n_kilos, weight)})
          }
        }
      }
      return allValues
    },
    allValuesVisible: function() {
      let visibles = {...this.allValues}
      for (var p in visibles) {
        visibles[p].shift()  //  don't show "not selected"
      }
      return visibles
    },
  },
  mounted() {
    if (!this.properties) {
      return
    }
  },
  methods: {
    dropdownPosition(dropdownList, component, {width /*, top, left */}) {
      // default implementation:
      // dropdownList.style.top = top;
      // dropdownList.style.left = left;
      // dropdownList.style.width = width;
     
      dropdownList.style.width = width
      const popper = createPopper(component.$el.closest('.v-select'), dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    }
  }
}
</script>