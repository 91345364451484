<template>
  <form @submit.prevent="submit">
    <button class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <h4>Восстановление пароля</h4>

      <div v-show="stage == 'phone'">
        <p>Введите номер телефона, к которому был привязан ваш аккаунт</p>
        <div class="form-field" v-bind:class="{ error: (!phone || errData.phone) && showErrors }">
          <label>Номер телефона</label>
          <PhoneInput v-model="phone" />
        </div>
      </div>

      <CodeInput v-if="stage == 'code'" v-model="code" :phone="phone" :error="(!code || errData.code) && showErrors"
        @back="stage = 'phone'" @repeat="onRepeat()" />

      <div v-show="stage == 'password'">
        <p>Отлично! Осталось просто придумать новый пароль для вашего аккаунта</p>
        <div class="form-field" v-bind:class="{ error: (!new_password || errData.new_password) && showErrors }">
          <label>Новый пароль</label>
          <PasswordInput v-model="new_password" @input="errCode = 0" />
        </div>

        <div class="form-field" v-bind:class="{ error: (!repeat_password || errData.repeat_password) && showErrors }">
          <label>Повторите пароль</label>
          <PasswordInput v-model="repeat_password" @input="errCode = 0" />
        </div>
      </div>

      <div class="padding-half-t txt-c text-red" v-if="showErrors && errCode != 0">
        {{ errMessage }}
      </div>

      <div class="footer-modal">
        <button class="btn full-width btn-link" type="submit" :disabled="!submitEnabled">
          <span v-if="stage == 'password'">Подтвердить</span>
          <span v-else>Далее</span>
        </button>
        <VueElementLoading :active="loading" />
      </div>
    </CustomScrollbar>

  </form>
</template>

<script>
import { popModal } from "jenesius-vue-modal"

import { apiCall, SERVER_ADDRESSES } from "@/utils/api"

import PhoneInput from '@/components/inputs/PhoneInput.vue'
import PasswordInput from '@/components/inputs/PasswordInput.vue'
import CodeInput from '@/components/inputs/CodeInput.vue'

import { apiErrCodes } from "@/utils/api"

export default {
  name: 'FormResetPassword',
  components: {
    PhoneInput, PasswordInput, CodeInput
  },
  emits: ['updated'],
  data: function () {
    return {
      showErrors: false,
      errCode: 0,
      errData: {},
      phone: "",
      code: "",
      new_password: "",
      repeat_password: "",
      stage: "phone",
      loading: false
    }
  },
  computed: {
    errMessage() {
      if (this.new_password != this.repeat_password) {
        return "Пароли не совпадают"
      } else if (this.errCode == apiErrCodes.PASSWORD_ERROR && this.new_password.length && this.new_password.length < 6) {
        return "Пароль должен быть не менее 6 символов"
      } else if (this.errData.code && this.errCode == apiErrCodes.INVALID_VALUE_ERROR) {
        return "Введен неверный код подтверждения"
      } else if (this.errCode == apiErrCodes.INVALID_VALUE_ERROR) {
        return "Введен некорректный номер или его использование недоступно"
      } else if (this.errCode == apiErrCodes.OBJECT_NOT_FOUND_ERROR) {
        return "Пользователь с указанным номером телефона не существует"
      }
      return "Неизвестная ошибка. Попробуйте позже или обратитесь к технической поддержке."
    },
    submitEnabled() {
      if (this.stage == "code" && !this.code) {
        return false
      }
      return true
    }
  },
  methods: {
    close() {
      popModal()
    },
    onRepeat() {
      this.stage = 'phone'
      this.$nextTick(() => {
        this.submit()
      })
    },
    submit() {
      this.errCode = 0
      this.errData = {}
      this.showErrors = false
      if (!this.submitEnabled) {
        this.showErrors = true
        return
      }
      if ((this.stage == 'phone' && !this.phone) || (this.stage == 'code' && !this.code)) {
        this.showErrors = true
        return
      }
      if (this.stage == 'password') {
        if (this.new_password.length && this.new_password.length < 6) {
          this.errCode = apiErrCodes.PASSWORD_ERROR
          this.showErrors = true
          this.errData = { new_password: 'error' }
          return
        }
        if (this.new_password != this.repeat_password) {
          this.errCode = -1
          this.showErrors = true
          this.errData = { new_password: 'error', repeat_password: 'error' }
          return
        }
        if (!this.new_password || !this.repeat_password) {
          this.showErrors = true
          return
        }
      }

      this.loading = true
      apiCall({
        url: SERVER_ADDRESSES.auth + "reset/password", method: "POST", data: {
          phone: this.phone,
          new_password: this.new_password,
          code: this.code
        }
      }).then((resp) => {
        this.loading = false
        if (!resp.success || resp.err_code) {
          this.errCode = resp.err_code ?? -1
          this.errData = resp.err_data ?? {}
          this.showErrors = true
        } else if (!this.code) {
          this.stage = 'code'
        } else if (!this.new_password) {
          this.stage = 'password'
        } else {
          this.$store.dispatch("AUTH_REQUEST", {
            "grant_type": "password",
            "username": this.phone,
            "password": this.new_password
          }).then(() => {
            this.$router.push('/users')
            this.close()
          }).catch((e) => {
            this.loginErrCode = e.err_code
            this.showErrors = true
          })
        }
      }).catch((e) => {
        this.loading = false
        this.errCode = e.err_code ?? -1
        this.errData = e.err_data ?? {}
        this.showErrors = true
      })
    }
  }
}
</script>


<style scoped>
form {
  width: 30rem;
}

h4 {
  margin: 0 0 1.5rem;
  font-weight: 500;
  color: #222020;
  font-size: 1.5rem;
}

.form-field {
  margin: .5em 0;
}

label {
  display: block;
  position: relative;
  font-weight: 600;
  font-size: .8em;
  margin-bottom: .5em;
  color: #222020;
  background-color: #fff;
  top: 1em;
  left: .5em;
  padding: 0 .5em;
  z-index: 1;
}

.form-field label,
.form-field .hint {
  display: inline-block;
  margin: .5em;
}

.modal-container .footer-modal {
  margin-top: 2rem;
}

a {
  font-weight: 500;
}
</style>