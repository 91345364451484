<template>
  <div class="pure-g chat">
    <DialogsList :uid="uid" class="pure-u-1 pure-u-md-10-24 pure-u-xl-8-24 dialogs-list" :class="dlgListClass"
      ref="dlgList" @dialogUpdated="onDialogUpdated" @listUpdated="onDialogListUpdated" />
    <div class="pure-u-1 pure-u-md-14-24 pure-u-xl-16-24 chat-block">
      <ChatHeader :uid="uid" ref="chatHeader" @delDialog="onDelDialog" />
      <hr class="no-margin" />
      <MessagesList :uid="uid" :dialog="activeDialog" :empty="isEmptyList" :height="messagesListHeight" class="messages"
        ref="msgList" @repeatMessage="repeatMessage" />
      <ChatForm :uid="uid" :draft="uid in drafts ? drafts[uid] : ''" :enable-attachments="activeDialog && activeDialog.has_responses" @send="onMsgSend" @update="onMsgUpdated"
        ref="chatForm" @input="onMsgInput" />
    </div>
  </div>
</template>

<script>
import DialogsList from '@/components/messenger/DialogsList.vue'
import ChatHeader from '@/components/messenger/ChatHeader.vue'
import MessagesList from '@/components/messenger/MessagesList.vue'
import ChatForm from '@/components/messenger/ChatForm.vue'

export default {
  name: 'Messages',
  props: {
    "uid": {
      type: String,
      default: null
    }
  },
  components: {
    DialogsList,
    ChatHeader, MessagesList, ChatForm
  },
  data: function () {
    return {
      activeDialog: null,
      chatHeaderHeight: 0,
      chatFormHeight: 0,
      isEmptyList: null,
      drafts: {}  // key - to_id, value - msg 
    }
  },
  computed: {
    dlgListClass() {
      return {
        "hidden-xs": this.uid,
        "hidden-sm": this.uid
      }
    },
    messagesListHeight() {
      return 'calc(100vh - 3.6rem - ' + (this.chatFormHeight + this.chatHeaderHeight) + 'px)'
    }
  },
  mounted() {
    // document.addEventListener("keydown", this.onKeydown);
    this.chatHeaderHeight = this.$refs.chatHeader.$el.offsetHeight
    this.chatFormHeight = this.$refs.chatForm.$el.offsetHeight
  },
  unmounted() {
    // document.removeEventListener("keydown", this.onKeydown);
    this.$store.dispatch("SET_ACTIVE_DIALOG", null)
  },
  methods: {
    // onKeydown(e) {
    //   if (this.uid && e.key === "Escape") {
    //     this.$router.push({ name: 'Messages' })
    //   }
    // },
    onMsgInput(text) {
      if (!text) {
        delete this.drafts[this.uid]
      } else {
        this.drafts[this.uid] = text
      }
      this.chatFormHeight = this.$refs.chatForm.$el.offsetHeight
    },
    onMsgSend(e) {
      this.$refs.msgList.updateMessage(e.message)
      this.$refs.msgList.scrollToBottom()
    },
    onMsgUpdated(e) {
      if (!e.message) {
        return
      }
      this.$refs.msgList.updateMessage(e.message)
      this.$refs.dlgList.onNewMessage(e.message, e.newDialog)
    },
    onDialogUpdated(dialog) {
      this.activeDialog = dialog
    },
    onDialogListUpdated(data) {
      this.isEmptyList = data.count === 0
    },
    onDelDialog(uid) {
      this.$refs.dlgList.onDelDialog(uid)
    },
    async repeatMessage(msg) {
      const tempMessage = this.$refs.chatForm.createTempMessage(msg.text)
      await this.$refs.chatForm.sendMessage(tempMessage)
    }
  }
}
</script>

<style scoped>
.chat {
  max-height: calc(100vh - 3.6rem);
}

.dialogs-list,
.chat-block {
  height: 100%;
}

.chat-block {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ECECEC;
}

.chat-block .messages {
  flex: 1 1 auto;
}
</style>
