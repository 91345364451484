<template>
  <div class="code-container">
    <h5>Подтверждение</h5>
    <p class="phone-mask">
      <i class="icon icon-phone margin-half-r highlight"></i>
      +x&nbsp;xxx - xxx - <span class="highlight">xx xx</span>
    </p>
    <p>Сейчас на ваш номер <span class="highlight">{{ phone }}</span> поступит звонок. Не отвечайте, просто сбросьте</p>
    <p class="no-margin-b">Введите последние 4 цифры входящего номера</p>
    <div class="pure-g">
      <div class="pure-u-1-4 form-field" :class="{ 'error': error }">
        <label>Код</label>
        <input v-model="code" type="text" @keypress="isNumber($event)" @input="onUpdated" :maxlength="length" />
      </div>
      <div class="pure-u-3-4 block-vcenter padding-t margin-half-t padding-l">
        <button class="btn btn-sm btn-link" type="button" @click="this.$emit('back')">Изменить номер</button>
      </div>
    </div>
    <p class="smaller" v-if="seconds <= repeatTimeout - 10">
      Нет звонка?
      Проверьте указанный номер телефона или
      <span v-if="seconds > 0">подождите <span class="highlight">{{ seconds }}&nbsp;сек.</span> и повторите звонок.</span>
      <a v-else class="dashed" @click.prevent="this.$emit('repeat')" href="#">повторите звонок</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'CodeInput',
  emits: ['update:modelValue', 'back', 'repeat'],
  props: {
    "modelValue": {
      type: String,
    },
    "error": {
      type: Boolean,
      default: false
    },
    "phone": {
      type: String,
    },
    "length": {
      type: Number,
      default: 4
    },
    "repeatTimeout": {
      type: Number,
      default: 69
    }
  },
  data: function () {
    return {
      seconds: 0,
      intervalHandler: null,
      code: '',
    }
  },
  mounted() {
    this.seconds = this.repeatTimeout
    this.intervalHandler = setInterval(() => {
      this.seconds--
      if (this.seconds <= 0) {
        clearInterval(this.intervalHandler)
      }
    }, 1000)
  },
  unmounted() {
    clearInterval(this.intervalHandler)
  },
  methods: {
    onUpdated: function (e) {
      if (e.target.value.length == this.length) {
        this.$emit('update:modelValue', e.target.value)
      } else {
        this.$emit('update:modelValue', '')
      }
    },
    isNumber: function (evt) {
      let keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      if (keysAllowed.indexOf(evt.key) === -1) {
        evt.preventDefault()
      }
    }
  }
}
</script>

<style scoped>
h5 {
  margin: .5rem 0 1.5rem;
  font-weight: 700;
  color: #222020;
  font-size: 1.33rem;
}

.phone-mask {
  display: block;
  text-transform: uppercase;
  font-size: 1.75rem;
  margin: .5rem;
}

.highlight {
  color: #359EFF;
}

.phone-mask i {
  position: relative;
  top: .05rem;
}


label {
  display: block;
  position: relative;
  font-weight: 600;
  font-size: .8em;
  margin-bottom: .5em;
  color: #222020;
  background-color: #fff;
  top: 1em;
  left: .5em;
  padding: 0 .5em;
  z-index: 1;
}

.form-field label,
.form-field .hint {
  display: inline-block;
  margin: .5em;
}
</style>