<template>
  <div class="aside">
    <img class="logo" alt="Kvisto" src="@/assets/logo.svg" ref="logoBlock">
    <div class="me txt-c" ref="meBlock">
      <router-link :to="{ name: 'ProfileMine' }" class="avatar" :class="{'avatar-premium': me && me.premium.is_active}">
        <Avatar :thumb="myAvatar ? myAvatar.thumbs.face_md : null" :hidden="false"
          :gender="me ? me.profile.gender : 'male'" :loading="myPhotos === null" :height="100" radius="50%" />
      </router-link>
      <router-link :to="{ name: 'ProfileMine' }" class="block name bigger margin-t">
        <span v-if="me">
          {{ me.profile.name }},&nbsp;{{ me.profile.age }}&nbsp;<i class="icon-pencil smaller"></i>
        </span>
        <span v-else>&nbsp;&nbsp;</span>
      </router-link>
      <router-link :to="{ name: 'Rating' }" class="rating no-line">
        <RatingWidget :percent="me ? me.rating : 100" />
      </router-link>
    </div>
    <CustomScrollbar :style="{ 'height': scrollHeight }">
      <div class="menu">
        <router-link :to="{ name: 'Near' }"><i class="icon-menu-search"></i> Поиск</router-link>
        <router-link :to="{ name: 'Game' }"><i class="icon-menu-game"></i> Игра</router-link>
        <router-link class="flex-row" :to="{ name: 'Messages' }">
          <span class="flex-expand"><i class="icon-menu-msg"></i>Сообщения</span>
          <span v-if="myEvents && myEvents.messages" class="unread">{{ myEvents.messages }}</span>
        </router-link>
        <router-link class="flex-row" :to="{ name: 'Guests' }">
          <span class="flex-expand"><i class="icon-menu-guests"></i>Гости</span>
          <span v-if="myEvents && myEvents.guests" class="unread">{{ myEvents.guests }}</span>
        </router-link>
        <router-link class="flex-row" :to="{ name: 'Pairs' }">
          <span class="flex-expand"><i class="icon-menu-pairs"></i>Мои пары</span>
          <span v-if="myEvents && myEvents.pairs" class="unread">{{ myEvents.pairs }}</span>
        </router-link>
        <router-link class="flex-row" :to="{ name: 'Likes' }">
          <span class="flex-expand"><i class="icon-menu-liked"></i>Кому я нравлюсь</span>
          <span v-if="myEvents && myEvents.likes" class="unread">{{ myEvents.likes }}</span>
        </router-link>
        <router-link :to="{ name: 'Favorites' }"><i class="icon-menu-favorites"></i>Избранные</router-link>
        <router-link :to="{ name: 'Settings' }"><i class="icon-settings"></i>Настройки</router-link>

        <a class="flex-row" href="#" @click="resetUser"><i class="icon-game-undo"></i>Сбросить лайки и сообщения</a>

        <div class="top-users padding-half-r hidden-xl">
          <TopUsers />
        </div>

        <hr />

        <footer>
          <a href="#">Помощь</a>
          <a href="#">Еще</a>
          <span>{{ year }} &copy; Kvisto</span>
        </footer>
      </div>
    </CustomScrollbar>
  </div>
</template>

<script>
import { withProps } from 'mosha-vue-toastify';
import { mapGetters } from "vuex";

import {apiCall, apiEventTypes, SERVER_ADDRESSES} from "@/utils/api";

const format = require('string-format')
import LOCALIZATIONS from "@/localizations/ru"

import Avatar from '@/components/Avatar.vue'
import RatingWidget from '@/components/RatingWidget.vue'
import TopUsers from '@/components/TopUsers.vue'
import Toast from '@/components/Toast.vue'

export default {
  name: 'AsideMenu',
  components: {
    Avatar, RatingWidget, TopUsers
  },
  data: function () {
    return {
      logoHeight: 0,
      meHeight: 0,
    }
  },
  computed: {
    ...mapGetters(["myId", "me", "myPhotos", "myAvatar", "myEvents", "activeDialog"]),
    scrollHeight() {
      return 'calc(100vh - 1.8rem - ' + (this.meHeight + this.logoHeight) + 'px)'
    },
    year() {
      return (new Date()).getFullYear()
    }
  },
  mounted() {
    if (!this.me || !this.me.search) {
      this.$store.dispatch("REQUEST_MY_PROFILE")
    }
    if (this.myPhotos === null) {
      this.$store.dispatch("REQUEST_MY_PHOTOS")
    }
    if (this.myEvents === null) {
      this.$store.dispatch("REQUEST_MY_EVENTS")
    }
    this.meHeight = this.$refs.meBlock.offsetHeight
    this.logoHeight = this.$refs.logoBlock.offsetHeight

    this.$options.sockets.onmessage = this.wsEvent
  },
  unmounted: function () {
    this.$options.sockets.onmessage = undefined
  },
  methods: {
    wsEvent(event) {
      let data = JSON.parse(event.data)
      if (data.type == apiEventTypes.CHAT_MESSAGE && this.activeDialog != data.from_id) {
        this.$store.dispatch("INCREMENT_MY_EVENT", { type: "messages" })
        this.createNewMessageToast(data)
      }
      if ([apiEventTypes.USER_VISIT, apiEventTypes.USER_LIKE, apiEventTypes.USER_PAIR].indexOf(data.type) !== -1) {
        let eventType = ''
        if (data.type == apiEventTypes.USER_VISIT) {
          eventType = 'guests'
        } else if (data.type == apiEventTypes.USER_LIKE) {
          eventType = 'likes'
        } else if (data.type == apiEventTypes.USER_PAIR) {
          eventType = 'pairs'
        }
        this.$store.dispatch("INCREMENT_MY_EVENT", { type: eventType })
        this.createNewRelationToast(data)
      }
    },

    createNewMessageToast(data) {
      const router = this.$router
      const { close } = this.$moshaToast(withProps(Toast, {
        icon: data.data.icon,
        gender: data.data.gender,
        title: LOCALIZATIONS["new_msg"],
        text: "<strong>" + data.data.name + "</strong> " + data.data.text.substring(0, 300),
        click: function() {
          router.push({ name: 'Messages', params: { uid: data.from_id } })
          close()
        }
      }), {
        position: 'top-right',
        hideProgressBar: true,
        transition: 'slide',
      })
    },

    createNewRelationToast(data) {
      let routeName = ""
      let localizationData = null
      if (data.type == apiEventTypes.USER_VISIT) {
        routeName = "Guests"
        localizationData = LOCALIZATIONS.notifications["guest"]
      } else if (data.type == apiEventTypes.USER_LIKE) {
        routeName = "Likes"
        localizationData = LOCALIZATIONS.notifications["like"]
      } else if (data.type == apiEventTypes.USER_PAIR) {
        routeName = "Pairs"
        localizationData = LOCALIZATIONS.notifications["pair"]
      }
      if (!routeName) {
        return
      }

      const router = this.$router
      const { close } = this.$moshaToast(withProps(Toast, {
        icon: data.data.icon,
        gender: data.data.gender,
        title: localizationData.title,
        text: format(localizationData[data.data.gender], '<b>' + (data.data.name ? data.data.name : localizationData.unknown) + '</b>'),
        click: function() {
          router.push({ name: routeName })
          close()
        }
      }), {
        position: 'top-right',
        hideProgressBar: true,
        transition: 'slide',
      })
    },

    async resetUser() {
      await apiCall({ url: SERVER_ADDRESSES.events + this.myId + '/reset', method: "POST"})
      await apiCall({ url: SERVER_ADDRESSES.dialogs + this.myId + '/reset', method: "POST"})
      await apiCall({ url: SERVER_ADDRESSES.users + this.myId + '/reset', method: "POST"})
      await apiCall({ url: SERVER_ADDRESSES.photos + this.myId + '/reset', method: "POST"})
      window.location.reload()
    }
  }
}
</script>


<style scoped>
.aside {
  /* position: fixed; */
  height: 100vh;
}

.logo {
  display: block;
  width: 6rem;
  margin: 0 auto;
  padding: 1.8rem 0 1rem;
}

.top-users {
  margin-bottom: 1.8rem;
}

.me {
  padding: 1.5rem 0;
  line-height: 1.33;
}

.me .avatar {
  display: block;
  max-width: 9rem;
  margin: 0 auto 0 auto;
}

.me .avatar.avatar-premium {
  position: relative;
  padding: .25rem;
  border-radius: 50%;
  box-sizing: border-box;
}

.me .avatar.avatar-premium::before {
  position: absolute;
  display: block;
  top: -3px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  content: "";
  background: linear-gradient(-45deg, #FF426F, #359EFF);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
}

.me .avatar.avatar-premium::after {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
}

.me .avatar.avatar-premium .avatar {
  z-index: 1;
}

.me a i {
  transition: color .2s ease-in-out;
}

.me a:hover i {
  color: #222020;
}

.name,
.name:active,
.name:visited,
.name:hover {
  font-weight: 600;
  text-decoration: none;
  color: #222020;
}

.rating,
.rating:active,
.rating:visited {
  display: block;
  text-decoration: none;
  color: #222020;
}

.name i {
  color: #bdbcbc;
}

.menu {
  font-size: .95em;
}

.menu a,
.menu a:active,
.menu a:visited {
  display: flex;
  font-size: 1.1em;
  padding: .8em .8em;
  border-radius: .5rem;
  margin-bottom: .75rem;
  text-decoration: none;
  color: #222020;
  transition: all .2s ease-out;
}

.menu a i {
  display: inline-block;
  position: relative;
  top: .1rem;
  color: #A4A3A3;
  margin-right: .8rem;
  width: 1.2em;
  text-align: center;
}

.menu a.router-link-exact-active,
.menu a:hover {
  background-color: #EEE;
}

.menu a.router-link-exact-active,
.menu a.router-link-active i {
  color: #222020;
  font-weight: 600;
}

@media screen and (min-width: 88em) {
  .menu {
    padding: 0 .5rem 0 2rem;
  }
}

/* lg */
@media screen and (min-width: 64em) and (max-width: 79.95em) {

  .menu a,
  .menu a:active,
  .menu a:visited {
    font-size: 1em;
    padding: .8em .5em;
  }
}


.menu footer {
  font-size: .8rem;
}

.menu footer span {
  display: block;
  padding: 0 1rem;
  color: #666;
}

.menu footer a,
.menu footer a:active,
.menu footer a:visited {
  display: inline-block;
  padding: .5rem 0;
  margin: 0 .25rem 0 1rem;
  color: #666;
}

.menu footer a:hover {
  background-color: initial;
  text-decoration: underline;
}

.unread {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #FF426F;
  text-align: center;
  color: #FFF;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
