<template>
  <VDropdown :placement="placement">
    <slot/>

    <template #popper="{ hide }">
      <button class="btn btn-context-menu" type="button" @click="sendComplain('18+'); hide()">Порнографическое фото</button>
      <button class="btn btn-context-menu" type="button" @click="sendComplain('rude'); hide()">Грубость и оскорбления</button>
      <button class="btn btn-context-menu" type="button" @click="sendComplain('fake'); hide()">Ненастоящий профиль</button>
      <button class="btn btn-context-menu" type="button" @click="sendComplain('spam'); hide()">Спам/Реклама</button>
    </template>
  </VDropdown>
</template>

<script>
import { withProps } from 'mosha-vue-toastify';

const format = require('string-format')
import LOCALIZATIONS from "@/localizations/ru";
import {apiCall, SERVER_ADDRESSES} from "@/utils/api";
import { mapGetters } from "vuex";

import Toast from '@/components/Toast.vue'

export default {
  name: 'MenuComplain',
  props: {
    "uid": {
      type: String,
    },
    "placement": {
      type: String,
      default: "bottom-end"
    }
  },
  computed: {
    ...mapGetters(["usersStore", "avatarsStore"]),
    gender() {
      return this.uid in this.usersStore ? this.usersStore[this.uid].profile.gender : 'male'
    }
  },
  methods: {
    sendComplain(type) {
      this.createToast(LOCALIZATIONS["complain_sent"])

      apiCall({ url: SERVER_ADDRESSES.users + this.uid + '/complain/' + type, method: "POST" })
    },
    createToast(text) {
      let user = this.uid in this.usersStore ? this.usersStore[this.uid] : null,
        avatar = this.uid in this.avatarsStore ? this.avatarsStore[this.uid] : ''
      this.$moshaToast(withProps(Toast, {
        icon: avatar,
        gender: user ? user.profile.gender : 'male',
        text: format(text, '<b>' + (user ? user.profile.name : '') + '</b>')
      }), {
        position: 'top-right',
        hideProgressBar: true,
        transition: 'slide',
      })
    }
  }
}
</script>
