<template>
  <div>
    <ScrollTop />

    <PageTitle title="Гости"></PageTitle>
    <div class="pure-g padding container-sm" :class="{ 'empty-block-wrapper': isEmpty }">
      <div class="pure-u-1-2 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4" v-for="v in visits" :key="v.from_id">
        <router-link :to="{ name: 'Profile', params: { uid: v.from_id } }" v-if="v.from_id[0] != '-'">
          <ProfileCard class="margin-b" :uid="v.from_id" :date="v.date" />
        </router-link>
        
        <ProfileCard class="margin-b pointer" :uid="v.from_id" :date="v.date" @click.stop="openPayment" v-else />
      </div>
      <div class="pure-u-1 loading-block" v-if="isPageLoading">
        <VueElementLoading :active="true" size="40" />
      </div>
      <div class="pure-u-1 empty" v-if="isEmpty">
        <div class="txt-c">
          <p>
            <img src="@/assets/img/empty/couple1.svg" />
          </p>
          <p>
            Здесь будут те, кто смотрел ваш профиль, а пока тут пусто...
          </p>
          <router-link :to="{ name: 'Near' }" class="btn btn-red">
            Поиск
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/empty-list.css'

import { mapGetters } from "vuex";

import { pushModal } from "jenesius-vue-modal"
import { apiCall, SERVER_ADDRESSES } from "@/utils/api";

import PageTitle from '@/components/PageTitle.vue'
import ProfileCard from '@/components/ProfileCard.vue'
import ScrollTop from '@/components/ScrollTop.vue'
import PaymentModal from '@/components/modals/payment/PaymentModal.vue'

export default {
  name: 'Guests',
  components: {
    PageTitle, ProfileCard, ScrollTop
  },
  data: function () {
    return {
      visits: [],
      pageSize: 50,
      isPageLoading: false,
      isLoadedToEnd: false,
      scrollElement: null
    }
  },
  computed: {
    ...mapGetters(["myEvents"]),
    isEmpty() {
      return this.visits.length == 0 && !this.isPageLoading
    }
  },
  watch: {
    myEvents: {
      handler(newVal) {
        if (!newVal || !newVal.guests) {
          return
        }
        this.$store.dispatch('DECREMENT_MY_EVENT', {
          type: 'guests',
          value: newVal.guests
        });
      },
      immediate: true
    }
  },
  mounted: function () {
    this.loadPage()
    this.scrollElement = document.querySelector('#app > .kvisto-app')
    this.scrollElement.addEventListener("scroll", this.handleScroll)
  },
  unmounted: function () {
    this.scrollElement.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    loadPage() {
      this.isPageLoading = true
      this.loadGuests().then(visits => {
        if (visits.length < this.pageSize) {
          this.isLoadedToEnd = true
        }
        let uids = visits.map(v => v.from_id)
        this.$store.dispatch("LOAD_USERS", {uids})
        this.visits = [...this.visits, ...visits]
        this.isPageLoading = false
      })
    },
    async loadGuests() {
      let url = SERVER_ADDRESSES.users + "guests?limit=" + this.pageSize,
        fromDate = this.visits.length > 0 ? this.visits[this.visits.length - 1].date : ""
      if (fromDate) {
        url += '&from_date=' + encodeURIComponent(fromDate)
      }
      var resp = await apiCall({ url: url, method: "GET" })
      return resp.visits
    },

    handleScroll() {
      if (this.isLoadedToEnd) {
        return
      }
      let body = this.scrollElement.children[0],
        p = body.parentNode,
        bottomOffset = (p.scrollHeight - p.clientHeight) - (body.scrollTop || p.scrollTop)
      if (!this.isPageLoading && bottomOffset < 1000) {
        this.loadPage()
      }
    },

    openPayment() {
      pushModal(PaymentModal)
    }
  }
}
</script>


<style scoped>
.loading-block {
  min-height: 5rem;
}

.empty-block {
  display: flex;
  min-height: 20rem;
  justify-content: center;
  align-items: center;
  color: #908f8f;
}

.pointer {
  cursor: pointer;
}
</style>