<template>
  <div>
    <ScrollTop />

    <VueElementLoading :active="isPageLoading" size="40" />

    <PageTitle title="Избранные">
      <template #after>
        <VDropdown placement="bottom-end" v-if="favorites.length">
          <button class="btn btn-grey btn-more"><i class="icon-dots"></i></button>

          <template #popper="{ hide }">
            <button class="btn btn-context-menu" v-if="checkedProfiles.length != favorites.length"
              @click="selectAll()">Выделить все</button>
            <button class="btn btn-context-menu" v-if="checkedProfiles.length" @click="deselectAll(); hide()">Отменить
              выделение</button>

            <button class="btn btn-context-menu" :disabled="!checkedProfiles.length"
              @click="removeSelected(); hide()">Удалить
              выбранных</button>
          </template>
        </VDropdown>
      </template>
    </PageTitle>

    <div class="pure-g padding container-sm" :class="{ 'empty-block-wrapper': isEmpty }">
      <div class="pure-u-1-2 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4" v-for="f in favorites" :key="f.to_id">
        <router-link :to="{ name: 'Profile', params: { uid: f.to_id } }">
          <ProfileCard class="margin-b" :uid="f.to_id">
            <template #top-right>
              <span class="checkbox" @click.stop>
                <input :id="'checkbox_' + f.to_id" :value="f.to_id" type="checkbox" v-model="checkedProfiles" />
                <label :for="'checkbox_' + f.to_id"></label>
              </span>
            </template>
          </ProfileCard>
        </router-link>
      </div>
      <div class="pure-u-1 empty" v-if="isEmpty">
        <div class="txt-c">
          <p class="top-star" v-if="gender == 'female'">
            <i class="icon-menu-favorites"></i>
            <img src="@/assets/img/empty/man1.svg" />
          </p>
          <p class="top-star" v-else>
            <img src="@/assets/img/empty/woman1.svg" />
            <i class="icon-menu-favorites"></i>
          </p>
          <p>
            Добавляйте кого хотите в избранные, чтобы не потерять.
            И все они будут здесь!
          </p>
          <router-link :to="{ name: 'Near' }" class="btn btn-red">
            Поиск
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/empty-list.css'

import { mapGetters } from "vuex";
import { apiCall, SERVER_ADDRESSES } from "@/utils/api";

import PageTitle from '@/components/PageTitle.vue'
import ProfileCard from '@/components/ProfileCard.vue'
import ScrollTop from '@/components/ScrollTop.vue'

export default {
  name: 'Favorites',
  components: {
    PageTitle, ProfileCard, ScrollTop
  },
  data: function () {
    return {
      favorites: [],
      checkedProfiles: [],
      pageSize: 50,
      isPageLoading: false,
      isLoadedToEnd: false,
      scrollElement: null
    }
  },
  mounted: function () {
    this.loadPage()
    this.scrollElement = document.querySelector('#app > .kvisto-app')
    this.scrollElement.addEventListener("scroll", this.handleScroll)
  },
  unmounted: function () {
    this.scrollElement.removeEventListener("scroll", this.handleScroll)
  },
  computed: {
    ...mapGetters(["me"]),
    gender() {
      return this.me ? this.me.profile.gender : 'male'
    },
    isEmpty() {
      return this.favorites.length == 0 && !this.isPageLoading
    }
  },
  methods: {
    loadPage() {
      this.isPageLoading = true
      this.loadPeople().then(favorites => {
        if (favorites.length < this.pageSize) {
          this.isLoadedToEnd = true
        }
        let uids = favorites.map(f => f.to_id)
        this.$store.dispatch("LOAD_USERS", {uids})
        this.favorites = [...this.favorites, ...favorites]
        this.isPageLoading = false
      })
    },
    async loadPeople() {
      let url = SERVER_ADDRESSES.users + "favorites?limit=" + this.pageSize,
        fromDate = this.favorites.length > 0 ? this.favorites[this.favorites.length - 1].date : ""
      if (fromDate) {
        url += '&from_date=' + encodeURIComponent(fromDate)
      }
      var resp = await apiCall({ url: url, method: "GET" })
      return resp.favorites
    },

    handleScroll() {
      if (this.isLoadedToEnd) {
        return
      }
      let body = this.scrollElement.children[0],
        p = body.parentNode,
        bottomOffset = (p.scrollHeight - p.clientHeight) - (body.scrollTop || p.scrollTop)
      if (!this.isPageLoading && bottomOffset < 1000) {
        this.loadPage()
      }
    },

    selectAll() {
      this.checkedProfiles = this.favorites.map(f => f.to_id)
    },
    deselectAll() {
      this.checkedProfiles = []
    },
    async removeSelected() {
      this.isPageLoading = true

      for (var uid of this.checkedProfiles) {
        await this.$store.dispatch("SET_USER_FAVORITE", {
          uid: uid,
          isFavorite: false
        })
      }

      this.deselectAll()
      this.favorites = []
      this.isLoadedToEnd = false
      this.loadPage()
    }
  }
}
</script>


<style scoped>
.loading-block {
  min-height: 5rem;
}

.top-star {
  position: relative;
}

.top-star i {
  position: absolute;
  top: 0;
  font-size: 1.25rem;
  color: #fff200;
}

.checkbox label {
  display: inline-block;
  padding: .6em;
  margin: -0.6em;
}

.checkbox label:before {
  position: relative;
  background-color: rgba(255, 255, 255, .4);
  border-color: #FFF;
  transition: all .2s;
}

.checkbox label:hover:before {
  background-color: rgba(255, 255, 255, .75);
  border-color: #FFF;
}
</style>