<template>
  <div class="confirm-del">
    <button class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <h4 v-if="warnLast" class="txt-c">Вы удаляете свое единственное фото, где вас хорошо видно</h4>
      <h4 v-else class="txt-c">Вы уверены, что хотите удалить это фото?</h4>

      <div class="pure-g margin-b">
        <div class="pure-u-1 pure-u-sm-1-3"></div>
        <div class="pure-u-1 pure-u-sm-1-3 margin-b">
          <Avatar :thumb="photo.thumbs.face_md" :height="120" radius=".8rem" class="photo" />
        </div>
      </div>

      <p v-if="warnLast" class="txt-c">Остальные ваши фотографии не подходят для главной и <strong>будут удалены</strong>.
        Все равно удалить?</p>

      <p v-if="warnLast && isMeInTopUsers" class="txt-c">
        <b>Внимание!</b> Вы оплатили поднятие анкеты и сейчас находитесь в ТОПе.
        Если вы удалите свою последнюю фотографию с лицом, то мы не сможем показывать вас там.
      </p>

      <div class="footer-modal">
        <div class="pure-g" :class="{ 'reversed': warnLast }">
          <button class="pure-u-1-2 btn" @click="close">Отмена</button>
          <button class="pure-u-1-2 btn btn-link text-red" @click="confirm">Да, удалить</button>
        </div>
      </div>
    </CustomScrollbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { popModal } from "jenesius-vue-modal"
import Avatar from '@/components/Avatar.vue'

export default {
  name: 'ConfirmDelPhoto',
  components: {
    Avatar
  },
  emits: ['confirmed'],
  props: {
    warnLast: {
      type: Boolean,
      default: false
    },
    photo: {
      required: true
    }
  },
  computed: {
    ...mapGetters(["myId", "topUsersStore"]),
    isMeInTopUsers() {
      return this.topUsersStore.length && this.topUsersStore.indexOf(this.myId) !== -1
    }
  },
  methods: {
    close() {
      popModal()
    },
    confirm() {
      this.$emit('confirmed')
      this.close()
    }
  }
}
</script>


<style scoped>
.confirm-del {
  width: 27rem;
}

.reversed {
  flex-direction: row-reverse;
}

h4 {
  margin: 0 0 1.5rem;
  font-weight: 500;
  color: #222020;
  font-size: 1.5rem;
}
</style>