<template>
  <div class="invite txt-c">
    <h4>Kvisto Premium бесплатно!</h4>
    <p>
      Отправь ссылку на Kvisto друзьям.
      Как только они зарегистрируются, вы получите 1 месяц Premium бесплатно
    </p>
    <div class="postfixed">
      <input type="text" :readonly="true" v-model="inviteLink" />
      <button type="button" class="btn btn-link btn-red postfix" @click.stop="copyToClipboard">
        <i class="icon icon-copy"></i>
      </button>
    </div>

    <p class="invited padding-t" v-if="myReferrers.length">
      По вашей ссылке зарегистрировано: {{ myReferrers.length }}/{{ referrersRequired }}
    </p>
    <p class="invited padding-t" v-else>
      По вашей ссылке пока никто не зарегистрировался
    </p>

    <div class="progress-bar">
      <div :style="{ width: invitedPercent + '%' }"></div>
    </div>

    <p>
      <ProfileCircle v-for="uid in myReferrers" :key="'invited_' + uid" :uid="uid" class="avatar" />
    </p>
  </div>
</template>

<script>
import { withProps } from 'mosha-vue-toastify'
import { mapGetters } from "vuex"

import ProfileCircle from '@/components/ProfileCircle.vue'
import Toast from '@/components/Toast.vue'

export default {
  name: 'PaymentModalInvite',
  components: {
    ProfileCircle
  },
  computed: {
    ...mapGetters(["myId", "me", "myReferrers", "referrersRequired"]),
    invitedPercent() {
      return (this.myReferrers.length / this.referrersRequired) * 100
    },
    inviteLink() {
      let link = "https://kvis.to/"
      if (this.me && this.me.referer_key) {
        link += this.me.referer_key
      } else {
        link += this.myId
      }
      return link
    }
  },
  mounted() {
    this.$store.dispatch("REQUEST_MY_REFERRERS")
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.inviteLink);

      this.$moshaToast(withProps(Toast, {
        icon: null,
        text: "Ссылка скопирована в буфер обмена"
      }), {
        position: 'top-right',
        hideProgressBar: true,
        transition: 'slide',
      })
    }
  }
}
</script>

<style scoped>
.postfixed {
  position: relative;
}

.btn.postfix {
  border-color: transparent;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.invite {
  margin: 0 auto;
  max-width: 30rem;
}

h4 {
  padding: 0;
  font-size: 1.6rem;
  font-weight: 600;
}

p {
  font-size: 1.2rem;
}

.invited {
  font-size: 1rem;
  font-weight: 400;
}

.progress-bar,
.progress-bar>* {
  height: 4px;
  border-radius: 2px;
  background-color: #ECECEC;
  overflow: hidden;
}

.progress-bar>* {
  background-color: #FF426F;
}

.avatar {
  display: inline-block;
  width: 2.5rem;
  margin-right: -.5rem;
}
</style>