<template>
  <div class="pure-g container">
    <div class="pure-u-1 form-field" v-bind:class="{error: !phone && showErrors}">
      <label>Мой номер</label>
      <PhoneInput :modelValue="phone" @update:modelValue="$emit('update:phone', $event)" />
    </div>
    <div class="pure-u-1 form-field" v-bind:class="{error: (!password || password.length < minLength) && showErrors}">
      <label for="registration_password">Придумайте пароль</label>
      <PasswordInput :modelValue="password" @update:modelValue="$emit('update:password', $event)" />
      <span class="smaller hint">Пароль должен быть не менее {{ minLength }} символов</span>
    </div>
  </div>
</template>

<script>
import PhoneInput from '@/components/inputs/PhoneInput.vue'
import PasswordInput from '@/components/inputs/PasswordInput.vue'

export default {
  name: 'FormRegistrationStep3',
  components: {
    PhoneInput, PasswordInput
  },
  props: {
    "showErrors": {
      type: Boolean,
      default: false
    },
    "phone": {
      type: String,
    },
    "password": {
      type: String,
    },
    "minLength": {
      type: Number,
      default: 6
    }
  }
}
</script>
