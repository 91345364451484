<template>
  <span class="avatar photo-cover" v-if="thumb" :style="{
    'background-image': 'url(' + url + ')',
    'padding-top': height + '%',
    'border-radius': radius,
    'background-position-x': faceX + '%',
    'background-position-y': faceY + '%'
  }">
    <i class="icon-photo-hidden" v-if="hidden"></i>
    <slot></slot>
  </span>
  <span class="avatar default photo-cover" v-else :style="{
    'background-image': 'url(' + url + ')',
    'padding-top': height + '%',
    'border-radius': radius
  }">
    <slot></slot>
  </span>
</template>

<script>
import Male from '@/assets/img/male.svg'
import Female from '@/assets/img/female.svg'

export default {
  name: 'Avatar',
  props: {
    "hidden": {
      type: Boolean,
      default: false
    },
    "loading": {
      type: Boolean,
      default: false
    },
    "height": {
      type: Number,
      default: 100
    },
    "thumb": {
      type: Object,
      default: null
    },
    "gender": {
      type: String,
      default: "male"
    },
    "radius": {
      type: String,
      default: "0"
    },
  },
  computed: {
    url: function() {
      if (this.loading) {
        return ''
      }
      if (this.thumb) {
        return this.thumb.url
      } else if (this.gender != "male") {
        return Female
      }
      return Male
    },
    faceX: function() {
      if (!this.thumb.face) {
        return 50
      }
      return 100 * (this.thumb.face.left + this.thumb.face.width/2)/this.thumb.width
    },
    faceY: function() {
      if (!this.thumb.face) {
        return 50
      }
      return 100 * (this.thumb.face.top + this.thumb.face.height/2)/this.thumb.height
    },
  }
}
</script>

<style scoped>
.avatar {
  position: relative;
  display: block;
  background-color: #F6F6F6;
}
.avatar.default {
  background-size: contain;
  background-position-y: bottom;
}

.icon-photo-hidden {
  position: absolute;
  font-size: inherit;
  top: .5em;
  right: .5em;
  display: inline-block;
  color: #FFF;
  background-color: rgba(0, 0, 0, .5);
  padding: .5em;
  border-radius: .33em;
}
</style>
