<template>
  <div class="my-photos-modal">
    <button class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <div class="flex-row">
        <h4 class="flex-expand no-margin-t">Мои фото</h4>
        <button class="btn btn-link" type="button" @click="uploadPhotos">
          <i class="icon-photo-add margin-half-r"></i> Добавить фото
        </button>
      </div>
      
      <div class="pure-g container">
        <div class="pure-u-1-3 pure-u-md-1-4" v-for="(p, i) in myPhotos" :key="p ? p.id : 'smallPhoto_' + i">
          <ProfilePhotoMiniature
            class="margin-t photo"
            :photo="p"
            :editing="true"
            @click="galleryItem = i + 1"
            @setAvatar="$emit('setAvatar', p)"
            @delPhoto="$emit('delPhoto', p)"
            />
        </div>
      </div>

    </CustomScrollbar>

  </div>
</template>

<script>
import { popModal, pushModal } from "jenesius-vue-modal"
import { mapGetters } from "vuex";

import ProfilePhotoMiniature from '@/components/ProfilePhotoMiniature.vue'
import UploadPhotos from '@/components/modals/UploadPhotos.vue'

export default {
  name: 'MyPhotosModal',
  emits: ['setAvatar', 'delPhoto'],
  components: {
    ProfilePhotoMiniature
  },
  computed: {
    ...mapGetters(["myId", "myPhotos"]),
  },
  methods: {
    close() {
      popModal()
    },
    uploadPhotos() {
      pushModal(UploadPhotos, {
        uid: this.myId,
      })
    },
  }
}
</script>


<style scoped>
.my-photos-modal {
  width: 50rem;
}

h4 {
  font-weight: 600;
  font-size: 1.3rem;
}

.btn.btn-link:hover {
  box-shadow: none;
}
</style>