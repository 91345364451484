<template>
  <div @click="onToastClicked" class="toast flex-row" :class="{'clickable': !!click}">
    <div class="avatar margin-r" v-if="icon !== null">
      <Avatar :thumb="icon.thumbs ? icon.thumbs.face_sm : null" :hidden="false" :gender="gender" :height="100" radius="50%"/>
    </div>
    <div class="flex-expand content">
      <span class="name" v-if="title.length">{{ title }}</span>
      <span class="name" v-else-if="fullName.length">{{ fullName }}</span>
      <span class="text" v-html="text"></span>
    </div>
  </div>
</template>

<script>
const format = require('string-format')
import LOCALIZATIONS from "@/localizations/ru";

import Avatar from '@/components/Avatar.vue'

export default {
  name: 'Toast',
  components: {
    Avatar
  },
  props: {
    icon: {
      default: null,
      required: false
    },
    name: {
      type: String,
      default: '',
      required: false
    },
    gender: {
      type: String,
      default: 'male',
      required: false
    },
    age: {
      default: '',
      required: false
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    text: {
      type: String,
      default: '',
      required: false
    },
    click: {
      type: Function,
      default: null,
      required: false
    }
  },
  computed: {
    fullName() {
      if (this.name && this.gender) {
        return format(LOCALIZATIONS['name_age'], this.name, this.age)
      }
      return this.name
    }
  },
  methods: {
    onToastClicked(e) {
      if (this.click) {
        this.click(e)
      }
    }
  }
}
</script>


<style scoped>
.toast {
  max-width: 20rem;
  padding: .25rem;
  color: #FFF;
}
.avatar {
  width: 3.5rem;
}
.name {
  display: block;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: .5rem;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.toast .text {
  font-size: .9rem;
}
.clickable {
  cursor: pointer;
}
</style>

<style>
.mosha__toast.default {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0,0,0,.6) !important;
}

.toast .text strong,
.toast .text b {
  font-weight: 500;
  color: #9dcfff;
}
</style>