import {apiCall, SERVER_ADDRESSES} from "@/utils/api"


const state = {
  cities: {},
  citiesLoading: {},
};


const getters = {
  cities: state => state.cities,
};


const actions = {
  ["LOAD_CITIES"]: ({ commit, state /*, dispatch*/ }, ids) => {
    return new Promise(() => {
      ids = ids.filter(id => id && (!(state.cities[id] || state.citiesLoading[id])))
      if (ids.length === 0) {
        return
      }
      let uniqueCityIds = [...new Set(ids)]
      for (var id of uniqueCityIds) {
        state.citiesLoading[id] = 1
      }
      apiCall({ url: SERVER_ADDRESSES.cities + "?ids=" + uniqueCityIds.join(','), method: "GET" })
        .then(resp => {
          commit("LOAD_CITIES", resp.cities);
        })
    });
  }
};


const mutations = {
  ["LOAD_CITIES"]: (state, cities) => {
    var update = {}
    for (var c of cities) {
      update[c.id] = c
      delete state.citiesLoading[c.id]
    }
    state.cities = {...state.cities, ...update}
  }
};


export default {
  state,
  getters,
  actions,
  mutations
};