import { apiCall, SERVER_ADDRESSES } from "@/utils/api"


const state = {
  users: {},
  usersLoadedAt: {},  // last time when user was loaded - key = uid, value = time
  usersLoading: {},
  avatars: {},
  avatarsLoading: {},
  photos: {},
  photosLoading: {},

  topUsers: [],
  topUsersLoading: false,
  topUsersLastCityId: null,
  topUsersLoadedAt: null,
};


const getters = {
  usersStore: state => state.users,
  avatarsStore: state => state.avatars,
  photosStore: state => state.photos,
  topUsersStore: state => state.topUsers,
};


const actions = {
  ["LOAD_USERS"]: ({ state, commit, dispatch }, {uids, force}) => {
    return new Promise(() => {
      dispatch("LOAD_AVATARS", uids)
      const now = new Date()
      if (!force) {
        uids = uids.filter((id) => {
          if (!id || state.usersLoading[id]) {
            return false
          }
          if (!state.usersLoading[id] && !state.users[id]) {
            return true
          }
          // if elapsed more than 120 sec since last load we can reload profile
          return (now - state.usersLoadedAt[id]) / 1000 > 120 
        })
      }
      if (uids.length === 0) {
        return
      }
      for (var id of uids) {
        state.usersLoading[id] = 1
      }
      apiCall({ url: SERVER_ADDRESSES.users + "list?ids=" + uids.join(','), method: "GET" })
        .then(resp => {
          commit("LOAD_USERS", resp.users);
          let cityIds = resp.users.map(u => u.profile.city_id)
          dispatch("LOAD_CITIES", cityIds)
        })
    });
  },
  ["LOAD_AVATARS"]: ({ state, commit }, ids) => {
    return new Promise(() => {
      ids = ids.filter(id => id && !(state.avatars[id] || state.avatarsLoading[id]))
      if (ids.length === 0) {
        return
      }
      for (var id of ids) {
        state.avatarsLoading[id] = 1
      }
      apiCall({ url: SERVER_ADDRESSES.photos + "avatars?uids=" + ids.join(','), method: "GET" })
        .then(resp => {
          commit("LOAD_AVATARS", { ids: ids, photos: resp.photos })
        })
    });
  },
  ["LOAD_PHOTOS"]: ({ state, commit }, ids) => {
    return new Promise(() => {
      for (var id of ids) {
        if (state.photos[id] || state.photosLoading[id]) {
          continue
        }
        state.photosLoading[id] = 1
        apiCall({ url: SERVER_ADDRESSES.photos + id, method: "GET" }).then(resp => {
          commit("LOAD_PHOTOS", { id: id, photos: resp.photos })
        })
      }
    });
  },
  ["LOAD_TOP_USERS"]: ({ state, commit }, {force, cityId}) => {
    return new Promise(() => {
      const now = new Date()
      if (state.topUsersLoading) {
        return
      }
      if (!force && (now - state.topUsersLoadedAt) / 1000 > 120 ) {
        return
      }
      state.topUsersLoading = true
      if (!cityId && state.topUsersLastCityId) {
        cityId = state.topUsersLastCityId
      }
      state.topUsersLastCityId = cityId
      apiCall({ url: SERVER_ADDRESSES.users + "top/" + cityId + "?limit=9", method: "GET" }).then(resp => {
        commit("LOAD_TOP_USERS", resp.users);
      })
    });
  },
  ["SET_USER_BLACKLISTED"]: ({ commit }, { uid, isBlacklisted }) => {
    return apiCall({ url: SERVER_ADDRESSES.users + uid + '/blacklist/' + isBlacklisted, method: "POST" }).then(() => {
      commit("SET_USER_BLACKLISTED", { uid, isBlacklisted })
    })
  },
  ["SET_USER_FAVORITE"]: ({ commit }, { uid, isFavorite }) => {
    return apiCall({ url: SERVER_ADDRESSES.users + uid + '/favorite/' + isFavorite, method: "POST" }).then(() => {
      commit("SET_USER_FAVORITE", { uid, isFavorite })
    })
  },
  ["SET_USER_ONLINE"]: ({ state }, { uid, isOnline }) => {
    return new Promise(() => {
      if (!(uid in state.users)) {
        return
      }
      state.users[uid].is_online = !!isOnline
    });
  },
};


const mutations = {
  ["LOAD_USERS"]: (state, users) => {
    const now = new Date()
    for (var u of users) {
      state.users[u.id] = u
      state.usersLoadedAt[u.id] = now
      delete state.usersLoading[u.id]
    }
  },
  ["LOAD_AVATARS"]: (state, { ids, photos }) => {
    for (var p of photos) {
      state.avatars[p.uid] = p
      delete state.avatarsLoading[p.uid]
    }
    // some users may have no avatar, set them to false (means it's loaded but not exists)
    for (var id of ids) {
      if (!state.avatars[id]) {
        state.avatars[id] = false
      }
    }
  },
  ["LOAD_PHOTOS"]: (state, { id, photos }) => {
    delete state.avatarsLoading[id]
    state.photos[id] = photos
  },
  ["LOAD_TOP_USERS"]: (state, users) => {
    const now = new Date()
    state.topUsersLoadedAt = now
    state.topUsersLoading = false
    state.topUsers = []
    for (var u of users) {
      state.topUsers.push(u.id)
      state.users[u.id] = u
      state.usersLoadedAt[u.id] = now
    }
  },
  ["SET_USER_BLACKLISTED"]: (state, { uid, isBlacklisted }) => {
    if (!(uid in state.users)) {
      return
    }
    state.users[uid].relations.is_blacklisted = isBlacklisted
  },
  ["SET_USER_FAVORITE"]: (state, { uid, isFavorite }) => {
    if (!(uid in state.users)) {
      return
    }
    state.users[uid].relations.is_favorite = isFavorite
  },
};


export default {
  state,
  getters,
  actions,
  mutations
};