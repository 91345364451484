<template>
  <div class="pure-g container">
    <div class="pure-u-1 form-field" v-bind:class="{error: !name && showErrors}">
      <label>Меня зовут</label>
      <input :value="name" @input="$emit('update:name', $event.target.value)" type="text" placeholder="Введите имя" />
      <span class="smaller hint">Под этим именем вас будут видеть другие пользователи</span>
    </div>
    <div class="pure-u-1 form-field">
      <label style="position: static;">Дата рождения</label>
      <BirthdayPicker :showErrors="showErrors" :modelValue="birthday" @update:modelValue="$emit('update:birthday', $event)"/>
      <span class="smaller hint">Вам должно быть не менее 18 лет</span>
      <!-- <span class="block txt-c margin-half-v error" v-show="showErrors && !isAgeCorrect">Для регистрации вам должно быть больше <b>{{minAge}}&nbsp;лет</b>!</span> -->
    </div>
  </div>
</template>

<script>
import BirthdayPicker from '@/components/inputs/BirthdayPicker.vue'

export default {
  name: 'FormRegistrationStep2',
  components: {
    BirthdayPicker
  },
  props: {
    "showErrors": {
      type: Boolean,
      default: false
    },
    "name": {
      type: String,
    },
    "birthday": {
      type: String,
    }
  }
}
</script>
