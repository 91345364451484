<template>
  <div class="payment-modal-success">
    <button class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <span class="done"><i class="icon-check"></i></span>

      <h4 class="txt-c no-margin-t">Оплата прошла успешно!</h4>
      <p class="txt-c" v-if="days == 1">Премиум подключен на 24 часа</p>
      <p class="txt-c" v-else-if="days == 7">Премиум подключен на 7 дней</p>
      <p class="txt-c" v-else-if="days == 30">Премиум подключен на 30 дней</p>
      <p class="txt-c" v-else-if="days == 90">Премиум подключен на 90 дней</p>

      <div class="footer-modal">
        <button class="btn full-width" @click="close">Закрыть</button>
      </div>
    </CustomScrollbar>

  </div>
</template>

<script>
import { popModal } from "jenesius-vue-modal"

export default {
  name: 'PaymentModalSuccess',
  props: {
    "days": {
      type: Number,
      required: true
    }
  },
  methods: {
    close() {
      popModal()
    },
  }
}
</script>


<style scoped>
.payment-modal-success {
  width: 30rem;
}

h4 {
  font-weight: 600;
  font-size: 1.3rem;
}

.footer-modal {
   margin-top: 2rem;
}

/* md+ */
@media screen and (min-width: 48em) {
  p {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}

.done {
  display: block;
  margin: 2rem auto 0;
  width: 5rem;
  height: 5rem;
  line-height: 5.7rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 50%;
  color: #FFF;
  background-color: #00d26d;
}

</style>