<template>
  <div class="password-container">
    <input :value="modelValue" :type="isHidden ? 'password' : 'text'" @input="this.$emit('update:modelValue', $event.target.value)"/>
    <button class="btn btn-white" type="button" @click="isHidden = !isHidden">
      <i v-if="isHidden" class="icon icon-eye-closed"></i>
      <i v-else class="icon icon-eye-open"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'PasswordInput',
  emits: ['update:modelValue'],
  props: {
    "modelValue": {
      type: String,
    },
  },
  data: function() {
    return {
      isHidden: true,
      password: '',
    }
  }
}
</script>

<style scoped>
.password-container {
  position: relative;
}

.password-container input {
  outline: none;
  background: transparent;
  padding-right: 3rem;
}

.password-container button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3rem;
}

.password-container button i {
font-size: .6rem;
}
.password-container button i.icon-eye-closed {
  font-size: .45rem;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>