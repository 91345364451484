<template>
  <div class="wrapper">
    <Transition name="slide-fade">
      <button class="btn btn-link btn-red" type="button" @click="scrollTop" v-show="isVisible">
        <span>
          <i class="icon-angle-left"></i>
        </span>
      </button>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'ScrollTop',
  data: function() {
    return {
      isVisible: false,
      scrollElement: null
    }
  },
  mounted() {
    this.scrollElement = document.querySelector('#app > .kvisto-app')
    this.scrollElement.addEventListener("scroll", this.handleScroll)
  },
  unmounted() {
    this.scrollElement.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    handleScroll() {
      let body = this.scrollElement.children[0],
        p = body.parentNode,
        bottomOffset = (body.scrollTop || p.scrollTop)
      this.isVisible = bottomOffset - p.clientHeight > 0
    },
    scrollTop() {
      this.scrollElement.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
</script>

<style scoped>
.wrapper {
  position: absolute;
  right: 6rem;
  top: calc(100vh - 8rem);
}
.btn {
  z-index: 1;
  position: fixed;
  width: 4rem;
  height: 4rem;
  padding: 0;
  border-radius: 50%;

  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.3);
}

.btn.btn-red:hover {
  background-color: #F5F5F5;
  box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.3);
}

.btn span {
  display: block;
  position: relative;
  top: -.2rem;
  left: -.05rem;
  transform: rotate(90deg);
  line-height: 4.2;
}


.slide-fade-enter-active {
  transition: all .2s ease-out;
}

.slide-fade-leave-active {
  transition: all .2s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
