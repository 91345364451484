<template>
  <div class="pure-g container">
    <div class="pure-u-1 pure-u-sm-1-2 form-field" v-bind:class="{error: !gender && showErrors}">
      <label for="registration__gender">Я</label>
      <GenderPicker
        inputId="registration__gender"
        :modelValue="gender"
        @update:modelValue="onGenderUpdate" />
    </div>
    <div class="pure-u-1 pure-u-sm-1-2 form-field" v-bind:class="{error: !looking_for && showErrors}">
      <label for="registration__looking_for">Ищу</label>
      <GenderPicker
        inputId="registration__looking_for"
        type="looking_for"
        :modelValue="looking_for"
        @update:modelValue="$emit('update:looking_for', $event)" />
    </div>
    <div class="pure-u-1 form-field" v-bind:class="{error: !city_id && showErrors}">
      <label for="registration__city">Из города</label>
      <CityPicker
        inputId="registration__city"
        :clearable="true"
        :modelValue="city_id"
        @update:modelValue="$emit('update:city_id', $event)" />
      <span class="city-suggest">
        Ваш город
        <a v-if="detectedCity" class="line-inverse" href="javascript:" @click="$emit('update:city_id', detectedCity.id)">{{ detectedCity.name }}?</a>
      </span>
    </div>
  </div>
</template>

<script>
import {apiCall, SERVER_ADDRESSES} from "@/utils/api";

import CityPicker from '@/components/inputs/CityPicker.vue'
import GenderPicker from '@/components/inputs/GenderPicker.vue'


export default {
  name: 'FormRegistrationStep1',
  components: {
    CityPicker, GenderPicker
  },
  props: {
    "showErrors": {
      type: Boolean,
      default: false
    },
    "gender": {
      type: String,
    },
    "looking_for": {
      type: String,
    },
    "city_id": {
      type: String,
    }
  },
  data: function() {
    return {
      detectedCity: null
    }
  },
  mounted: function() {
    this.detectCity()
  },
  methods: {
    onGenderUpdate(gender) {
      if (gender == 'male') {
        this.$emit('update:looking_for', 'female')
      } else {
        this.$emit('update:looking_for', 'male')
      }
      this.$emit('update:gender', gender);
    },
    detectCity() {
      apiCall({ url: SERVER_ADDRESSES.cities + "default", method: "GET" })
        .then(resp => {
          this.detectedCity = resp.city
        })
    },
  }
}
</script>

<style scoped>
.city-suggest {
  display: inline-block;
  font-weight: 400;
  color: #14294380;
  font-size: .9em;
  padding: .5rem;
}
.city-suggest a,
.city-suggest a:active,
.city-suggest a:visited {
  color: #142943;
  font-weight: 500;
}

.administrative {
  font-size: .8em;
  color: #999;
}
</style>
