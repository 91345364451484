<template>
  <VTooltip :distance="10">
    <div class="avatar">
      <Avatar
        :thumb="avatar ? avatar.thumbs.face_sm : null"
        :hidden="false"
        :gender="user ? user.profile.gender : 'male'"
        :loading="!avatar && avatar !== false"
        :height="100"
        radius="50%" />
      <div v-if="user && user.is_online" class="online-badge"></div>
    </div>

    <template #popper>
      <span v-if="user">{{ user.profile.name }}, {{ user.profile.age }}</span>
      <span v-else>...</span>
    </template>
  </VTooltip>
</template>

<script>
import { mapGetters } from "vuex";

import Avatar from '@/components/Avatar.vue'

export default {
  name: 'ProfileCircle',
  components: {
    Avatar
  },
  props: {
    "uid": {
      required: true
    }
  },
  computed: {
    ...mapGetters(["usersStore", "avatarsStore"]),
    user() {
      if (!(this.uid in this.usersStore)) {
        this.$store.dispatch("LOAD_USERS", {uids: [this.uid]})
      }
      return this.uid in this.usersStore ? this.usersStore[this.uid] : null
    },
    avatar() {
      if (!(this.uid in this.avatarsStore)) {
        this.$store.dispatch("LOAD_AVATARS", [this.uid])
      }
      if (this.user && this.user.profile.photo_count == 0) {
        return false
      }
      return this.uid in this.avatarsStore ? this.avatarsStore[this.uid] : null
    },
  }
}
</script>


<style scoped>
.avatar {
  position: relative;
  border-radius: 50%;
  transition: all .2s ease-in-out;
}
.avatar:hover {
  box-shadow: inset 0 0 20em rgba(0,0,0,0.1);
}

.online-badge {
  position: absolute;
  display: inline-block;
  width: 15%;
  height: 15%;
  bottom: 6%;
  right: 3%;
  border-radius: 50%;
  border: 2px solid #FFF;
  background-color: #00d26d;
}
</style>