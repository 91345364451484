<template>
  <div class="gallery">
    <Swiper class="slider" ref="swiper" :modules="swiperModules" :navigation="true" :initialSlide="index" loop
      :slides-per-view="1" @slideChange="onSlideChange">
      <SwiperSlide class="slide" v-for="p in photos" :key="'gallery_' + p.id">
        <img :src="p.thumbs.orig.url" alt="" />
      </SwiperSlide>
    </Swiper>
    <div class="controls flex-row">
      <div class="padding flex-expand">
        <div class="photo-count">
          <i class="icon-photo"></i>{{ activeIndex }}/{{ photos.length }}
        </div>
      </div>
      <MenuComplain v-if="uid && !editing" :uid="uid">
        <button class="btn btn-transparent btn-more"><i class="icon-dots"></i></button>
      </MenuComplain>
      <VDropdown v-else-if="uid" placement="bottom-end">
        <button class="btn btn-transparent btn-more"><i class="icon-dots"></i></button>
        <template #popper="{ hide }">
          
          <button class="btn btn-context-menu" type="button" @click="$emit('delPhoto', activeIndex-1); onClose(); hide()">Удалить фотографию</button>
          <button class="btn btn-context-menu" type="button" v-show="photos[activeIndex-1].has_face && !photos[activeIndex-1].is_avatar" @click="$emit('setAvatar', activeIndex-1); onClose(); hide()">Сделать главной</button>
        </template>
      </VDropdown>
      <button type="button" class="btn btn-transparent btn-times" @click="onClose"><i class="icon-times"></i></button>
    </div>
  </div>
</template>

<script>
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';

import 'swiper/swiper.min.css';
import "swiper/modules/navigation/navigation.min.css";

import MenuComplain from '@/components/MenuComplain.vue'


export default {
  name: 'Gallery',
  components: {
    Swiper,
    SwiperSlide,
    MenuComplain
  },
  emits: ["close", "change", "delPhoto", "setAvatar"],
  props: {
    "uid": {
      type: String,
      required: false
    },
    "photos": {
      type: Array,
      required: true
    },
    "index": {
      type: Number,
      default: 0
    },
    "editing": {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: 0,
      swiperModules: [Navigation]
    }
  },
  mounted() {
    this.activeIndex = this.index + 1
    document.addEventListener("keydown", this.onKeydown);
  },
  unmounted() {
    document.removeEventListener("keydown", this.onKeydown);
  },
  methods: {
    onSlideChange(e) {
      this.$emit('change', e.activeIndex)
      this.activeIndex = e.activeIndex % this.photos.length
      if (this.activeIndex === 0) {
        this.activeIndex = this.photos.length
      }
    },
    onKeydown(e) {
      if (e.key === "Escape") {
        this.onClose()
      } else if (e.key === "ArrowRight") {
        this.$refs.swiper.$el.swiper.slideNext()
      } else if (e.key === "ArrowLeft") {
        this.$refs.swiper.$el.swiper.slidePrev()
      }
    },
    onClose() {
      this.$emit('close', this.activeIndex)
    }
  }
}
</script>

<style>
.swiper-slide,
.swiper-slide.swiper-slide-duplicate {
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>


<style scoped>
.gallery {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .8);
  z-index: 9999;
  user-select: none;
}

.gallery .controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #EEE;
  z-index: 1;
}

.gallery .controls,
.gallery .controls .btn {
  font-size: 1.4rem;
}

.slider {
  height: 100%;
}

img {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
}

.btn-more {
  width: auto;
  height: auto;
  margin: .75rem 0;
  padding: .75rem 1rem;
}

.btn-more i {
  top: -.2rem;
}

.btn.btn-times {
  margin: .75rem 1rem;
  padding: .75rem;
}

.btn.btn-transparent:hover {
  box-shadow: inset 0 0 20em rgba(255, 255, 255, 0.1);
}


.photo-count {
  display: inline-block;
  background: rgba(0, 0, 0, 0.2);
  padding: .25rem .5rem;
  border-radius: .25rem;
}

.photo-count i {
  font-size: .8em;
  margin-right: .33rem;
}
</style>