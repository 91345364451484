import { createStore } from 'vuex'

import auth from "./modules/auth";
import cities from "./modules/cities";
import users from "./modules/users";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    cities,
    users
  }
})
