export const SERVER_ADDRESSES = {
  cities: "https://kvisto.com/api/cities/",
  auth: "https://kvisto.com/api/auth/",
  photos: "https://kvisto.com/api/photos/",
  users: "https://kvisto.com/api/users/",
  interests: "https://kvisto.com/api/interests/",
  dialogs: "https://kvisto.com/api/dialogs/",
  events:  "https://kvisto.com/api/events/",

  ws: "wss://kvisto.com/api/events/ws",
}

export const getToken = () => {
  return localStorage.getItem("tkn")
}

export const apiAuthHeaders = () => {
  let token = getToken()
  if (token) {
    return {"Authorization": "Bearer " + token}
  }
  return {}
}

export const apiCall = ({ url, data, method }) =>
new Promise((resolve, reject) => {
  let headers = Object.assign({
    'Content-Type': 'application/json'
  }, apiAuthHeaders())
  fetch(url, {
      method: method,
      body: JSON.stringify(data),
      headers,
  })
  .then((response) => {
    return response.json();
  })
  .then((respData) => {
    console.log(`${method} ${url}:`, respData);
    resolve(respData);
  })
  .catch(reason => {
    console.log(`ERROR ${method} ${url}:`, reason);
    reject(reason);
  })
});

/*
export const apiFile = ({ url, data, method }) =>
new Promise((resolve, reject) => {
  let headers = Object.assign({
    'Content-Type': 'application/json'
  }, apiAuthHeaders())
  fetch(url, {
      method: method,
      body: JSON.stringify(data),
      headers,
  })
  .then( res => res.blob() )
  .then( blob => {
    var file = window.URL.createObjectURL(blob);
    window.location.assign(file);
  })
  .catch(reason => {
    console.log(`ERROR ${method} ${url}:`, reason);
    reject(reason);
  })
});
*/

export const apiErrCodes = {
  NO_ERROR: 0,
  EXPECTED_PARAM_ERROR: 1,
  INVALID_VALUE_ERROR: 2,
  CLIENT_NOT_FOUND_ERROR: 3,
  ACCESS_TOKEN_NOT_FOUND_ERROR: 4,
  ACCESS_TOKEN_EXPIRED: 5,
  OBJECT_NOT_FOUND_ERROR: 6,
  PREMIUM_EXPECTED_ERROR: 7,
  BLACKLISTED_ERROR: 8,
  PRIVACY_ERROR: 9,
  TOO_MANY_DATA_ERROR: 10,
  PHOTO_EXPECTED_ERROR: 11,
  ANSWER_EXPECTED_ERROR: 12,
  PERMISSION_ERROR: 13,
  PASSWORD_ERROR: 14,
  DUPLICATE_ERROR: 15,
  THE_SAME_INPUT_ERROR: 16,
  LIMITS_ERROR: -2,
  UNKNOWN_ERROR: -1,
}

export const apiEventTypes = {
  USER_VISIT: 10,
  USER_LIKE: 12,
  USER_PAIR: 14,

  CHAT_TYPING: 20,
  CHAT_READ: 22,
  CHAT_MESSAGE: 24,
  CHAT_MESSAGE_UPDATED: 25
}

export default apiCall;
