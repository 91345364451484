<template>
  <div class="confirm-modal">
    <button class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <h4 class="txt-c">{{ title ?? 'Подтверждение' }}</h4>

      <p class="txt-c" v-html="text"></p>

      <div class="footer-modal">
        <div class="pure-g">
          <button type="button" class="pure-u-1-2 btn" @click="close">
            {{ denyText ?? 'Отмена' }}
          </button>
          <button type="button" class="pure-u-1-2 btn btn-link text-red" @click="confirm">
            {{ confirmText ?? 'Подтверждаю' }}
          </button>
        </div>
      </div>
    </CustomScrollbar>

  </div>
</template>

<script>
import { popModal } from "jenesius-vue-modal"

export default {
  name: 'ConfirmAnything',
  emits: ['confirmed'],
  props: {
    text: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null,
      required: false
    },
    confirmText: {
      type: String,
      default: null,
      required: false
    },
    denyText: {
      type: String,
      default: null,
      required: false
    },
  },
  methods: {
    close() {
      popModal()
    },
    confirm() {
      this.$emit('confirmed')
      this.close()
    }
  }
}
</script>


<style scoped>
.confirm-modal {
  width: 27rem;
}

.footer-modal {
  margin-top: 2rem;
}

h4 {
  margin: 0 0 1.5rem;
  font-weight: 500;
  color: #222020;
  font-size: 1.5rem;
}
</style>