export default {
  methods: {
    mixin_autoResize_resize(el) {
      el.style.height = "auto";
      el.style.height = `${el.scrollHeight + 1}px`;
    }
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.$el.setAttribute("style", "height",
  //       `${this.$el.scrollHeight}px`);
  //   });
  // }
};