<template>
  <div class="full-width">
    <div class="pure-g container-sm">
      <div class="pure-u-6-24" v-bind:class="{error: (!day || !isAgeCorrect) && showErrors}">
        <VueSelect
          v-model="day"
          :options="days"
          :clearable="false"
          :searchable="false"
          placeholder="День"
          @selected="dateUpdated"/>
      </div>
      <div class="pure-u-10-24" v-bind:class="{error: (!month || !isAgeCorrect) && showErrors}">
        <VueSelect
          v-model="month"
          :options="months"
          :clearable="false"
          :searchable="false"
          placeholder="Месяц"
          @selected="dateUpdated"/>
      </div>
      <div class="pure-u-8-24" v-bind:class="{error: (!year || !isAgeCorrect) && showErrors}">
        <VueSelect
          v-model="year"
          :options="years"
          :clearable="false"
          :searchable="false"
          placeholder="Год"
          @update:modelValue="dateUpdated"
          @selected="dateUpdated"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BirthdayPicker',
  emits: ['update:modelValue'],
  props: {
    "showErrors": {
      type: Boolean,
      default: false
    },
    "modelValue": {
      type: String,
    },
    "minAge": {
      type: Number,
      default: 18
    },
    "maxAge": {
      type: Number,
      default: 80
    },
  },
  data: function() {
    return {
      day: null,
      month: null,
      year: null,
      months: [
        {label: "Января", key: 1},
        {label: "Февраля", key: 2},
        {label: "Марта", key: 3},
        {label: "Апреля", key: 4},
        {label: "Мая", key: 5},
        {label: "Июня", key: 6},
        {label: "Июля", key: 7},
        {label: "Августа", key: 8},
        {label: "Сентября", key: 9},
        {label: "Октября", key: 10},
        {label: "Ноября", key: 11},
        {label: "Декабря", key: 12}],
    }
  },
  watch: {
    modelValue: function(bday) {
      if (bday) {
        let parsed = this.parseDate(bday)
        this.day = parsed.day
        this.month = this.months.find(m => m.key == parseInt(parsed.month))
        this.year = parsed.year
      } else {
        this.day = null
        this.month = null
        this.year = null
      }
    }
  },
  computed: {
    days: function() {
      let daysInMoth = 31
      if (this.month) {
        // 2020 means any year Leap year (високосный год)
        let year = this.year ? this.year : 2020
        let month = this.month ? this.month.key : 0
        // zero day means -1 day for next month == last day in month
        daysInMoth = new Date(year, month, 0).getDate()
      }
      return Array.from(new Array(daysInMoth), (x, i) => i+1)
    },
    years: function() {
      let year = new Date().getFullYear()
      return Array.from(new Array(this.maxAge - this.minAge + 1), (x, i) => year - this.minAge - i)
    },
    age: function() {
      if (!this.day || !this.month || !this.year) {
        return null
      }
      var today = new Date();
      var birthDate = new Date(this.year, this.month.key-1, this.day);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;
    },
    isAgeCorrect: function() {
      return !this.age || (this.age >= this.minAge && this.age <= this.maxAge)
    }
  },
  methods: {
    parseDate(dateStr) {
      const split = dateStr.split('-');

      return {
        year: +split[0],
        month: +split[1],
        day: +split[2]
      };
    },
    dateUpdated() {
      this.$nextTick(() => {
        if (this.day > this.days.length) {
          this.day = this.days[0]
        }
        if (this.day && this.month && this.year && this.age >= this.minAge && this.age <= this.maxAge) {
          var birthDate = new Date(Date.UTC(this.year, this.month.key-1, this.day));
          this.$emit('update:modelValue', birthDate.toISOString().split('T')[0])
        } else {
          this.$emit('update:modelValue', '')
        }
      })
    },
  }
}
</script>
