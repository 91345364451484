<template>
  <div class="flex-row padding">
    <router-link v-if="uid" class="btn btn-white margin-r hidden-md hidden-lg hidden-xl" :to="{ name: 'Messages' }"><i
        class="icon-angle-left"></i></router-link>
    <div class="flex-expand">
      <router-link v-if="uid" class="name" :to="{ name: 'Profile', params: { uid: uid } }">
        {{ user ? user.profile.name : '&nbsp;' }}
      </router-link>
      <span v-else class="name">&nbsp;</span>

      <span v-if="user && user.is_online" class="status online">online</span>
      <span v-else-if="user" class="status offline">{{ lastOnline }}</span>
      <span v-else class="status offline">&nbsp;</span>
    </div>
    <MenuProfile v-if="user" :uid="user.id">
      <button class="btn btn-white btn-more"><i class="icon-dots"></i></button>

      <template #other-buttons="{ hide }">
        <button class="btn btn-context-menu" @click="confirmDelChat(); hide()">Скрыть диалог</button>
      </template>
    </MenuProfile>
  </div>
</template>

<script>
const format = require('string-format')
import moment from 'moment'

import LOCALIZATIONS from "@/localizations/ru"

import { mapGetters } from "vuex";
import { pushModal } from "jenesius-vue-modal"

import ConfirmAnything from '@/components/modals/ConfirmAnything.vue'
import MenuProfile from '@/components/MenuProfile.vue'

export default {
  name: 'ChatHeader',
  emits: ['delDialog'],
  props: {
    "uid": {
      type: String,
      default: null
    }
  },
  components: {
    MenuProfile
  },
  computed: {
    ...mapGetters(["usersStore"]),
    user: function () {
      return this.usersStore[this.uid]
    },
    lastOnline() {
      if (!this.user || this.user.is_online) {
        return false
      }
      if (!this.user.last_online) {
        return 'Не в сети'
      }
      const text = LOCALIZATIONS['was_online_' + this.user.profile.gender]
      return format(text, moment.utc(this.user.last_online).local().calendar().toLowerCase())
    }
  },
  watch: {
    uid: function () {
      this.userUpdated()
    }
  },
  data: function () {
    return {
    }
  },
  mounted() {
    this.userUpdated()
  },
  methods: {
    userUpdated() {
      if (this.uid) {
        this.$store.dispatch("LOAD_USERS", {uids: [this.uid]})
      }
    },
    async confirmDelChat() {
      const modal = await pushModal(ConfirmAnything, {
        title: "Скрыть диалог",
        text: "Вы уверены, что хотите скрыть выбранный диалог из списка?"
      })
      modal.on('confirmed', () => {
        this.$emit('delDialog', this.uid)
      })
    }
  }
}
</script>

<style scoped>
.name,
.name:active,
.name:visited {
  display: inline-block;
  color: #4a4747;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 400;
}

.name:focus,
.name:hover {
  color: #000;
}

.status {
  display: block;
}

.status.offline {
  color: #908F8F;
}

.status.online {
  color: #72BBFF;
}

.btn.btn-white {
  line-height: 0;
}
</style>