<template>
  <VueSelect
    :modelValue="value"
    @update:modelValue="$emit('update:modelValue', $event.value)" 
    :options="genders"
    :clearable="false"
    :searchable="false"
    placeholder="Выберите ваш пол"/>
</template>

<script>
import LOCALIZATIONS from "@/localizations/ru";

export default {
  name: 'GenderPicker',
  emits: ['update:modelValue'],
  props: {
    "showErrors": {
      type: Boolean,
      default: false
    },
    "modelValue": {
      type: String,
    },
    "type": {
      type: String,
      default: "mine"  // or "looking_for"
    }
  },
  data: function() {
    return {
      value: null,
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.value = this.genders.find(g => g.value == this.modelValue)
      }
    }
  },
  computed: {
    genders: function() {
      let options = []
      let translations = this.type == "mine" ? LOCALIZATIONS.registration.gender : LOCALIZATIONS.registration.looking_for
      for (let [key, value] of Object.entries(translations)) {
        options.push({label: value, value: key})
      }
      return options
    },
  },
}
</script>
