<template>
  <GridOffset>
    <Header/>
    <article class="max-width">
      <h1>Пользовательское соглашение Kvisto</h1>
      <p>
        Пользовательское соглашение об условиях использования интернет-сервиса kvisto.com (далее – Соглашение, пользовательское соглашение) – оферта, адресованная неограниченному кругу лиц, представленная в настоящем документе, дополнениях, приложениях и протоколах к нему.
      </p>
      <p>
        Настоящий документ является официальным, публичным и безотзывным предложением заключить настоящее Соглашение в части использования информационно-коммуникационных технологий, возможностей предоставляемых с помощью интернет-сервиса kvisto.com на изложенных ниже условиях.
      </p>
      <p>
        <strong>
          Пожалуйста, внимательно прочитайте данные условия использования, прежде чем начнете пользоваться интернет-сервисом kvisto.com.
        </strong>
      </p>
      <p>
        Пользуясь интернет-сервисом kvisto.com вы подтверждаете, что прочитали, поняли, приняли, акцептовали и полностью согласны соблюдать настоящее Соглашение. Любой пользователь считается таковым с момента регистрации на сайте и (или) совершения иных действий, связанных с использованием сайта или возможностей сайта и до момента прекращения обязательств сторон по основаниям, предусмотренным законодательством РФ.
      </p>
      <p>
        <strong>
          Если вы не согласны с условиями использования, пожалуйста, не используйте интернет-сервис kvisto.com.
        </strong>
        Если вы не согласны с настоящим Соглашением, Вы не можете пользоваться сайтом.
      </p>
      <h2>1. Термины и определения.</h2>
      <p>
        <strong>Сайт (веб-сайт, интернет-сервис kvisto.com, kvisto.com)</strong> -  сайт располагающийся по интернет-адресу: www.kvisto.com. Интернет-сервис kvisto.com является сложным объектом интеллектуальной собственности, представляющей собой совокупность программ для ЭВМ, баз данных, текстовой, графической и иной информации и/или объектов доступных для пользователя.  Сайт является информационным посредником, вспомогательным ресурсом предоставляющим пользователям возможность дистанционного общения и знакомства, не заменяющим собой непосредственное личное общение между пользователями. Сайт управляется Администрацией Сайта (далее также Администрация, служба поддержки), осуществляющей в том числе контроль за соблюдением настоящего Соглашения. 
      </p>
      <p>
        <strong>Пользователь</strong> – дееспособное физическое лицо, достигшее 18 лет, присоединившееся к Соглашению и пользующееся сайтом на условиях, определенных Соглашением. Пользователь подтверждает и гарантирует, что достиг восемнадцатилетнего возраста, является полностью дееспособным физическим лицом, полностью оценивает риски и самостоятельно принимает решение пользоваться сайтом. Пользователь не ограничен в дееспособности по законодательству государства, гражданином которого является.
      </p>
      <p><strong>Данные пользователя</strong> – персональные данные</p>
      <p>
        <strong>Персональные данные</strong> – личная информация (в том числе фамилия, имя, отчество, дата рождения, адрес, контактный телефон, адрес электронной почты), добровольно и осознанно предоставляемая пользователем-физическим лицом при регистрации на сайте и необходимая для доступа пользователя к возможностям интернет-сервиса kvisto.com.
      </p>
      <p>
        <strong>Регистрация</strong> – процедура передачи пользователем информации о себе, а также своих персональных данных, необходимая для предоставления доступа к возможностям сайта. Регистрация и авторизация на сайте осуществляется с подтверждением по СМС на мобильный телефон пользователя. Пользователю на указанный им номер мобильного телефона направляется одноразовый пароль, ввод которого обеспечивает Пользователю возможность использовать сервисы сайта. Порядок регистрации (создание учетной записи) устанавливается с учетом технических особенностей и возможностей сайта.
      </p>
      <p>
        <strong>Авторизация</strong> – процедура ввода пары логин/пароль на сайте, направленная на идентификацию авторизующегося лица в качестве конкретного пользователя сайта и необходимая для использования возможностей сайта. Пользователь обязан проходить авторизацию при каждом новом посещении сайта для использования его возможностей. Подробный порядок авторизации устанавливается с учетом технических возможностей сайта.
      </p>
      <p>
        <strong>Логин</strong> – придуманная при регистрации пользователем комбинация латинских букв и/или цифр.
      </p>
      <p>
        <strong>Пароль</strong> - придуманная при регистрации пользователем комбинация латинских букв и/или цифр.
      </p>
      <p>
        <strong>Учетная запись пользователя</strong> – аккаунт, состоящий из данных пользователя, предоставленных пользователем фотоизображений, информации о пользователе, логина/пароля, используемых для идентификации пользователя и присваиваемых ему в ходе регистрации, другого контента, размещаемого пользователем.
      </p>
      <p>
        <strong>Счет учетной записи</strong> - счет пользователя в соответствующем разделе учетной записи, на котором учитываются платежи пользователя для доступа к платным возможностям сайта. Счет является элементом программно-аппаратного комплекса, интегрированного сайтом, предназначенным в том числе для осуществления сайтом внутреннего учета обязательств настоящего Соглашения сайта перед пользователем и пользователем перед сайтам. Счет учетной записи не является счетом, открытым кредитными организациями (банками) и/или платежными системами, а также счетами депозитария.
      </p>
      <h2>1. Гарантия и заверения.</h2>
      <p>Интернет-сервис kvisto.com и пользователь настоящим заявляют и гарантируют, что на дату заключения соглашения и в период его действия:</p>
      <ul>
        <li>каждый обладает всеми правами и полномочиями для заключения настоящего Соглашения и исполнения обязательств, принимаемых на себя по пользовательскому соглашению;</li>
        <li>каждый предпринял все действия, необходимые для заключения и исполнения пользовательского соглашения, и для обеспечения того, чтобы пользовательское соглашение являлось законным, обязательным для исполнения им и подлежащим исполнению в отношении его контрагента;</li>
        <li>не существует, насколько известно, нерешенных вопросов правового характера, угрозы судебного разбирательства или иных обстоятельств, которые могут иметь негативные последствия для стороны пользовательского соглашения или его деятельности, стороной пользовательского соглашения не допущено нарушения каких-либо существенных положений какого-либо заключенного им договора или иного соглашения, нет постановлений, определений и решений суда, арбитражного суда или другого государственного органа, которые могут оказать отрицательное воздействие на положение стороны пользовательского соглашения или их деятельность;</li>
        <li>не существует никаких положений в российском законодательстве или иных нормативных актах, в договорах, имеющих обязательную силу для стороны пользовательского соглашения или имущества, которые могут нарушать или препятствовать подписанию и/или исполнению пользовательского соглашения;</li>
        <li>исполнение обязательств по пользовательскому соглашению является безусловным обязательством стороны пользовательского соглашения, которое, как минимум, имеет такой же приоритет, как и остальные обязательства стороны пользовательского соглашения;</li>
        <li>вся информация и документы, предоставленные стороной пользовательского соглашения другой стороне в связи с настоящим пользовательским соглашением, соответствуют действительности, являются достоверными, полными и точными во всех отношениях, и сторона пользовательского соглашения не скрывает никаких фактов, которые, если бы они стали известны, могли бы оказать неблагоприятное влияние на решение другой стороны пользовательского соглашения о заключении данного соглашения;</li>
        <li>каждый обладает достаточной информацией о своем контрагенте в объеме, необходимом для выбора его в качестве такого, понимания наличия у него достаточных ресурсов для исполнения своих обязательств по пользовательскому соглашению;</li>
        <li>обязательства стороны пользовательского соглашения являются законными, действительными, действующими и обязательными для исполнения стороной пользовательского соглашения, подлежащими исполнению в соответствии с положениями пользовательского соглашения.</li>
      </ul>
      <p>Пользователь заявляет и гарантирует kvisto.com, что на дату заключения настоящего соглашения и в период его действия:</p>
      <ul>
        <li>имеет необходимое и исправное для пользования сайта техническое оборудование, программное обеспечение, возможность пользования сайтом и доступ в сеть Интернет;</li>
        <li>обеспечит своими силами и за свой счет соблюдение авторских, патентных и любых иных прав третьих лиц при исполнении своих обязательств по настоящему соглашению;</li>
        <li>материалы, загружаемые пользователем с использованием возможностей сайта, содержат исключительно объекты интеллектуальной собственности самого пользователя и (или) третьих лиц, с которыми пользователь заключил соглашения об использовании результата интеллектуальной деятельности (далее также РИД). Настоящим пользователь на срок действия исключительного права на РИД и/или на срок действия настоящего соглашения (в зависимости от того, какой срок наступит позднее) предоставляет kvisto.com право использования РИД в пределах, необходимых для исполнения обязательств по Соглашению, в том числе посредством переработки, воспроизведения, доведения до всеобщего обозрения, распространения, публичного показа, размещения в сети Интернет;</li>
        <li>исполнение kvisto.com своих обязательств при обработке материалов, загружаемых пользователем (контента) с использованием возможностей сайта, не повлечет нарушение авторских, патентных и любых иных прав пользователя, третьих лиц. Пользователь является лицом, обладающим правом использования РИД. РИД, а также интеллектуальные права на РИД не отчуждены, не заложены. РИД, а также интеллектуальные права на РИД не оспорены в суде или иным законным способом.</li>
      </ul>
      <p>Каждая из сторон настоящим признает, что заключает настоящее соглашение, полагаясь на заверения и гарантии, предоставленные другой стороной и имеющие для нее существенное значение.</p>
      <h2>2. Общие положения.</h2>
      <p>Интернет-сервис kvisto.com предлагает и предоставляет пользователям право использовать сайт на условиях, изложенных в настоящем пользовательском соглашении.</p>
      <p>Использование интернет-сервиса kvisto.com, а также все отношения между пользователем и kvisto.com регулируются настоящим пользовательским соглашением.</p>
      <p>Любой пользователь, используя сайт или воспользовавшись любой его функциональной возможностью, выражает тем самым свое безоговорочное согласие со всеми условиями пользовательского соглашения и всеми иными условиями, изложенными на страницах сайта, обязуетесь соблюдать их, а в случае несогласия с любым из условий немедленно прекратить использование и покинуть сайт.</p>
      <p>Настоящее пользовательское соглашение распространяется на все существующие на данный момент сервисы и возможности сайта, а также на любое их развитие и/или добавление новых сервисов и возможностей, изменение существующих сервисов и возможностей.</p>
      <p>Kvisto.com оставляет за собой право изменять и (или) дополнять настоящее пользовательское соглашение без какого-либо специального уведомления. Пользователю необходимо время от времени посещать данную страницу и указанные здесь ссылки, чтобы быть в курсе изменений, которые вносятся, и которые пользователи должны соблюдать. Некоторые положения в настоящем Соглашении могут быть заменены положениями и замечаниями, опубликованными в других разделах сайта. Молчание пользователя расценивается как согласие с изменениями и (или) дополнениями соглашения. </p>
      <p>Пользователь дает своё согласие с положениями настоящего Соглашения нажатием кнопки «Принять пользовательское Соглашение» или «Продолжить» или «Согласен», проставлением соответствующей отметки в поле при Регистрации, в том числе на любом этапе такой регистрации и (или) в любой момент пользования сайтом.</p>
      <p>Пользователь подтверждает, что не имеет права передавать свой логин и пароль, полученный при подтверждении регистрации или авторизации по СМС одноразовый пароль третьим лицам, а также не имеет права получать логин и (или) пароль от третьих лиц.</p>
      <p>Пользователь обязан незамедлительно уведомить Администрацию сайта о любом нарушении безопасности его учетной записи на kvisto.com.</p>
      <p>Пользователь самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с использованием сайта под его учетной записью пользователя, включая случаи добровольной передачи пользователем данных для доступа к учетной записи Пользователя третьим лицам на любых условиях (в том числе по договорам или соглашениям) без согласования подобного с kvisto.com и (или) в нарушение условий соглашения. При этом все действия в рамках или с использованием сайта под учетной записью пользователя считаются произведенными самим пользователем, за исключением случаев, когда пользователь уведомил kvisto.com о несанкционированном доступе к сайту с использованием учетной записи пользователя.</p>
      <p>После регистрации на сайте с использованием одного пользовательского устройства, подключенного к сети Интернет (например, мобильный телефон) Пользователь также может активировать учетную запись на других устройствах (например, компьютер, планшет). При этом учетная запись Пользователя не может быть активированной на нескольких пользовательских устройствах, имеющих доступ к сети Интернет, одновременно. </p>
      <p>Kvisto.com оставляет за собой право в любой момент потребовать от пользователя подтверждения данных, указанных при регистрации, и (или) запросить подтверждающие документы (в частности - документы, удостоверяющие личность) непредоставление которых, по усмотрению kvisto.com, может быть приравнено к предоставлению недостоверной информации и повлечь последствия, предусмотренные соглашением. В случае если данные пользователя, указанные в предоставленных им документах, не соответствуют данным, указанным при регистрации, или, когда данные, указанные при регистрации, не позволяют идентифицировать пользователя, а также в иных случаях нарушения условий данного пользовательского соглашения, kvisto.com вправе отказать пользователю в доступе к учетной записи и/или использовании сайта. Пользователь обязан предоставлять всю запрашиваемую kvisto.com информацию и документы в срок, указанный последним.</p>
      <p>Kvisto.com вправе заблокировать или удалить учетную запись пользователя, а также запретить доступ с использованием какой-либо учетной записи к определенным сервисам и возможностям cайта и удалить любой контент и информацию без объяснения причин, в том числе в случае нарушения пользователем условий соглашения и/или законодательства РФ. Факт нарушения пользователем условий соглашения и/или законодательства РФ устанавливается kvisto.com в одностороннем порядке, самостоятельно. Пользователь соглашается с таким порядком и обязуется исполнять решение kvisto.com.</p>
      <p>После регистрации на сайте Пользователю предоставляется возможность размещать на сайте материалы, статьи, иной контент в пределах ограничений, установленных настоящим соглашением.</p>
      <p>Пользователь соглашается и дает разрешение на получение электронных писем/уведомлений от kvisto.com. Эти электронные письма/уведомления, направляемые по известным kvisto.com контактам пользователя, могут представлять собой сообщения об операциях или уведомления о контактах, связанные с сайтом, такие как административные уведомления и служебные объявления об изменениях, и(или) письма, содержащие коммерческие предложения, рекламные материалы, специальные предложения и(или) информацию о действиях других пользователей в отношении учетной записи пользователя.</p>
      <h2>3. Подписка и ценообразование.</h2>
      <p>Пользователь может пользоваться определенным набором возможностей Веб-сайта бесплатно, за исключением тех, что доступны за отдельную плату. Для получения дополнительных возможностей, пользователю необходимо стать платным подписчиком.</p>
      <p>Платный доступ означает предоставление пользователю дополнительных возможностей по использованию сайта на условиях осуществления предварительного платежа. В разделе сайта «VIP» пользователь может ознакомиться с действующими подписками и ценами. Правила для пользователей в разделе сайта «VIP» являются частью настоящего Соглашения. </p>
      <p>Текущие тарифы на подписки:</p>
      <p>
        1 день - 29,00р. пробный период ежемесячной подписки*<br/>
        12 месяцев - 149.00 RUB в месяц (итого 1788 RUB) - экономия 75%<br/>
        1 месяц - 248.00 RUB в неделю (итого 992 RUB) - экономия 50%<br/>
        1 неделя - 449.00 RUB в неделю (итого 449 RUB)<br/>
      </p>
      <p>
        * однодневный пробный период, первый день - 29 рублей, затем подписка продлевается на период 1 месяц, если не было отмены в течение одного дня (24 часов).
      </p>
      <p>
        Для оплаты платной подписки kvisto.com выставляет пользователю счета через онлайн-счет. В зависимости от способа оплаты, платеж будет зачислен на счет учетной записи пользователя в течение некоторого времени. Пользователь соглашается оплачивать все счета, выставленные kvisto.com за любое использование платных подписок. Пользователь разрешает kvisto.com взимать платежи через выбранного пользователем поставщика платежных услуг (ваш «Способ оплаты»), например: PayPal, Яндекс.Деньги, WebMoney, Сбербанк Онл@йн, QIWI Wallet, Robokassa (Робокасса), DengiOnline (Деньги Online), RBKMoney, др.
      </p>
      <p>
        Платные подписки могут быть предоставлены только с момента их полной оплаты. Kvisto.com не осуществляет кредитование пользователей ни денежными средствами, ни виртуальной валютой, а также не предоставляет рассрочку. 
      </p>
      <p>Пользователь согласен совершать платежи с использованием выбранного Способа оплаты.</p>
      <p>Kvisto.com может исправить любые возникшие ошибки по счетам, даже если уже запрошена или получена оплата.</p>
      <p>
        Если пользователь запрашивает возврат средств или иным образом отзывает платеж, совершенный через Способ оплаты, kvisto.com может на собственное усмотрение немедленно приостановить работу учетной записи пользователя. Если в последующем при возникновении спора о платеже, kvisto.com успешно оспаривает возврат средств, и средства возвращаются kvisto.com, пользователь не имеет права на возмещение или на восстановление учетной записи или подписки.
      </p>
      <p>
        В случае когда пользователем впервые оплачена платная подписки за возможности сайта, по завершению первоначального периода подписки, а равно и по завершению каждого последующего периода подписки, подписка автоматически продлевается на аналогичный период, по цене, на которую пользователь согласился в момент оформления подписки.
      </p>
      <p>
        Условия пробной подписки (в том числе, в рамках акций) и ее оплаты, являющиеся неотъемлемыми частями пользовательского соглашения, указаны на странице «Условия оплаты и подписки».
      </p>
      <p>
        В целях автоматического обновления, новый период подписки начнется со дня получения платежа. Пользователь должен учитывать, что когда он подписывается на любой пакет подписки, включая пробный период, он принимает на себя обязательство по оплате автоматического обновления.
      </p>
      <p>
        Оформляя подписку, пользователь дает kvisto.com разрешение взимать плату через выбранный пользователем Способ оплаты в настоящий момент и далее в аналогичном порядке в начале каждого последующего периода подписки.
      </p>
      <p>
        Если пользователь не хочет, чтобы подписка обновлялась автоматически, или если хочет изменить или приостановить свою подписку, пользователю необходимо войте в свою учетную запись, и воспользоваться функцией Отписаться / Подписаться (или подобной) на странице настроек платежей своей учетной записи.
      </p>
      <p>
        Если пользователь отменит подписку, он сможет пользоваться своей подпиской до конца текущего срока оплаченной подписки. Подписка не обновится по окончанию периода подписки. Однако, пользователь не будет иметь права на пропорциональное возмещение какой-либо части стоимости подписки, оплаченной за текущий период. 
      </p>
      <p>
        Если пользователь оплатил подписку, используя опцию «Массового платежа», пользователь обязан совершать все платежи, даже если отказался от своей подписки до истечения срока действия текущей подписки.
      </p>
      <p>
        Пользователь согласен на взимание комиссии и/или налогов, которыми могут облагаться платежи по подписке.
      </p>
      <p>
        В момент обновления подписки пользователя, если kvisto.com не получит оплату через поставщика Способа оплаты, пользователь соглашается по требованию Администрации сайта оплатить все суммы, подлежащие выплате по использованию сайта и/или признает, что kvisto.com может отменить или приостановить подписку Kvisto.com вправе продолжать пытаться получить оплату через поставщика Способа оплаты, пока не получит этот платеж (только после получения платежа, учетная запись пользователя будет активирована). 
      </p>
      <p>
        В избежание случаев мошенничества при осуществлении оплаты подписок с использованием банковских карт, платежи, оплаченные банковской картой через выбранный пользователем Способ оплаты, могут быть проверены Администрацией сайта. В случае получения запроса от Администрации, пользователь обязан предоставить копию двух первых страниц своего паспорта, а также копию банковской карты с обеих сторон, с закрытым номером карты кроме последних четырех цифр, а также иные документы по запросу Администрации. В случае непредставления указанных сведений в течение суток, Администрация оставляет за собой право в одностороннем порядке приостановить доступ пользователя к учетной записи до выяснения обстоятельств.
      </p>
      <p>
        Так как обновление подписок осуществляется автоматически, пользователь должен своевременно предоставлять kvisto.com актуальную, действующую, полную и точную информацию о своем счёте, с учетом выбранного Способа платежа. Пользователь должен незамедлительно обновлять всю информацию касательно счета (например, изменение адреса плательщика, номера карты или даты окончания срока действия). Пользователь должен незамедлительно сообщать, если Способ оплаты аннулирован (включая, утрату банковской карты). Внесение изменений к такой информации может осуществляться в «Настройках» учетной записи на Веб-сайте. В случае непредоставления вышеуказанной информации, пользователь соглашается, что kvisto.com вправе самостоятельно, доступными и законными способами получить обновленные или измененные сведения о счете пользователя в части даты окончания срока действия и номера банковских карт пользователя. 
      </p>
      <p>
        Выбор и заказ платных подписок, а равно отказ от них осуществляется пользователем самостоятельно по своему усмотрению. Сайт никак не влияет, не участвует в таком выборе. 
      </p>
      <p>
        Пользователь соглашается и принимает, что сайт и предоставляемые сайтом платные подписки не являются азартной игрой, конкурсом и т.д., а оплата подписок являются волей и желанием пользователя и не является необходимым условием для использования возможностей сайта.
      </p>
      <h2>4. Обязанности пользователя.</h2>
      <p>
        Пользователь обязуется не использовать сайт для:
      </p>
      <ul>
        <li>осуществления коммерческих целей и предпринимательской деятельности;</li>
        <li>рассылки массовых сообщений (за исключением массовой рассылки предлагаемой сайтом), электронных писем, а также использовать в этих целях информацию, полученную на сайте;</li>
        <li>совершения сделок – коммерческих и некоммерческих – с другими пользователями, без предварительного согласия сайта;</li>
        <li>предложения другим пользователям совершать сделки, передавать товары, осуществлять работы, оказывать услуги;</li>
        <li>рекламы товаров и услуг, совершения оферты, предложения Пользователям совершить какие-либо действия в пользу третьих лиц.</li>
      </ul>
      <p>
        Использование пользователями сайта, включая размещение контента на сайте, должно отвечать всем требованиям действующего законодательства РФ, условиям настоящего Соглашения.
      </p>
      <p>
        Вся информация, которую пользователь вносит в свою учетную запись или иным образом размещает за счет возможностей сайта, должна быть точной, актуальной, полной и соответствовать действительности.
      </p>
      <p>
        При использовании kvisto.com пользователь не вправе:
      </p>
      <ul>
        <li>загружать, посылать, передавать или любым другим способом размещать и/или распространять контент (в т.ч. информацию), который является незаконным, вредоносным, клеветническим, оскорбляет нравственность, демонстрирует (или является пропагандой) насилия и жестокости, нарушает права интеллектуальной собственности, пропагандирует ненависть и/или дискриминацию людей по расовому, этническому, половому, религиозному, социальному признакам, содержит оскорбления в адрес каких-либо лиц или организаций, содержит элементы (или является пропагандой) порнографии, детской эротики, представляет собой рекламу (или является пропагандой) услуг сексуального характера (в том числе под видом иных услуг), разъясняет порядок изготовления, применения или иного использования наркотических веществ или их аналогов, взрывчатых веществ или иного оружия;</li>
        <li>нарушать права третьих лиц, в том числе несовершеннолетних лиц и/или причинять им вред в любой форме;</li>
        <li>выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то прав, в том числе за сотрудников или представителей Администрации сайта, за модераторов, за владельца сайта, а также применять любые другие формы и способы незаконного представительства других лиц в сети (в том числе использовать изображения физических лиц, без их согласия), а также вводить пользователей или сайт в заблуждение относительно личности пользователя, свойств и характеристик каких-либо субъектов или объектов;</li>
        <li>загружать, посылать, передавать или любым другим способом размещать и/или распространять контент, при отсутствии прав на такие действия согласно законодательству РФ или каким-либо договорным отношениям;</li>
        <li>загружать, посылать, передавать или любым другим способом размещать и/или распространять не разрешенную специальным образом рекламную информацию, спам (в том числе и поисковый), списки чужих адресов электронной почты, схемы «пирамид», многоуровневого (сетевого) маркетинга (MLM), системы интернет-заработка и e-mail-бизнесов, «письма счастья», а также использовать сервисы и возможности сайта для участия в этих мероприятиях, или использовать сайт, исключительно для перенаправления пользователей на страницы других доменов;</li>
        <li>загружать, посылать, передавать или любым другим способом размещать и/или распространять какие-либо материалы, содержащие вирусы или другие компьютерные коды, файлы или программы, предназначенные для нарушения, уничтожения либо ограничения функциональности любого компьютерного или телекоммуникационного оборудования или программ, для осуществления несанкционированного доступа, а также серийные номера к коммерческим программным продуктам и программы для их генерации, логины, пароли и прочие средства для получения несанкционированного доступа к платным ресурсам в Интернете, а также размещения ссылок на вышеуказанную информацию;</li>
        <li>несанкционированно собирать и хранить персональные данные других лиц;</li>
        <li>нарушать нормальную работу сайта;</li>
        <li>размещать ссылки на ресурсы сети, содержание которых противоречит действующему законодательству РФ;</li>
        <li>содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых соглашением;</li>
        <li>побуждать к совершению противоправных действий, а также осуществлять содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории РФ;</li>
        <li>ущемлять прав меньшинств;</li>
        <li>«выслеживать» или иным образом беспокоить других пользователей сайта против их воли на то;</li>
        <li>использовать роботов, вирусы, приложения для поиска/извлечения сайтов, или другие ручные или автоматические устройства или процессы для извлечения, индексирования, «шпионажа», или иным образом воспроизводить или обходить структуру навигации и отображения сайта или его содержимого;</li>
        <li>вмешиваться или нарушать работу сайта или серверов и сетей, связанных с сайтом;</li>
        <li>размещать, отправлять или иным образом передавать любые материалы, которые содержат вирусы или другой компьютерный код, файлы или программы, разработанные для вмешательства, разрушения или ограничения функциональности любого компьютерного программного или аппаратного обеспечения или телекоммуникационного оборудования;</li>
        <li>подделывать заголовки или иным образом манипулировать обозначениями с целью маскировки настоящего смысла любой информации, передаваемой через Сервис.</li>
        <li>«подделывать» или «повторять» любую часть сайта без предварительного письменного разрешения kvisto.com. Пользователь не может использовать метатеги или код или другие устройства, содержащие любые ссылки на kvisto.com с целью перенаправить любое лицо на другой сайт с любой целью противоречащей условиям настоящего соглашения;</li>
        <li>изменять, адаптировать, сублицензировать, переводить, продавать, перестраивать, расшифровывать, декомпилировать или иным образом разбирать любую часть сайта или любого другого программного обеспечения, используемого для него, или провоцировать других пользователей на подобные действия.</li>
        <li>другим образом нарушать нормы законодательства, в том числе нормы международного права.</li>
      </ul>
      <p>
        Если иное не предусмотрено настоящим соглашением и/или не следует из существа возможностей сайта, пользователь вправе при использовании сайта размещать созданные им авторские материалы (произведения), права на которые не были предоставлены, отчуждены третьим лицам (за исключением сайта).
      </p>
      <p>
        Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием сайта, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение законодательства РФ при использовании сайта.
      </p>
      <p>
        Пользователь обязан самостоятельно при условии предварительного письменного согласования с kvisto.com предпринять все необходимые меры для урегулирования возникшего спора с третьими лицами своими силами и за свой счет. Стороны определили следующие меры, например: оперативное взаимодействие с представителями третьих лиц, правообладателей, направление в адрес третьих лиц уведомлений, содержащих обоснованные письменные объяснения, в том числе об отсутствии вины пользователя, сайта, при необходимости организация переговоров, встреч, участие в переговорах и встречах с третьими лицами, правообладателями (или) их представителями. Достаточность и эффективность предпринятых пользователем для урегулирования спора действий определяется kvisto.com самостоятельно, в одностороннем внесудебном порядке.
      </p>
      <p>
        Пользователь соглашается освободить kvisto.com от любых убытков, обязательств, претензий или требований, включая обоснованные затраты на судебные разбирательства, понесенные любым третьим лицом, в результате использования сайта пользователем в нарушение настоящего Соглашения и/или в результате несоблюдения настоящего Соглашения и/или несоблюдения любого заявления или гарантии пользователя.
      </p>
      <p>
        Достаточность предпринятых пользователем мер для урегулирования споров, связанных с нарушением каких-либо прав третьих лиц определяется сайтом.
      </p>
      <p>
        Пользователь обязан возместить все убытки, которые могут возникнуть у сайта из-за действий/бездействий пользователя.
      </p>
      <p>
        Любые факты нарушения прав третьих лиц могут устанавливается сайтом в одностороннем порядке, самостоятельно. Пользователь соглашается с таким порядком и обязуется исполнять решение сайта.
      </p>
      <p>
        Пользователь подтверждает свое согласие на использование его изображения в любых целях, предусмотренных настоящим Соглашением, на безвозмездной основе, в случае если такое изображение будет размещено пользователем на сайте.
      </p>
      <p>
        В отношении всех размещаемых пользователем на сайте материалов пользователь дает сайту безотзывное, неисключительное, свободное от обязательств право на их использование, в том числе путем воспроизведения, адаптации, публикации, перевода, публичного показа и распространения посредством любых видов связи и вещания.
      </p>
      <p>
        Пользователь соглашается, что, за исключением случаев, когда это ясно указано в настоящем Соглашении, по отношению к настоящему Соглашению не будет сторонних бенефициаров пользователя.
      </p>
      <h2>5. Взаимодействие между Пользователями.</h2>
      <p>
        Пользователь несет личную ответственность за свое взаимодействие с другими пользователями.
      </p>
      <p>
        Пользуясь сайтом, пользователь должен проявлять разумную осторожность при оценке информации и данных, предоставленных другими пользователями, на предмет их точности и достоверности.
      </p>
      <p>
        Пользователь принимает на себя все риски, связанные с принятием им информации, содержащейся в сообщениях от других пользователей, а равно иной информации размещенной пользователями на сайте, в качестве достоверной, точной и надежной.
      </p>
      <p>
        Пользователь должен понимать, что информация и данные, содержащиеся на сайте, размещены в том числе другими пользователями и могут представлять собой недостоверную, ненадежную и(или) незаконную информацию. Сайт не несет никакой ответственности за действия пользователей.
      </p>
      <p>
        Обмен сообщениями, содержащими информацию и данные, запрещенные настоящим соглашением, запрещен.
      </p>
      <p>
        Kvisto.com оставляет за собой право, но не обязуется, контролировать, участвовать в разрешении споров между пользователями.
      </p>
      <p>
        Пользователь понимает и соглашается, что веб-сайт не проверяет пользователей на наличие у них судимостей и(или) факта уголовного преследования, а равно других социально отрицательных характеристик.
      </p>
      <p>
        Kvisto.com не дает никаких гарантий касательно поведения пользователей и (или) их совместимости с действительными или будущими пользователями сайта.
      </p>
      <p>
        Возможности сайта предоставляются на условиях «как есть», и kvisto.com прямо отказывается от любых гарантий или соответствия определенным целями ожиданиям пользователей. Kvisto.com не может гарантировать и не обещает каких-либо результатов от использования сайта. Пользователь с таким порядком использования сайта согласен. 
      </p>
      <p>
        Kvisto.com не несет ответственности за какой-либо ущерб прямой, косвенный, общего или особого характера, компенсационный, и/или случайный, полученный пользователем или другими лицами в результате использования веб-сайта, включая, но без исключения, телесные повреждения, стресс и/или иной ущерб, полученный пользователем в результате общения или личных встреч с другими пользователями сайта. Kvisto.com не несет ответственности за поведение пользователей, как в онлайн, так и офлайн-режиме.
      </p>
      <p>
        Пользователь обязан принять все возможные личные меры предосторожности при общении с другими пользователями сайта, особенно в тех случаях, когда пользователь решает встретиться с ними лично.
      </p>
      <p>
        Пользователь обязуется не предоставлять касающуюся его информацию о частной жизни, в том числе, информацию финансового характера иным пользователям сайта (например, информацию о кредитной или банковской карте). В случае если предоставлением такой информации пользователю причинен какой-либо ущерб, kvisto.com ответственности не несет.
      </p>
      <p>
        Пользователь соглашается с тем, что сеть Интернет никогда не была полностью приватной и безопасной. Пользователь понимает, что любое сообщение или информация, которые он публикует на Веб-сайте, могут быть прочитаны или использованы другими пользователя, даже если имеется специальное предупреждение, что информация скрыта.
      </p>
      <p>
        Kvisto.com обращает внимание на то, что не является и не может ни при каких обстоятельствах являться гарантом точности и достоверности информации, содержащейся в сообщениях между пользователями и информации пользователей на сайте. 
      </p>
      <p>
        Пользователь имеет право подать жалобу на информацию, содержащуюся в сообщениях между пользователями (и/или) размещенную в аккаунтах пользователей, в адрес Администрации. Сайт оставляет за собой право оставить жалобу пользователя без рассмотрения без объяснения причин.
      </p>
      <p>
        Контент размещаемый пользователями не проверяется сайтом на соответствие тем или иным требованиям (достоверности, полноты, законности и т.п.), за исключением прямо предусмотренных в соглашении случаев. Ответственность за размещаемый пользователем контент несет исключительно пользователь.
      </p>
      <p>
        Надежность контента, рекомендаций и пр. мнения, утверждения, предложения или другая информация, доступ к которым предоставляется посредством сайта, но не непосредственно kvisto.com, является собственностью соответствующих авторов, и не обязательно является надежной. За такой контент ответственность несут исключительно авторы, обладатели информации. Мнения, высказанные в сообщениях между пользователями, а равно размещенные в учетных записях пользователей являются мнениями их авторов. Сайт не несет ответственности за комментарии, точки зрения, замечания и любые другие высказывания пользователей.
      </p>
      <p>
        Ни при каких обстоятельствах kvisto.com не несет ответственности за любые убытки, понесенные в результате того, что пользователь поверил информации или другому контенту, размещенному на сайте или переданному какими-либо пользователем и(или) пользователями.
      </p>
      <h2>6. Удаление учетной записи.</h2>
      <p>
        Пользователь может прекратить свое пользование сайтом (пользование учетной записью) и/или отменить подписку в любое время, по любой причине, связавшись со службой поддержки сайта.
      </p>
      <p>
        Для того чтобы удалить ученую запись пользователю необходимо остановить подписку, если она была оформлена. Учетную запись с действующей подпиской удалить нельзя. В случае попытки удаления учетной записи с действующей (не отмененной) подпиской, удаление аккаунта не произойдет и пользователь может снова авторизоваться используя свои логин/пароль. 
      </p>
      <p>
        Для удаления учетной записи пользователю необходимо отправить запрос в службу поддержки по адресу help@corp.kvisto.com с обязательным указанием адреса электронной почты или номера телефона с которого была зарегистрирована учетная запись.
      </p>
      <p>
        Примеры писем:
      </p>
      <ul>
        <li>Если аккаунт был зарегистрирован через номер телефона с получением СМС:
"Прошу отменить мою подписку (учетную запись) на kvisto.com. Номер телефона, с которого был зарегистрирован мой аккаунт 89511234567", Где 89511234567 - это номер телефона пользователя.</li>
        <li>Если аккаунт был зарегистрирован с помощью e-mail:
"Прошу отменить мою подписку (учетную запись) на kvisto.com. Адрес электронной почты, с которого был зарегистрирован мой аккаунт name@domain.ru", Где name@domain.ru - это адрес пользователя.</li>
        <li>По id пользователя на сайте: "Прошу отменить мою подписку (учетную запись) на kvisto.com . Мой id на сайте id815453125". Для того чтобы узнать свой id, пользователю нужно перейти на свою страничку Kvisto.сom, после скопировать URL из адресной строки браузера, он должен иметь вид: https://kvisto.com/id815453125. Где id815453125 - это id пользователя на сайте. </li>
      </ul>
      <p>
        После запроса пользователю должно прийти уведомление об успешной остановке подписки (учетной записи).
      </p>
      <p>
        При заявлении пользователем отказа от пользования сайтом (пользования учетной записью) и/или отмены подписки, kvisto.com может потребоваться разумные сроки на обработку соответствующего запроса пользователя.
      </p>
      <p>
        Если пользователь отказывается от пользования сайтом (пользования учетной записью) и/или отменяет подписку, Служба поддержки сайта может попросить пользователя указать причины отказа.
      </p>
      <p>
        Сайт также оставляет за собой право прекратить пользование сайтом (пользование учетной записью) и/или отменить подписку. При этом сайт не обязан уведомлять пользователя перед закрытием учетной записи и/или отмены подписки, если у kvisto.com есть основания полагать, что пользователь нарушил условия настоящего Соглашения. Сайт не обязан раскрывать причину удаления аккаунта пользователя. 
      </p>
      <p>
        После прекращения пользования сайтом (пользования учетной записью) и/или отмены подписки, все условия, которые по своему характеру могут оставаться актуальными после прекращения действия настоящего Соглашения, будут действовать и после такого прекращения.
      </p>
      <h2>7. Права kvisto.com.</h2>
      <p>
        Kvisto.com вправе:
      </p>
      <ul>
        <li>в случаях, когда обратное прямо не предусмотрено настоящим соглашением, определять в одностороннем, внесудебном порядке условие соглашения (правило поведение), регулирующее в возникшей ситуации отношения сторон соглашения. Созданное в таком порядке условие соглашения будет являться обязательным для исполнения сторонами соглашения как, если бы оно было изначально предусмотрено самим соглашением (или) стороны в последующем заключили об этом необходимое дополнительное соглашение;</li>
        <li>в случаях, когда в соглашении и/или на сайте прямо не предусмотрены объективные критерии оценки действий, включаемых в предоставляемые пользователю возможности сайта, определять в одностороннем, внесудебном порядке соответствие таких действий условиям соглашения, приемлемость, разумность, достаточность и прочее.</li>
      </ul>
      <p>
        Kvisto.com предоставляет помощь и руководство пользователям через своих представителей – Администрация сайта, служба поддержки. Kvisto.com время от времени может создавать учетные записи, которые поддерживаются представителями Администрации сайта ("Профили сотрудников"). Целью таких учетных записей является предоставление гарантий о том, что сайт работает надлежащим образом, посредством тестирования сервисов сайта, функций и функциональных возможностей, а также исследование возможностей сайта.
      </p>
      <p>
        Пользователь вправе обращаться к Администрации сайта. Общаясь с учетными записями Администрации сайта, пользователь обязан не вести себя оскорбительно, непристойно, угрожающе, обидно, с сексуальным, расовым подтекстом, или иным образом которое может быть расценено как нарушение условий соглашения. В случае если данная обязанность не будет соблюдена пользователем, kvisto.com вправе закрыть доступ пользователя к его учетной записи без возврата неиспользованной платы за подписку.
      </p>
      <p>
        В целях защиты целостности сайта, kvisto.com оставляет за собой право в любое время по своему усмотрению блокировать IP-адреса определенных пользователей.
      </p>
      <p>
        Kvisto.com оставляет за собой право в любой момент изменить или отключить, временно или навсегда, сервис (или любую его часть), сайт без уведомления.  Пользователь согласен, что kvisto.com не несет ответственности перед ним или любыми третьими лицами за любые изменения, временные остановки или отключение сервиса, сайта. 
      </p>
      <p>
        Kvisto.com не несет ответственности за любой неверный или неточный контент, размещенный на cайте или связанный с cайтом, пользователями сайта или любым оборудованием или программным обеспечением, связанным с сайтом или используемым в нем.
      </p>
      <p>
        Kvisto.com не несет ответственность за любые ошибки, упущения, прерывания, удаление, повреждение или задержку работы или операции, повреждения линий связи, кражу или разрушение, или незаконный доступ, или изменение линий связи пользователя.
      </p>
      <p>
        Kvisto.com не несет ответственности за любые проблемы или технические неполадки телефонных сетей или линий, компьютерных систем, серверов или провайдеров, компьютерного оборудования, программного обеспечения, неполадки почты или проигрывателей в учетной записи или технические проблемы или перегрузки сети интернет, или на любом сайте или их комбинации, включая травмы или повреждения пользователей или любых третьих лиц, связанные с компьютером, или в результате участия и скачивания материалов, связанных с сетью и/или сайтом. 
      </p>
      <p>
        Ни при каких условиях kvisto.com не несет ответственности за любой ущерб или повреждение, включая личную травму или смерть, в результате чьего-либо использования сайта, любого контента, размещенного на сайте или переданного пользователями или любых взаимодействий между пользователями сайта, как в онлайн, так и в офлайн-режиме.
      </p>
      <p>
        Сайт вправе предлагать пользователю возможность массовой рассылки, с помощью которой пользователь может отправлять до 50 сообщений различным пользователям, как онлайн, так и офлайн. Сайт автоматически распределяет отправленные сообщения, основываясь на внутренних алгоритмах, которые учитывают месторасположение, возраст, поведение пользователя и прочие параметры.
      </p>
      <p>
        Сайт может просить пользователя оценить фотографии других пользователей или просить других пользователей оценить фотографии пользователя, размещенные в учетной записи пользователя и/или на сайте.
      </p>
      <p>
        Сайт может отправлять пользователям уведомления о том, что другие пользователи просматривали аккаунт пользователя (по почте или через сайт), если пользователь оценил фотографии или просмотрел учетную запись пользователя.
      </p>
      <p>
        Сайт вправе использовать алгоритм автоматического определения внимания или активности пользователей. Алгоритм самостоятельно определяет пользователей с незначительной входящей или исходящей активностью, и после этого отправляет приветственные сообщения наиболее подходящим пользователям (другим пользователям сайта, определенным как наиболее подходящие контакты для пользователя согласно алгоритму). 
      </p>
      <p>
        С учетом возможностей сайта и специфики работы алгоритма, отправка таких сообщений осуществляется через смоделированного компьютером пользователя, выполняющего автоматически и/или по заданному алгоритму действия. Аккаунт смоделированного компьютером пользователя может не отличаться от учетной записи пользователя физического лица.  При этом предварительно подготовленные ответы и вопросы для отправки сообщений пользователям – автоматические сообщения, сгенерированные алгоритмом, отличаются от стандартных сообщений пользователей стилем и их легко распознать. Переписка пользователя и смоделированного компьютером пользователя может не ограничиваться отправкой и получением только приветственных сообщений. Переписка пользователя и смоделированного компьютером пользователя может охватываться возможностями платной подписки. Только за пользователем остается право выбора общения с смоделированным компьютером пользователем или нет. Пользователь согласен с подобным порядком пользования сайтом. 
      </p>
      <p>
        Сервис может автоматически переводить данные учетной записи пользователя на другие языки.
      </p>
      <p>
        Kvisto.com не несет никакой ответственности по сделкам между пользователями, если подобные были заключены.
      </p>
      <p>
        Kvisto.com оставляет за собой право удалить учётную запись пользователя, в случае если он не использовал свой доступ к kvisto.com в течение достаточно долгого времени. Определение достаточности неактивности учётной записи в каждом конкретном случае остаётся на усмотрение сайта.
      </p>
      <h2>8. Интеллектуальная собственность.</h2>
      <p>
        Все объекты, доступные при помощи сервисов и возможностей сайта, в том числе элементы дизайна, текст, графические изображения, иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие объекты (далее – содержание сервисов), а также любой контент, размещенный на сервисах сайта, являются объектами исключительных прав kvisto.com и других правообладателей.
      </p>
      <p>
        Использование сайта, каких-либо иных элементов сайта или их сервисов возможно только в рамках функционала, предлагаемого тем или иным сервисом. Никакие элементы содержания сервисов сайта, а также любой контент, размещенный на сайте не могут быть использованы иным образом без предварительного разрешения kvisto.com, если последний является правообладателем в отношении соответствующего результата интеллектуальной деятельности. Под использованием подразумеваются, в том числе: воспроизведение, копирование, переработка, распространение на любой основе и т.д. Исключение составляют случаи, прямо предусмотренные законодательством РФ или условиями использования того или иного сервиса kvisto.com. Любой пользователь вправе в любое время обращаться к kvisto.com по данному и иным поводам.
      </p>
      <p>
        Возможности kvisto.com дают пользователям право размещать, хранить, передавать объекты (текст, графические изображения, иллюстрации, видео, музыка, звуки и другие объекты), которые могут являться результатами интеллектуальной деятельности. С момента размещения указанных объектов на сайте пользователь предоставляет kvisto.com на срок действия исключительного права право использования объектов на условиях простой (неисключительной) лицензии,в том числе способами, указанными в пункте 2 ст.1270 ГК РФ Сайт может использовать произведение, в том числе путем хранения, воспроизведения, публичного исполнения, публичного показа, переработки, перевода, распространения. Право использования объектов предоставляется kvisto.com безвозмездно.   
      </p>
      <p>
        Пользователь согласен на любое использование kvisto.com произведения полностью и (или) в части (использование отдельного элемента). Пользователь также согласен на использование произведения kvisto.com в составе других объектов авторского и (или) смежного права. Пользователь настоящим дает свое согласие kvisto.com, его правопреемникам (в т.ч. лицам, которым будет передано или уступлено право использования  произведения), уполномоченным лицам (агентам, посредникам и пр.) на внесение в его произведение изменений, сокращений и дополнений, снабжение произведения при его использовании иллюстрациями, предисловием, послесловием, комментариями или какими бы то ни было пояснениями (например, для внесения ясности или в целях оптимизации). Пользователь разрешает доступ к произведению неограниченному кругу лиц. Пользователь подтверждает свое согласие на распространение kvisto.com произведения третьим лицам.  
      </p>
      <p>
        Сайт предоставляет пользователю непередаваемую простую (неисключительную) лицензию на использование возможностей сайта и отдельных его элементов только по прямому функциональному назначению, исходя из назначения сайта и при условии, что ни пользователь, ни любые иные лица при содействии пользователя не будут воспроизводить, копировать или перерабатывать (модифицировать) их, а также использовать какие-либо их части в личных или коммерческих целях. Простая (неисключительная) лицензия на использование возможностей сайта предоставляется пользователю безвозмездно, кроме случаев прямого указания о платном характере использования отдельных возможностей сайта. Необходимым условием использования таких сервисов является оплата вознаграждения kvisto.com в порядке и размере, указанным в соглашении и/или на сайте.
      </p>
      <p>
        Сайт может содержать ссылки на другие сайты в сети Интернет (сайты третьих лиц). Указанные третьи лица и их контент не проверяются kvisto.com на соответствие тем или иным требованиям (достоверности, полноты, законности и т.п.), за исключением прямо предусмотренных случаев. Сайт не несет ответственность за любую информацию, материалы, размещенные на сайтах третьих лиц, к которым пользователь получает доступ с использованием kvisto.com, в том числе, за любые мнения или утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а также за доступность таких сайтов или контента и последствия их использования пользователем.  
      </p>
      <p>
        Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого характера, размещенная на сайте, в приложении, не является одобрением или рекомендацией данных продуктов (услуг, деятельности) со стороны сайта, за исключением случаев, когда на это прямо и однозначно указывается самим сайтом.
      </p>
      <p>
        Условия настоящего раздела о лицензиях по территории действия распространяются на весь мир. Лицензии на использование действует, в том числе в течение всего срока использования сайта. 
      </p>
      <h2>9. Заключительные положения.</h2>
      <p>
        Любые рекомендации, размещенные на сайте, предназначены исключительно в информационных и развлекательных целях, и не могут заменять или замещать собой любые финансовые, медицинские, юридические или прочие рекомендации. Kvisto.com не делает никаких заявлений или не предоставляет гарантий и прямо отказывается от любой ответственности, связанной с восприятием, предпринятыми действиями или влиянием на любое лицо информации, размещенной или предоставленной на cайте.
      </p>
      <p>
        Пользователь признает и соглашается, что ничто в пользовательском соглашении не может пониматься как установление между пользователем и kvisto.com каких-то иных отношений, прямо не предусмотренных настоящим пользовательским соглашением.
      </p>
      <p>
        Бездействие со стороны kvisto.com в случае нарушения пользователем положений пользовательского соглашения не лишает права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает отказ kvisto.com от своих прав в случае совершения в последующем подобных либо сходных нарушений. 
      </p>
      <p>
        Kvisto.com не несёт никакой ответственности за данные пользователя, которые он предоставляет сторонним ресурсам и/или иным третьим лицам в случае перехода на них с сайта.
      </p>
      <p>
        Сайт не отвечает за возможную потерю и/или порчу данных, которая может произойти из-за нарушения пользователем положений настоящего соглашения, а также неправильного доступа и/или использования сайта.
      </p>
      <p>
        Пользователь самостоятельно определяет условия и порядок использования созданного им аккаунта (учетной записи), которые, однако ни при каких условиях не могут противоречить настоящему Соглашению.
      </p>
      <p>
        Сайт является информационным посредником в отношениях между пользователями.
      </p>
      <p>
        Сайт не несет ответственности в случае невозможности выполнять обязанности по соглашению в силу обстоятельств непреодолимой силы и находящихся вне разумного контроля сторон, включая, в том числе, неполадке в работе Интернет, пожары, боевые действия, наводнения и иные стихийные бедствия.
      </p>
      <p>
        Все споры, разногласия или требования сторон, возникающие из настоящего соглашения или в связи с ним, в том числе касающиеся его исполнения, нарушения, прекращения или недействительности, разрешаются путем переговоров между сторонами. 
      </p>
      <p>
        В случае невозможности урегулирования разногласий в досудебном порядке они подлежат рассмотрению в суде по месту нахождения Администрации сайта, если иное не предусмотрено действующим законодательством РФ. Досудебный порядок разрешения споров рассматривается стороными как обязательный претензионный и его несоблюдение препятствует непосредственному обращению в соответствующий суд, срок ответа на претензию – 10 (десять) рабочих дней со дня получения.
      </p>
      <p>
        Адрес электронной почты для приема юридически значимых сообщений: help@corp.kvisto.com. Контакты для связи с Администрацией: help@corp.kvisto.com
      </p>
      <p>
        Стороны настоящего соглашения признают в качестве письменных доказательств электронную переписку между собой посредством электронной почты, смс-сообщений и/или с использованием сервисов и возможностей сайта.
      </p>
      <p>
        Настоящее пользовательское соглашение регулируется законодательством Российской Федерации.
      </p>
      <p>
        Признание судом какого-либо положения настоящего соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности или неисполнимости иных положений пользовательского соглашения.
      </p>
      <p>
        Любые из сторон данного соглашения вправе в любое время оформить собственные отношения путем подписания на бумажном носителе необходимых документов, в том числе настоящее соглашение.
      </p>
      <p>
        При проведении контролирующими лицами проверочных мероприятий, прямо или косвенно связанных с использованием пользователем сайта, пользователь:
      </p>
      <ul>
        <li>при непосредственном обращении контролирующего лица незамедлительно узнает у последнего его фамилию, имя, отчество, должность, место работы, основание для обращения с запросом/требованием, сведения и документы, которые необходимо представить, сроки, порядок представления запрашиваемых сведений и документов. Любые пояснения контролирующего лица полно и развернуто фиксируются пользователем на бумаге.</li>
        <li>после получения от контролирующего лица необходимых пояснений и разъяснений пользователь незамедлительно, в возможно короткий срок уведомляет о таком обращении Администрацию сайта посредством телефонной связи, электронной почты с полным и развернутым изложением пояснений контролирующего лица. До получения от Администрации сайта прямых указаний относительно вариантов поведения, объема сведений и документов, необходимых для представления контролирующему лицу, самостоятельно пользователь не предпринимает никаких действий.</li>
      </ul>
      <p>
        В случае поступления в адрес сайта запроса контролирующих лиц пользователь обеспечивает незамедлительное представление любых необходимых Администрации сайта для представления контролирующим лицам документов. Документы представляются незамедлительно в возможно короткий срок, но в любом случае не позднее 3 (Трех) дней с момента получения пользователем запроса Администрации сайта, направленного любым из способов, определенных настоящим соглашением. 
      </p>
      <p>
        При наступлении указанных в предыдущих положениях. настоящего раздела обстоятельств пользователь должен предпринять все необходимые действия, в том числе связанные с действиями (бездействием) третьих лиц, подписанием документов, удовлетворяющих по форме и содержанию Администрацию сайта, контролирующих лиц, обращение к нотариусу и (или) иным компетентным органам государственной власти или органам местного самоуправления. Пользователь обязуется предпринять все возможные меры, совершить все возможные действия, как по своей инициативе, так и по указанию другой стороны в целях исполнения обязательств и требований, предусмотренных в настоящем разделе соглашения.
      </p>
    </article>
    <Footer/>
  </GridOffset>
</template>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import GridOffset from '@/components/GridOffset.vue'

export default {
  name: 'Terms',
  components: {
    GridOffset, Header, Footer
  },
}
</script>