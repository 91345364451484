export default {
    registration: {
        "gender": {
            "male": "Парень",
            "female": "Девушка",
        },
        "looking_for": {
            "male": "Парня",
            "female": "Девушку",
            "male,female": "Обоих"
        },
    },
    properties: {
        'names': {
            'hair': 'Волосы',
            'eyes': 'Цвет глаз',
            'height': 'Рост',
            'weight': 'Вес',
            'sociability': 'Общение',
            'relationship': 'Отношения',
            'orientation': 'Ориентация',
            'live': 'Я живу',
            'smoking': 'Курение',
            'alcohol': 'Алкоголь',
            'car': 'Машина',
            'nature': 'Характер'
        },

        hair_values_male: [
            'Не выбрано', 'Скажу позже', 'Черные',
            'Каштановые', 'Рыжие', 'Светлые', 'Немного седины',
            'Седые', 'Сбриты', 'Окрашенные', 'Лысый'
        ],
        hair_values_female: [
            'Не выбрано', 'Скажу позже', 'Черные',
            'Каштановые', 'Рыжие', 'Светлые', 'Немного седины',
            'Седые', 'Сбриты', 'Окрашенные', 'Лысая'
        ],

        eyes_values_male: [
            'Не выбрано', 'Скажу позже', 'Карие',
            'Серые', 'Зелёные', 'Голубые', 'Светло-карие',
            'Другое'
        ],
        eyes_values_female: [
            'Не выбрано', 'Скажу позже', 'Карие',
            'Серые', 'Зелёные', 'Голубые', 'Светло-карие',
            'Другое'
        ],

        height_values_male: [
            'Не выбрано', 'Скажу позже'
        ],
        height_values_female: [
            'Не выбрано', 'Скажу позже'
        ],

        weight_values_male: [
            'Не выбрано', 'Скажу позже'
        ],
        weight_values_female: [
            'Не выбрано', 'Скажу позже'
        ],

        sociability_values_male: [
            'Не выбрано', 'Скажу позже', 'Очень общительный',
            'Открыт интересным людям', 'Пишите - посмотрим'
        ],
        sociability_values_female: [
            'Не выбрано', 'Скажу позже', 'Очень общительная',
            'Открыта интересным людям', 'Пишите - посмотрим'
        ],

        relationship_values_male: [
            'Не выбрано', 'Скажу позже', 'Свободен',
            'Есть девушка', 'Женат', 'Разведён', 'Вдовец',
            'Другое'
        ],
        relationship_values_female: [
            'Не выбрано', 'Скажу позже', 'Свободна',
            'Есть парень', 'Замужем', 'Разведена', 'Вдова'
        ],

        orientation_values_male: [
            'Не выбрано', 'Скажу позже', 'Гетеросексуальная',
            'Гомосексуальная', 'Бисексуальная'
        ],
        orientation_values_female: [
            'Не выбрано', 'Скажу позже', 'Гетеросексуальная',
            'Гомосексуальная', 'Бисексуальная'
        ],

        live_values_male: [
            'Не выбрано', 'Скажу позже', 'Один',
            'С друзьями', 'В общежитии', 'С супругой/девушкой'
        ],
        live_values_female: [
            'Не выбрано', 'Скажу позже', 'Одна',
            'С друзьями', 'В общежитии', 'С супругом/парнем'
        ],

        smoking_values_male: [
            'Не выбрано', 'Скажу позже', 'Не курю',
            'Не курю и не люблю курящих', 'Курю немного', 'Курю постоянно'
        ],
        smoking_values_female: [
            'Не выбрано', 'Скажу позже', 'Не курю',
            'Не курю и не люблю курящих', 'Курю немного', 'Курю постоянно'
        ],

        alcohol_values_male: [
            'Не выбрано', 'Скажу позже', 'Не пью',
            'Не пью и не люблю пьющих', 'Пью за компанию по праздникам', 'Частенько выпиваю'
        ],
        alcohol_values_female: [
            'Не выбрано', 'Скажу позже', 'Не пью',
            'Не пью и не люблю пьющих', 'Пью за компанию по праздникам', 'Частенько выпиваю'
        ],

        car_values_male: [
            'Не выбрано', 'Скажу позже', 'Есть', 'Нет'
        ],
        car_values_female: [
            'Не выбрано', 'Скажу позже', 'Есть', 'Нет'
        ],

        nature_values_male: [
            'Не выбрано', 'Скажу позже', 'Легкий',
            'Тяжелый', 'Строгий', 'Мягкий', 'Спокойный',
            'Беспокойный', 'Никто не жаловался'
        ],
        nature_values_female: [
            'Не выбрано', 'Скажу позже', 'Легкий',
            'Тяжелый', 'Строгий', 'Мягкий', 'Спокойный',
            'Беспокойный', 'Никто не жаловался'
        ],
    },

    'notifications': {
        'guest': {
            'title': "Новый гость",
            'unknown': "Кто-то",
            'male': "{0} сейчас смотрит вашу анкету",
            'female': "{0} сейчас смотрит вашу анкету"
        },
        'like': {
            'title': "Новый лайк",
            'unknown': "Кто-то",
            'male': "{0} только что поставил лайк на вашу анкету",
            'female': "{0} только что поставила лайк на вашу анкету"
        },
        'pair': {
            'title': "Новая пара",
            'male': "Вы и {0} только что образовали новую пару",
            'female': "Вы и {0} только что образовали новую пару"
        }
    },

    'rating': {
        low: 'низкий',
        average: 'средний',
        high: 'высокий',  
    },

    'n_kilos': "{0} кг",
    'n_sm': "{0} см",
    'name_age': "{0}, {1}",

    'blacklisted_male': '{0} заблокирован и больше не сможет написать вам',
    'blacklisted_female': '{0} заблокирована и больше не сможет написать вам',
    'unblacklisted_male': '{0} разблокирован и теперь снова сможет написать вам',
    'unblacklisted_female': '{0} разблокирована и теперь снова сможет написать вам',

    'favorite_male': '{0} добавлен в избранные',
    'favorite_female': '{0} добавлена в избранные',
    'unfavorite_male': '{0} удалён из избранных',
    'unfavorite_female': '{0} удалена из избранных',
    
    'new_msg': 'Новое сообщение',
    
    'complain_sent': 'Спасибо, жалоба на пользователя {0} отправлена',

    'user_no_name': 'Пользователь',
    'no_interests_male': '{0} пока не добавил интересы',
    'no_interests_female': '{0} пока не добавила интересы',

    'was_online_male': 'Был в сети {0}',
    'was_online_female': 'Была в сети {0}',
}