<template>
  <div class="messages" :class="{ 'empty-block-wrapper': empty }">
    <div class="pure-u-1 empty" v-if="!uid && empty">
      <div class="txt-c">
        <p>
          <img src="@/assets/img/empty/clouds-and-hearts.svg" />
        </p>
        <p>
          Здесь пока пусто. Начните общаться прямо сейчас!
          Ищите людей рядом с вами и заводите новые знакомства!
        </p>
        <router-link :to="{ name: 'Near' }" class="btn btn-red">
          Поиск
        </router-link>
      </div>
    </div>
    <CustomScrollbar v-else ref="container" :style="{ 'height': height }">
      <template v-for="(msg, i) in allMessages" :key="msg.sending_id ? msg.sending_id : msg.id">
        <div class="day-divider"
          v-if="msg.date && (i == 0 || dateToString(allMessages[i - 1].date) != dateToString(msg.date))">
          <span>{{ dateToString(msg.date) }}</span>
        </div>
        <div class="padding-h msg block-vcenter" :class="{
          'mine': msg.from_id == myId,
          'non-mine': msg.from_id != myId,
          'has-avatar': i + 1 == allMessages.length || allMessages[i + 1].from_id != msg.from_id
        }">
          <div class="avatar-wrp margin-half-r">
            <Avatar class="avatar" v-if="i + 1 == allMessages.length || allMessages[i + 1].from_id != msg.from_id"
              radius="50%" :height="100" :hidden="false"
              :thumb="avatarsStore[msg.from_id] ? avatarsStore[msg.from_id].thumbs.face_sm : null"
              :gender="usersStore[msg.from_id] ? usersStore[msg.from_id].profile.gender : 'male'"
              :loading="!avatarsStore[msg.from_id] && avatarsStore[msg.from_id] !== false" />
          </div>
          <div class="content">
            <div class="pure-g container-sm photos" v-if="msg.attachments">
              <span class="msg-info">
                <span v-if="msg.date" class="date" :title="fullDateToString(msg.date)">{{ timeToString(msg.date) }}</span>

                <span v-if="msg.from_id == myId" class="status">
                  <i v-if="msg.date && dialog && dialog.partner_read_until && new Date(dialog.partner_read_until) >= new Date(msg.date)"
                    class="icon-check-double"></i>
                  <i v-else-if="msg.date" class="icon-check"></i>
                </span>
              </span>
              <div class="pure-u-1-5" v-for="p, i in msg.attachments" :key="msg.id + '_attach_' + i">
                <div class="photo-cover" @click="messagePhotos=attachemntsToPhotos(msg.attachments); galleryItem = i"
                :style="{ 'background-image': 'url(' + p.data.thumbs.face_sm.url + ')' }"></div>
              </div>
            </div>
            <div class="text" v-if="msg.text">
              <span class="nl2br">{{ msg.text }}</span>

              <span v-if="msg.date" class="date" :title="fullDateToString(msg.date)">{{ timeToString(msg.date) }}</span>

              <span v-if="msg.from_id == myId" class="status">
                <i v-if="msg.date && dialog && dialog.partner_read_until && new Date(dialog.partner_read_until) >= new Date(msg.date)"
                  class="icon-check-double"></i>
                <i v-else-if="msg.date" class="icon-check"></i>
              </span>
            </div>
          </div>
          <div class="error" v-if="msg.err_message"><i class="icon-exclamation-circle"></i></div>
        </div>
        <div class="error error-description" v-if="'err_code' in msg && msg.err_code !== null && msg.err_code != 0">
          Сообщение не отправлено. Нажмите <a href="javascript:" class="dashed" @click="$emit('repeatMessage', msg)">сюда</a> для повторной отправки
        </div>
      </template>
    </CustomScrollbar>
    <Gallery
      v-if="galleryItem !== null"
      :photos="messagePhotos"
      :index="galleryItem"
      @close="onGalleryClosed" />
  </div>
</template>

<script>
import '@/assets/css/empty-list.css'

import moment from 'moment'
import { reactive } from "vue";

import { mapGetters } from "vuex";
import { apiCall, SERVER_ADDRESSES, apiEventTypes } from "@/utils/api";

import Avatar from '@/components/Avatar.vue'
import Gallery from '@/components/Gallery.vue'


export default {
  name: 'MessagesList',
  emits: ["repeatMessage"],
  props: {
    "uid": {
      type: String,
      default: null
    },
    "dialog": {
      default: null
    },
    "height": {
      type: String,
      required: true
    },
    "empty": {
      type: Boolean,
      default: false
    }
  },
  components: {
    Avatar, Gallery
  },
  data: function () {
    return {
      messages: reactive([]),
      sendingMessages: reactive([]),  // messages that are not sent yet
      messagePhotos: [],
      galleryItem: null
    }
  },
  computed: {
    ...mapGetters(["myId", "usersStore", "avatarsStore"]),
    allMessages() {
      return this.messages.concat(this.sendingMessages)
    }
  },
  watch: {
    uid: {
      handler() {
        this.reloadMessages()
      },
      immediate: true
    }
  },
  mounted: function () {
    moment.locale('ru')
    this.$options.sockets.onmessage = this.wsEvent
  },
  unmounted: function () {
    this.$options.sockets.onmessage = undefined
  },
  methods: {
    loadPage() {
      this.isPageLoading = true
      this.loadMessages().then(messages => {
        //prepend messages
        this.messages = messages.concat(this.messages)
        this.isPageLoading = false
        if (this.messages.length == messages.length) {
          this.scrollToBottom()
        }
      })
    },
    reloadMessages() {
      this.messages = []
      this.sendingMessages = []
      if (!this.uid) {
        return
      }
      this.$store.dispatch("LOAD_USERS", {uids: [this.uid, this.myId]})
      this.loadPage()
      this.readAllMessages()
    },
    async loadMessages() {
      let url = SERVER_ADDRESSES.dialogs + this.myId + '/' + this.uid,
        fromDate = this.messages.length > 0 ? this.messages[0].date : ""
      if (fromDate) {
        url += '?from_date=' + encodeURIComponent(fromDate)
      }
      var resp = await apiCall({ url: url, method: "GET" })
      return resp.messages
    },
    readAllMessages() {
      let url = SERVER_ADDRESSES.dialogs + this.myId + '/' + this.uid + '/read'
      apiCall({ url: url, method: "POST" })
    },
    updateMessage(msg) {
      let index = -1
      if (msg.id) {
        index = this.messages.findIndex(m => m.id == msg.id)
        if (index !== -1) {
          this.messages[index] = { ...this.messages[index], ...msg }
        }
      }

      if (msg.sending_id) {
        index = this.sendingMessages.findIndex(m => m.sending_id == msg.sending_id)
        if (!msg.date && index == -1) {  // new sending message
          this.sendingMessages.push(msg)
        } else if (!msg.date) {  // updated sending message, but still not sent
          this.sendingMessages[index] = { ...this.sendingMessages[index], msg }
        } else {  // sending message was successfully sent
          this.messages.push(msg)
          this.sendingMessages.splice(index, 1);
        }
      }
    },
    wsEvent(event) {
      let data = JSON.parse(event.data)
      if (data.from_id != this.uid) {
        return
      }
      if (data.type == apiEventTypes.CHAT_MESSAGE) {
        this.messages.push(data.data.data.message)
        this.readAllMessages()
        this.scrollToBottom()
      } else if (data.type == apiEventTypes.CHAT_MESSAGE_UPDATED) {
        this.updateMessage(data.data.data.message)
      }
    },
    scrollToBottom() {
      // next tick to render all new messages
      this.$nextTick(() => {
        this.$refs.container.scrollEl.scrollTop = this.$refs.container.scrollEl.scrollHeight
      })
    },
    dateToString(d) {
      if (moment.utc().local().format('L') == moment.utc(d).local().format('L')) {
        return 'Сегодня'
      } else if (moment.utc().local().subtract(1, 'days').format('L') == moment.utc(d).local().format('L')) {
        return 'Вчера'
      }
      return moment.utc(d).local().format('L')
    },
    timeToString(d) {
      return moment.utc(d).local().format('LT')
    },
    fullDateToString(d) {
      return moment.utc(d).local().format('L LTS')
    },
    attachemntsToPhotos(attachments) {
      return attachments.filter(a => a.type == 'photo').map(p => p.data)
    },
    onGalleryClosed() {
      this.galleryItem = null
    }
  }
}
</script>

<style scoped>
.empty img {
  margin-bottom: 2rem;
}

.messages {
  background-color: #F3F9FF;
}

.msg {
  display: flex;
  flex-direction: row;
}

.msg .content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}
.msg .content .break {
  flex-basis: 100%;
}

.msg .avatar-wrp {
  display: flex;
  min-width: 3.2rem;
  width: 3.2rem;
  margin: .25rem .5rem .25rem 0;
  align-self: flex-end;
}

.msg .avatar {
  width: 100%;
  align-self: end;
}

.msg .text {
  display: inline-block;
  align-self: flex-start;
  min-width: 0;
  padding: .95rem 1.2rem;
  margin: .25rem 0;
  border-radius: 6px;

  background-color: #FFF;
  border: 1px solid #ECECEC;
  color: #222020;
}

.msg .photos {
  position: relative;
  flex-basis: 100%;
  flex: 1 1 auto;
}

.msg .photos .photo-cover {
  cursor: pointer;
  padding-top: 125%;
  border-radius: .5rem;
  margin: .5rem 0;
}

.msg.mine .text {
  background-color: #359EFF;
  border: 1px solid #359EFF;
  color: #FFFFFF;
}

.msg.has-avatar .text {
  border-bottom-left-radius: 0;
}

.msg .date,
.msg .status {
  position: relative;
  bottom: -.15rem;
  font-size: .8rem;
}

.msg .status {
  font-size: .6rem;
  margin-left: .75rem;
}

.msg .status .icon-check {
  font-size: .8em;
}

.msg .date {
  margin-left: .75rem;
  opacity: .5;
}

.msg .photos .msg-info {
  position: absolute;
  bottom: 1rem;
  background-color: rgba(0,0,0,.33);
  color: white;
  font-size: .75rem;
  border-radius: 0.5rem;
  padding: 0.15rem 0.5rem .15rem;
  letter-spacing: normal;
  margin-left: 0.75rem;
}

.msg .photos .msg-info .date,
.msg .photos .msg-info .status {
  position: initial;
}

.msg .photos .msg-info > *:first-child {
  margin-left: 0;
}

.error {
  margin: 0 .5rem;
  font-size: 1.4rem;
  color: #FF426F;
}

.error.error-description {
  display: block;
  font-size: 1rem;
  padding-bottom: .5rem;
  padding-left: 4.5rem;
}

.error.error-description a {
  color: inherit;
}

.msg:last-of-type {
  margin-bottom: 1rem;
}

.day-divider {
  text-align: center;
}

.day-divider span {
  display: inline-block;
  background-color: rgba(0, 0, 0, .3);
  color: #FFF;
  font-size: .8rem;
  padding: .25rem .75rem;
  border-radius: 2rem;
  margin: .5rem 0;
}
</style>