<template>
  <div class="phone-container">
    <div class="phone">
      <input v-model="phoneContent" type="text" v-maska="phone_countries[phoneCountry].maska" @maska="phoneUpdated"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhoneInput',
  emits: ['update:modelValue'],
  props: {
    "modelValue": {
      type: String,
    },
  },
  data: function() {
    return {
      phone_countries: {
        RU: {default: "+7 ", maska: "+# ### ###-##-##"},
        // UA: {default: "+380 ", maska: "+### ### ###-##-##"},
      },
      phoneCountry: 'RU',
      phoneContent: '',
    }
  },
  mounted: function() {
    // TODO: пока работает только с пустым значением.
    //       Надо сделать, что если передали сразу значение какое-то,
    //       то угадать какой код страны и выбрать правильно
    this.phoneCountry = 'RU'
    this.phoneContent = this.phone_countries[this.phoneCountry].default
  },
  methods: {
    phoneUpdated: function(e) {
      const rawPhone = e.target.dataset.maskRawValue
      if (this.phoneContent.replace(this.phone_countries[this.phoneCountry].default, '')) {
        this.$emit('update:modelValue', '+' + rawPhone)
      } else {
        this.$emit('update:modelValue', '')
      }
    }
  }
}
</script>

<style scoped>
.phone-container .phone input {
  outline: none;
  background: transparent;
}
</style>