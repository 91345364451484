<template>
  <header class="pure-g max-width header">
    <div class="pure-u-1 pure-u-md-1-3 block-vcenter txt-c-xs txt-c-sm">
      <router-link :to="{name: 'Welcome'}">
        <img class="logo" alt="Kvisto logo" src="@/assets/logo.svg">
      </router-link>
    </div>
    <div class="pure-u-1 pure-u-md-2-3 block-vcenter txt-r txt-c-xs txt-c-sm">
      <button class="btn login-btn" @click="login">Войти</button>
    </div>
  </header>
</template>

<script>
import {pushModal} from "jenesius-vue-modal"

import FormLogin from '@/components/modals/FormLogin.vue'

export default {
  name: 'Header',
  methods: {
    login() {
      pushModal(FormLogin)
    }
  }
}
</script>

<style scoped>
header {
  padding: 1em 0;
}

.logo {
  height: 1.5em;
}

.btn.login-btn {
  font-weight: 600;
  color: #FF676C;
  border-color: #FFC2C4;
  background-color: #FFF;
  padding: .35em 1em;
}
</style>
