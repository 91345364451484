<template>
  <VueSelect :modelValue="city" @update:modelValue="$emit('update:modelValue', $event ? $event.id : null)"
    :options="cityList" label="name" placeholder="Укажите город" :loading="citiesLoading" :clearable="clearable"
    @search="onSearchChange" ref="citySelect">

    <template #option="{ name, administratives }">
      <div>{{ name }}</div>
      <div class="administrative">{{ administratives.map(a => a.name).join(',') }}</div>
    </template>

    <template #no-options="{ loading }">
      <div class="hint padding-half" v-show="!loading">Ничего не найдено. Введите название вашего города или выберите из
        списка</div>
    </template>

  </VueSelect>
</template>

<script>
import { apiCall, SERVER_ADDRESSES } from "@/utils/api";
import { mapGetters } from "vuex";

export default {
  name: 'CityPicker',
  emits: ['update:modelValue'],
  props: {
    "showErrors": {
      type: Boolean,
      default: false
    },
    "clearable": {
      type: Boolean,
      default: false
    },
    "modelValue": {  // city id
      type: String,
    },
  },
  data: function () {
    return {
      citiesLoading: false,
      cityList: [],
      // citySelected: null,
    }
  },
  computed: {
    ...mapGetters(["cities"]),
    city: function () {
      if (!this.modelValue) {
        return null
      }
      if (!this.cities[this.modelValue]) {
        this.$store.dispatch("LOAD_CITIES", [this.modelValue])
        return null
      }
      return this.cities[this.modelValue]
    },
  },
  mounted: function () {
    if (this.city) {
      this.searchCities(this.city.name)
    } else {
      this.searchCities('')
    }
  },
  watch: {
    city: function (city) {
      const name = city ? city.name : ''
      // this.$refs.citySelect.$el.querySelector('.vue-input input').value = name
      if (city) {
        this.searchCities(name)
      }
    },
  },
  methods: {
    onSearchChange(query) {
      this.searchCities(query)
    },
    searchCities(query) {
      this.citiesLoading = true
      apiCall({ url: SERVER_ADDRESSES.cities + "suggest?query=" + query, method: "GET" })
        .then(resp => {
          this.cityList = resp.cities
          this.citiesLoading = false
        })
        .catch(err => {
          console.log(err)
          this.citiesLoading = false
        });
    },
  }
}
</script>

<style scoped>
.city-suggest {
  display: inline-block;
  font-weight: 400;
  color: #14294380;
  font-size: .8em;
  padding: .5rem;
}

.city-suggest a,
.city-suggest a:active,
.city-suggest a:visited {
  color: #142943;
}

.administrative {
  color: #999;
  font-size: .75em;
  white-space: normal;
  line-height: 1;
}
</style>
