<template>
  <form @submit.prevent="submit">
    <button class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <h4 v-if="hasPhone">Изменить номер телефона</h4>
      <h4 v-else>Добавить номер телефона</h4>

      <div v-show="stage == 'phone'">
        <div v-if="hasPhone" class="form-field" v-bind:class="{ error: (!phone || errData.phone) && showErrors }">
          <label>Ваш текущий телефон</label>
          <PhoneInput v-model="phone" />
        </div>

        <div class="form-field" v-bind:class="{ error: (!new_phone || errData.new_phone) && showErrors }">
          <label>Новый телефон</label>
          <PhoneInput v-model="new_phone" />
        </div>

        <div class="form-field" v-bind:class="{ error: (!password || errData.password) && showErrors }">
          <label>Пароль</label>
          <PasswordInput v-model="password" />
        </div>
      </div>

      <CodeInput v-if="stage == 'code'" v-model="code" :phone="new_phone" :error="(!code || errData.code) && showErrors"
        @back="stage = 'phone'" @repeat="onRepeat()" />

      <div class="padding-half-t txt-c text-red" v-if="showErrors && errCode != 0">
        {{ errMessage }}
      </div>

      <div class="footer-modal">
        <button class="btn full-width btn-link" type="submit" :disabled="!submitEnabled">Изменить</button>
        <VueElementLoading :active="loading" />
      </div>
    </CustomScrollbar>

  </form>
</template>

<script>
import { popModal } from "jenesius-vue-modal"

import { apiCall, SERVER_ADDRESSES } from "@/utils/api"

import PhoneInput from '@/components/inputs/PhoneInput.vue'
import PasswordInput from '@/components/inputs/PasswordInput.vue'
import CodeInput from '@/components/inputs/CodeInput.vue'

import { apiErrCodes } from "@/utils/api"

export default {
  name: 'FormEditPhone',
  components: {
    PhoneInput, PasswordInput, CodeInput
  },
  emits: ['updated'],
  data: function () {
    return {
      showErrors: false,
      errCode: 0,
      errData: {},
      phone: "",
      new_phone: "",
      password: "",
      code: "",
      stage: "phone", // or "code"
      loading: false
    }
  },
  props: {
    hasPhone: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errMessage() {
      if (this.errCode == apiErrCodes.DUPLICATE_ERROR) {
        return "Указанный телефонный номер уже используется"
      } else if (this.errCode == apiErrCodes.THE_SAME_INPUT_ERROR) {
        return "Новый номер телефона совпадает с текущим"
      } else if (this.errCode == apiErrCodes.PASSWORD_ERROR) {
        return "Введен неверный пароль"
      } else if (this.errData.code && this.errCode == apiErrCodes.INVALID_VALUE_ERROR) {
        return "Введен неверный код подтверждения"
      } else if (this.errCode == apiErrCodes.INVALID_VALUE_ERROR && this.errData.phone) {
        return "Введен неверный текущий номер телефона"
      } else if (this.errCode == apiErrCodes.INVALID_VALUE_ERROR) {
        return "Введен некорректный номер или его использование недоступно"
      }
      return "Неизвестная ошибка. Попробуйте позже или обратитесь к технической поддержке."
    },
    submitEnabled() {
      if (this.stage == "code" && !this.code) {
        return false
      }
      return true
    }
  },
  methods: {
    close() {
      popModal()
    },
    onRepeat() {
      this.stage = 'phone'
      this.$nextTick(() => {
        this.submit()
      })
    },
    submit() {
      this.errCode = 0
      this.errData = {}
      this.showErrors = false
      if (!this.submitEnabled || (this.hasPhone && !this.phone) || !this.new_phone || !this.password) {
        this.showErrors = true
        return
      }

      this.loading = true
      apiCall({
        url: SERVER_ADDRESSES.auth + "update/phone", method: "POST", data: {
          phone: this.phone,
          new_phone: this.new_phone,
          password: this.password,
          code: this.code
        }
      }).then((resp) => {
        this.loading = false
        if (!resp.success || resp.err_code) {
          this.errCode = resp.err_code ?? -1
          this.errData = resp.err_data ?? {}
          this.showErrors = true
        } else if (!this.code) {
          this.stage = 'code'
        } else {
          this.$store.dispatch("AUTH_REQUEST", {
            "grant_type": "password",
            "username": this.new_phone,
            "password": this.password
          }).then(() => {
            this.$emit('updated')
            this.close()
          }).catch(() => {
            this.errCode = -1
            this.showErrors = true
          })
        }
      }).catch((e) => {
        this.loading = false
        this.errCode = e.err_code ?? -1
        this.errData = e.err_data ?? {}
        this.showErrors = true
      })
    }
  }
}
</script>


<style scoped>
form {
  width: 30rem;
}

h4 {
  margin: 0 0 1.5rem;
  font-weight: 500;
  color: #222020;
  font-size: 1.5rem;
}

.form-field {
  margin: .5em 0;
}

label {
  display: block;
  position: relative;
  font-weight: 600;
  font-size: .8em;
  margin-bottom: .5em;
  color: #222020;
  background-color: #fff;
  top: 1em;
  left: .5em;
  padding: 0 .5em;
  z-index: 1;
}

.form-field label,
.form-field .hint {
  display: inline-block;
  margin: .5em;
}

.modal-container .footer-modal {
  margin-top: 2rem;
}

a {
  font-weight: 500;
}
</style>