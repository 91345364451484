<template>
  <VDropdown placement="left-start">
    <slot />

    <template #popper="{ hide }">
      <button v-if="!isFavorite" class="btn btn-context-menu" @click="setFavorite(true); hide()">Добавить в
        избранные</button>
      <button v-else class="btn btn-context-menu" @click="setFavorite(false); hide()">Удалить из избранных</button>

      <button v-if="isBlacklisted" class="btn btn-context-menu"
        @click="setBlacklisted(false); hide()">Разблокировать</button>
      <button v-else class="btn btn-context-menu" @click="setBlacklisted(true); hide()">Заблокировать</button>

      <MenuComplain :uid="uid" placement="right-start">
        <button class="btn btn-context-menu">Пожаловаться</button>
      </MenuComplain>

      <slot name="other-buttons" :hide="hide"></slot>
    </template>
  </VDropdown>
</template>

<script>
import { withProps } from 'mosha-vue-toastify'

const format = require('string-format')
import LOCALIZATIONS from "@/localizations/ru"
import { mapGetters } from "vuex";

import Toast from '@/components/Toast.vue'
import MenuComplain from '@/components/MenuComplain.vue'

export default {
  name: 'MenuProfile',
  components: {
    MenuComplain
  },
  props: {
    "uid": {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["usersStore", "avatarsStore"]),
    gender() {
      return this.uid in this.usersStore ? this.usersStore[this.uid].profile.gender : 'male'
    },
    isBlacklisted() {
      return this.uid in this.usersStore ? this.usersStore[this.uid].relations.is_blacklisted : false
    },
    isFavorite() {
      return this.uid in this.usersStore ? this.usersStore[this.uid].relations.is_favorite : false
    }
  },
  methods: {
    setBlacklisted(isBlacklisted) {
      let key = (isBlacklisted ? '' : 'un') + 'blacklisted_' + this.gender
      this.createToast(LOCALIZATIONS[key])

      this.$store.dispatch("SET_USER_BLACKLISTED", {
        uid: this.uid,
        isBlacklisted: isBlacklisted
      })

      this.$emit('blacklisted', isBlacklisted)
    },
    async setFavorite(isFavorite) {
      let key = (isFavorite ? '' : 'un') + 'favorite_' + this.gender
      this.createToast(LOCALIZATIONS[key])

      await this.$store.dispatch("SET_USER_FAVORITE", {
        uid: this.uid,
        isFavorite: isFavorite
      })

      this.$emit('favorited', isFavorite)

    },
    createToast(text) {
      let user = this.uid in this.usersStore ? this.usersStore[this.uid] : null,
        avatar = this.uid in this.avatarsStore ? this.avatarsStore[this.uid] : ''
      this.$moshaToast(withProps(Toast, {
        icon: avatar,
        // name: user ? user.profile.name : '',
        // age: user ? user.profile.age : '',
        gender: user ? user.profile.gender : 'male',
        text: format(text, '<b>' + (user ? user.profile.name : '') + '</b>')
      }), {
        position: 'top-right',
        hideProgressBar: true,
        transition: 'slide',
      })
    }
  }
}
</script>
