<template>
  <div class="welcome-modal">
    <button class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <h4 class="txt-c no-margin-t">Приветствуем в Kvisto!</h4>
      <p class="txt-c">Это место, где люди ищут другу друга с разными целями. Общайтесь культурно и не используйте сервис
        в других целях</p>
      <p class="txt-c padding-b">Рекомендуем заполнить ваш профиль и загрузить несколько своих фотографий</p>

      <div class="footer-modal">
        <div class="pure-g">
          <button class="pure-u-1-2 btn" @click="close">Не сейчас</button>
          <RouterLink :to="{ name: 'ProfileMine' }" @click="close" class="pure-u-1-2 btn btn-link">Заполнить профиль
          </RouterLink>
        </div>
      </div>
    </CustomScrollbar>

  </div>
</template>

<script>
import { popModal } from "jenesius-vue-modal"

export default {
  name: 'WelcomeModal',
  methods: {
    close() {
      popModal()
    },
  }
}
</script>


<style scoped>
.welcome-modal {
  width: 30rem;
}

h4 {
  font-weight: 600;
  font-size: 1.3rem;
}

/* md+ */
@media screen and (min-width: 48em) {
  p {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}
</style>