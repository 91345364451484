<template>
  <form class="login-form" @submit.prevent="login">
    <button class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <h4>Вход в Kvisto</h4>
      <div class="form-field" v-bind:class="{ error: !phone && showErrors }">
        <label>Ваш телефон</label>
        <PhoneInput v-model="phone" />
      </div>
      <div class="form-field" v-bind:class="{ error: !password && showErrors }">
        <label>Пароль</label>
        <PasswordInput v-model="password" />
        <div class="txt-r margin-half-t">
          <a class="smaller text-red line-inverse" href="#" @click.prevent="resetPassword()">Забыли пароль?</a>
        </div>
      </div>

      <div class="padding-b txt-c text-red" v-if="showErrors && loginErrCode != 0">
        {{ loginErrMessage }}
      </div>

      <div class="footer-modal">
        <button class="btn full-width btn-link" type="submit" @click="login">Войти</button>
        <VueElementLoading :active="authStatus == 'loading'" />
      </div>
    </CustomScrollbar>

  </form>
</template>

<script>
import { popModal, openModal } from "jenesius-vue-modal"

import FormResetPassword from '@/components/modals/FormResetPassword.vue'

import PhoneInput from '@/components/inputs/PhoneInput.vue'
import PasswordInput from '@/components/inputs/PasswordInput.vue'

import { apiErrCodes } from "@/utils/api"
import { mapGetters } from "vuex";

export default {
  name: 'FormLogin',
  components: {
    PhoneInput, PasswordInput
  },
  data: function () {
    return {
      showErrors: false,
      loginErrCode: 0,
      phone: "",
      password: "",
    }
  },
  computed: {
    ...mapGetters(["authStatus"]),
    loginErrMessage() {
      if (this.loginErrCode == apiErrCodes.OBJECT_NOT_FOUND_ERROR) {
        return "Указан неверный номер телефона или пароль"
      }
      return "Неизвестная ошибка авторизации. Попробуйте позже или обратитесь к технической поддержке."
    }
  },
  methods: {
    close() {
      popModal()
    },
    login() {
      this.loginErrCode = 0
      this.showErrors = false
      if (!this.phone || !this.password) {
        this.showErrors = true
        return
      }
      this.$store.dispatch("AUTH_REQUEST", {
        "grant_type": "password",
        "username": this.phone,
        "password": this.password
      }).then(() => {
        this.$router.push('/users')
        this.close()
      }).catch((e) => {
        this.loginErrCode = e.err_code
        this.showErrors = true
      })
    },
    resetPassword() {
      openModal(FormResetPassword)
    }
  }
}
</script>


<style scoped>
.login-form {
  width: 30rem;
}

h4 {
  margin: 0 0 1.5rem;
  font-weight: 500;
  color: #222020;
  font-size: 1.5rem;
}

.form-field {
  margin-bottom: 1.5em;
}

label {
  display: block;
  position: relative;
  font-weight: 600;
  font-size: .8em;
  margin-bottom: .5em;
  color: #222020;
  background-color: #fff;
  top: 1em;
  left: .5em;
  padding: 0 .5em;
  z-index: 1;
}

.form-field label,
.form-field .hint {
  display: inline-block;
  margin: .5em;
}

a {
  font-weight: 500;
}
</style>