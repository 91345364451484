import {apiCall, SERVER_ADDRESSES} from "@/utils/api"


const state = {
  uid: localStorage.getItem("uid") || "",
  accessToken: localStorage.getItem("tkn") || "",
  refreshToken: localStorage.getItem("rtkn") || "",
  me: null,
  photos: null,
  avatar: null,
  events: null,
  referrers: [],
  referrersRequired: 5,  // how many invite for premium
  status: "",  // loading, success, error

  isProfileLoading: false,
  isPhotosLoading: false,
  isReferrersLoading: false,
  isEventsLoading: false,

  activeDialog: null,
};


const getters = {
  isAuthenticated: state => !!state.accessToken,
  myId: state => state.uid,
  me: state => state.me,
  myPhotos: state => state.photos,
  myAvatar: state => state.photos ? state.photos.find(p => p.is_avatar) : null,
  myEvents: state => state.events,
  myReferrers: state => state.referrers,  // uids
  referrersRequired: state => state.referrersRequired,
  authStatus: state => state.status,

  activeDialog: state => state.activeDialog  // uid of the active dialog
};


const actions = {
  ["AUTH_REQUEST"]: ({ commit, dispatch }, login_data) => {
    return new Promise((resolve, reject) => {
      commit("AUTH_REQUEST");
      apiCall({ url: SERVER_ADDRESSES.auth + "token", data: login_data, method: "POST" })
        .then(resp => {
          if (!resp.access_token) {
            commit("AUTH_LOGOUT")
            commit("AUTH_ERROR")
            localStorage.removeItem("tkn")
            localStorage.removeItem("rtkn")
            localStorage.removeItem("uid")
            reject(resp);
            return
          }
          localStorage.setItem("tkn", resp.access_token)
          localStorage.setItem("rtkn", resp.refresh_token)
          localStorage.setItem("uid", resp.uid)
          commit("AUTH_SUCCESS", resp)
          dispatch("REQUEST_MY_PROFILE")
          dispatch("REQUEST_MY_EVENTS")
          resolve(resp)
        })
        .catch(err => {
          commit("AUTH_LOGOUT")
          commit("AUTH_ERROR")
          localStorage.removeItem("tkn")
          localStorage.removeItem("rtkn")
          localStorage.removeItem("uid")
          reject(err)
        });
    });
  },
  ["AUTH_LOGOUT"]: ({ commit }) => {
    return new Promise(resolve => {
      commit("AUTH_LOGOUT");
      localStorage.removeItem("uid")
      localStorage.removeItem("tkn")
      localStorage.removeItem("rtkn")
      resolve();
    });
  },

  ["REQUEST_MY_PROFILE"]: ({ commit, state }) => {
    return new Promise(() => {
      if (state.isProfileLoading) {
        return
      }
      state.isProfileLoading = true
      var allScopes = [
        "base", "profile", "relation", "search", "privacy", "notifications"
      ]
      apiCall({ url: SERVER_ADDRESSES.users + state.uid + "?scopes=" + allScopes.join(','), method: "GET" })
        .then(resp => {
          commit("REQUEST_MY_PROFILE", resp.user);
        })
    });
  },
  ["REQUEST_MY_PHOTOS"]: ({ commit, state }) => {
    return new Promise(() => {
      if (state.isPhotosLoading) {
        return
      }
      state.isPhotosLoading = true
      apiCall({ url: SERVER_ADDRESSES.photos + state.uid, method: "GET" })
        .then(resp => {
          commit("REQUEST_MY_PHOTOS", resp.photos);
        })
    });
  },
  ["REQUEST_MY_EVENTS"]: ({ commit, state }) => {
    return new Promise(() => {
      if (state.isEventsLoading) {
        return
      }
      state.isEventsLoading = true
      apiCall({ url: SERVER_ADDRESSES.users + state.uid + "/events", method: "GET" })
        .then(resp => {
          commit("REQUEST_MY_EVENTS", resp.unread);
        })
    });
  },
  ["REQUEST_MY_REFERRERS"]: ({ commit, state, dispatch }) => {
    return new Promise(() => {
      if (state.isReferrersLoading) {
        return
      }
      state.isReferrersLoading = true
      apiCall({ url: SERVER_ADDRESSES.users + state.uid + '/referrers', method: "GET" })
        .then(resp => {
          commit("REQUEST_MY_REFERRERS", resp.uids)
          dispatch("LOAD_USERS", {uids: resp.uids})
        })
    });
  },
  ["INCREMENT_MY_EVENT"]: ({ state }, {type, value}) => {
    return new Promise(() => {
      if (state.isEventsLoading || !(type in state.events)) {
        return
      }
      if (!value) {
        value = 1
      }
      state.events[type] += value
    });
  },
  ["DECREMENT_MY_EVENT"]: ({ state }, {type, value}) => {
    return new Promise(() => {
      if (state.isEventsLoading || !(type in state.events)) {
        return
      }
      if (!value) {
        value = 1
      }
      state.events[type] -= value
      if (state.events[type] < 0) {
        state.events[type] = 0
      }
    });
  },

  ["UPDATE_PROFILE"]: ({ state, commit, dispatch }, data) => {
    return new Promise(() => {
      commit("UPDATE_PROFILE", data);
      apiCall({ url: SERVER_ADDRESSES.users + state.uid + '/update/profile', method: "POST", data: data })
        .then(() => {
          dispatch("REQUEST_MY_PROFILE");
        })
    });
  },

  ["UPDATE_PROFILE_PROPERTIES"]: ({ state, commit, dispatch }, data) => {
    return new Promise(() => {
      commit("UPDATE_PROFILE_PROPERTIES", data);
      apiCall({ url: SERVER_ADDRESSES.users + state.uid + '/update/properties', method: "POST", data: data })
        .then(() => {
          dispatch("REQUEST_MY_PROFILE");
        })
    });
  },

  ["UPDATE_PRIVACY"]: ({ state, commit, dispatch }, {data}) => {
    return new Promise(() => {
      const isHidePhotosChanged = 'hide_photos' in data && state.me.privacy.hide_photos != data.hide_photos
      commit("UPDATE_PRIVACY", data);
      apiCall({ url: SERVER_ADDRESSES.users + state.uid + "/update/privacy", method: "POST", data: data }).then(() => {
        dispatch("REQUEST_MY_PROFILE");
        if (isHidePhotosChanged) {
          dispatch("REQUEST_MY_PHOTOS")
          dispatch("LOAD_TOP_USERS", {force: true})
        }
      })
    });
  },

  ["SET_ACTIVE_DIALOG"]: ({ state }, uid) => {
    return new Promise(() => {
      state.activeDialog = uid
    });
  },
};


const mutations = {
  ["AUTH_REQUEST"]: state => {
    state.status = "loading";
  },
  ["AUTH_SUCCESS"]: (state, resp) => {
    state.status = "success";
    state.uid = resp.uid;
    state.accessToken = resp.access_token;
    state.refreshToken = resp.refresh_token;
  },
  ["AUTH_ERROR"]: state => {
    state.status = "error";
  },
  ["AUTH_LOGOUT"]: state => {
    state.uid = "";
    state.accessToken = "";
    state.refreshToken = "";
    state.status = ""

    state.isPhotosLoading = false
    state.isProfileLoading = false
  },

  ["REQUEST_MY_PROFILE"]: (state, user) => {
    state.me = user;
    state.isProfileLoading = false
  },
  ["REQUEST_MY_PHOTOS"]: (state, photos) => {
    state.photos = photos;
    state.isPhotosLoading = false
  },
  ["REQUEST_MY_REFERRERS"]: (state, uids) => {
    state.referrers = uids;
    state.isReferrersLoading = false
  },
  ["REQUEST_MY_EVENTS"]: (state, unread) => {
    state.events = unread;
    state.isEventsLoading = false
  },

  ["UPDATE_PROFILE"]: (state, data) => {
    state.me.profile = {...state.me.profile, ...data}
  },
  ["UPDATE_PROFILE_PROPERTIES"]: (state, data) => {
    state.me.profile.properties = {...state.me.profile.properties, ...data}
  },
  ["UPDATE_PRIVACY"]: (state, data) => {
    state.me.privacy = {...state.me.privacy, ...data}
  },
};


export default {
  state,
  getters,
  actions,
  mutations
};