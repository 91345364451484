<template>
  <div>
    <VueElementLoading :active="isLoading" color="#BBB" background-color="#FFF" />

    <PageTitle title="Настройки"></PageTitle>

    <div class="settings-block">
      <h3>Уведомления</h3>
      <div class="property flex-row">
        <div class="flex-expand">
          <strong>Лайки</strong>
          <span>Кто-то поставил вам лайк</span>
        </div>
        <Toggle class="margin-l" v-model="notifications.new_like" @change="updateNotifications" />
      </div>

      <div class="property flex-row">
        <div class="flex-expand">
          <strong>Новые пары</strong>
          <span>Кто-то поставил вам взаимный лайк</span>
        </div>
        <Toggle class="margin-l" v-model="notifications.new_match" @change="updateNotifications" />
      </div>

      <div class="property flex-row">
        <div class="flex-expand">
          <strong>Сообщения</strong>
          <span>Кто-то написал вам</span>
        </div>
        <Toggle class="margin-l" v-model="notifications.new_message" @change="updateNotifications" />
      </div>
    </div>

    <hr />
    <div class="settings-block">
      <h3>Конфиденциальность</h3>
      <div class="property flex-row">
        <div class="flex-expand">
          <strong>Скрыть фотографии</strong>
          <span>Ваши фотографии смогут увидеть только те, кому вы напишите сообщение или поставите лайк</span>
        </div>
        <Toggle class="margin-l" v-model="privacy.hide_photos" @change="updatePrivacy" />
      </div>

      <div class="property flex-row">
        <div class="flex-expand">
          <strong>Невидимый гость</strong>
          <span>Тот человек, чей профиль вы смотрели, не увидит вас в списке "Кто смотрел меня"</span>
        </div>
        <Toggle class="margin-l" v-model="privacy.hide_me_in_guests" @change="updatePrivacy" />
      </div>

      <div class="property flex-row">
        <div class="flex-expand">
          <strong>Оффлайн-статус</strong>
          <span>Не показывать другим, что вы находитесь онлайн</span>
        </div>
        <Toggle class="margin-l" v-model="privacy.hide_online" @change="updatePrivacy" />
      </div>
    </div>

    <hr />
    <div class="settings-block">
      <h3>Сообщения</h3>
      <div class="property flex-row">
        <div class="flex-expand">
          <strong>От парней</strong>
        </div>
        <Toggle class="margin-l" v-model="receiveMsgMale" @change="receiveMsgMaleChanged" />
      </div>

      <div class="property flex-row">
        <div class="flex-expand">
          <strong>От девушек</strong>
        </div>
        <Toggle class="margin-l" v-model="receiveMsgFemale" @change="receiveMsgFemaleChanged" />
      </div>

      <div class="property">
        <div class="flex-row">
          <strong class="flex-expand">В возрасте</strong>
          <span class="ages">{{ receiveMsgAge[0] }} - {{ receiveMsgAge[1] }}</span>
        </div>
        <div class="slider">
          <Slider v-model="receiveMsgAge" :min="minAge" :max="maxAge" :lazy="false" @update="ageChanging"
            @change="ageChanged" :options="sliderOptions" :tooltips="false" />
        </div>
        <span>Вам смогут писать сообщения только эти люди и только из этого диапазона возрастов</span>
      </div>

      <div class="property flex-row">
        <div class="flex-expand">
          <strong>Общаться только при взаимном лайке</strong>
          <span>Вы будете получать сообщения только от тех пользователей, с которыми вы образовали пару</span>
        </div>
        <Toggle class="margin-l" v-model="privacy.receive_msg_only_pair" @change="updatePrivacy" />
      </div>
    </div>

    <hr />
    <div class="settings-block">
      <h3 class="margin-half-b">Личные данные</h3>

      <div class="property flex-row no-margin">
        <div class="flex-expand">
          <strong>Адрес электронной почты</strong>
        </div>
        <a v-if="this.myAuths.email" @click.prevent="editEmail" class="btn btn-white">{{ this.myAuths.email }}&nbsp;<i
            class="icon-pencil smaller"></i></a>
        <a v-else @click.prevent="editEmail" class="btn btn-link">
          <i class="icon-plus smaller margin-half-r"></i>Добавить
        </a>
      </div>

      <div class="property flex-row no-margin">
        <div class="flex-expand">
          <strong>Номер телефона</strong>
        </div>
        <a v-if="this.myAuths.phone" @click.prevent="editPhone" class="btn btn-white small">{{ this.myAuths.phone
        }}&nbsp;<i class="icon-pencil smaller"></i></a>
        <a v-else @click.prevent="editPhone" class="btn btn-link">
          <i class="icon-plus smaller margin-half-r"></i>Добавить
        </a>
      </div>

      <div class="property flex-row no-margin">
        <div class="flex-expand">
          <strong>Пароль</strong>
        </div>
        <a @click.prevent="editPassword" class="btn btn-white small">********&nbsp;<i class="icon-pencil smaller"></i></a>
      </div>
    </div>

    <hr />
    <div class="settings-block">
      <h3 class="margin-half-b">Юридическая информация</h3>

      <div><a class="btn btn-white" href="#">Условия обслуживания</a></div>
      <div><a class="btn btn-white" href="#">Политика конфиденциальности</a></div>
      <div><a class="btn btn-white" href="#">Файлы Cookie</a></div>
    </div>

    <hr />
    <div class="settings-block">
      <h3 class="margin-half-b">Помощь и поддержка</h3>

      <div><a class="btn btn-white" href="#">Помощь с аккаунтом</a></div>
      <div><a class="btn btn-white" href="#">Написать нам</a></div>
    </div>

    <hr />
    <div class="settings-block padding-h margin-b">
      <div><button class="btn btn-white" @click="logout">Удалить анкету</button></div>
      <div><button class="btn btn-white" @click="logout">Выход</button></div>
    </div>
  </div>
</template>

<script>
import { pushModal } from "jenesius-vue-modal"
import { withProps } from 'mosha-vue-toastify'

import { apiCall, SERVER_ADDRESSES } from "@/utils/api";
import { mapGetters } from "vuex";

import PageTitle from '@/components/PageTitle.vue'
import LogoutModal from '@/components/modals/LogoutModal.vue'
import FormEditPhone from '@/components/modals/FormEditPhone.vue'
import FormEditEmail from '@/components/modals/FormEditEmail.vue'
import FormEditPassword from '@/components/modals/FormEditPassword.vue'
import ConfirmAnything from '@/components/modals/ConfirmAnything.vue'
import Toast from '@/components/Toast.vue'

import Toggle from '@vueform/toggle'
import '@vueform/toggle/themes/default.css'

import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css'

export default {
  name: 'Settings',
  components: {
    PageTitle, Toggle, Slider
  },
  data: function () {
    return {
      sliderOptions: {
        margin: 4
      },
      isLoading: true,

      notifications: {
        new_like: true,
        new_pair: true,
        new_message: true
      },
      privacy: {
        hide_photos: false,
        hide_me_in_guests: false,
        hide_online: true,
        receive_msg_genders: "male,female",
        receive_msg_only_pair: false,
        receive_msg_age_from: 18,
        receive_msg_age_to: 70
      },

      receiveMsgMale: true,
      receiveMsgFemale: true,

      minAge: 18,
      maxAge: 70,
      receiveMsgAge: [18, 70],

      myAuths: {}
    }
  },
  computed: {
    ...mapGetters(["myId", "me", "topUsersStore"]),
    isMeInTopUsers() {
      return this.topUsersStore.length && this.topUsersStore.indexOf(this.myId) !== -1
    }
  },
  watch: {
    me: function () {
      this.loadDefaults()
    }
  },
  created: function () {
    this.loadDefaults()
    this.loadAuthsList()
  },
  methods: {
    loadDefaults() {
      if (!this.me) {
        return
      }
      this.notifications = { ...this.me.notifications }
      this.privacy = { ...this.me.privacy }
      this.privacy.receive_msg_genders = this.me.privacy.receive_msg_genders.join(',')
      this.receiveMsgAge = [this.me.privacy.receive_msg_age_from, this.me.privacy.receive_msg_age_to]
      this.receiveMsgMale = this.me.privacy.receive_msg_genders.indexOf("male") !== -1
      this.receiveMsgFemale = this.me.privacy.receive_msg_genders.indexOf("female") !== -1

      this.isLoading = false
    },
    receiveMsgMaleChanged() {
      if (!this.receiveMsgMale && !this.receiveMsgFemale) {
        this.receiveMsgFemale = true
      }
      this.receiveMsgGenderChanged()
    },
    receiveMsgFemaleChanged() {
      if (!this.receiveMsgMale && !this.receiveMsgFemale) {
        this.receiveMsgMale = true
      }
      this.receiveMsgGenderChanged()
    },
    receiveMsgGenderChanged() {
      let genders = []
      if (this.receiveMsgMale) {
        genders.push("male")
      }
      if (this.receiveMsgFemale) {
        genders.push("female")
      }
      this.privacy.receive_msg_genders = genders.join(',')
      this.updatePrivacy()
    },
    ageChanging(newAges) {
      this.privacy.receive_msg_age_from = newAges[0]
      this.privacy.receive_msg_age_to = newAges[1]
    },
    ageChanged() {
      this.updatePrivacy()
    },
    loadAuthsList() {
      apiCall({ url: SERVER_ADDRESSES.auth + "list", method: "GET" }).then((resp) => {
        this.myAuths = resp
      })
    },
    async updatePrivacy() {
      if (!this.me) {
        return
      }

      if (this.isMeInTopUsers && this.privacy.hide_photos) {
        this.privacy.hide_photos = false
        const modal = await pushModal(ConfirmAnything, {
          title: "Вы уверены?",
          text: "<b>Внимание!</b> Вы оплатили поднятие анкеты и сейчас находитесь в ТОПе. Если вы скроете свои фотографии, то мы не сможем показывать вас там.",
          confirmText: "Скрыть",
          denyText: "Отмена"
        })
        modal.on('confirmed', () => {
          this.privacy.hide_photos = true
          this.$store.dispatch("UPDATE_PRIVACY", {data: this.privacy})
        })
        return
      }

      this.$store.dispatch("UPDATE_PRIVACY", {data: this.privacy})
    },
    updateNotifications() {
      if (!this.me) {
        return
      }
      apiCall({ url: SERVER_ADDRESSES.users + this.me.id + "/update/notifications", method: "POST", data: this.notifications }).then(() => {
        this.$store.dispatch("REQUEST_MY_PROFILE")
      })
    },
    async editPhone() {
      let modal = await pushModal(FormEditPhone, {
        hasPhone: !!this.myAuths.phone
      })
      modal.on('updated', () => {
        this.loadAuthsList()
        this.createToast("Система", "Номер телефона успешно изменен.")
      })
    },
    async editEmail() {
      let modal = await pushModal(FormEditEmail, {
        hasEmail: !!this.myAuths.email
      })
      modal.on('updated', () => {
        this.loadAuthsList()
        this.createToast("Система", "E-mail успешно изменен.")
      })
    },
    async editPassword() {
      let modal = await pushModal(FormEditPassword, {
        hasPassword: !!this.myAuths.email || !!this.myAuths.phone
      })
      modal.on('updated', () => {
        this.createToast("Система", "Пароль успешно изменен.")
      })
    },
    createToast(title, text) {
      this.$moshaToast(withProps(Toast, {
        title: title,
        text: text
      }), {
        position: 'top-right',
        hideProgressBar: true,
        transition: 'slide',
      })
    },
    logout() {
      pushModal(LogoutModal)
    }
  }
}
</script>


<style scoped>
.settings-block {
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 40rem;
}

h3 {
  margin: 1rem 0 1.5rem;
}

h3.margin-half-b {
  margin-bottom: .75rem;
}

.property {
  margin: 1rem 0;
  align-items: center;
}

.property strong {
  display: block;
  font-weight: 500;
  font-size: 1rem;
  color: #222020;
}

.property span {
  display: block;
  color: #908f8f;
  margin: .25rem 0;
  font-weight: 400;
  font-size: .8rem;
}

.property .ages {
  font-size: 1rem;
  color: #222020;
  font-weight: 500;
}

.slider {
  margin: 1.5rem .5rem;
  font-size: 1.33rem;
}
</style>