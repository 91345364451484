<template>
  <div class="page-title flex-row block-vcenter">
    <slot name="before"></slot>
    <div class="flex-expand">
      <slot name="title" :title="title">
        <h2>{{ title }}</h2>
      </slot>
    </div>
    <slot name="after"></slot>
  </div>
</template>

<script>

export default {
  name: 'PageTitle',
  props: {
    "title": {
      type: String,
      default: null
    }
  },
}
</script>

<style scoped>
.page-title {
  border-bottom: 1px solid #ECECEC;
  padding: 0 1rem;
}

h2 {
  margin: 0;
  padding: 1.2rem 0 1rem;
}
</style>