import { createRouter, createWebHistory } from 'vue-router'
import Welcome from '../views/Welcome.vue'
import Referrer from '../views/Referrer.vue'
import Terms from '../views/Terms.vue'
import Privacy from '../views/Privacy.vue'

import Profile from '../views/Profile.vue'
import ProfileMine from '../views/ProfileMine.vue'
import Near from '../views/Near.vue'
import Favorites from '../views/Favorites.vue'
import Guests from '../views/Guests.vue'
import Likes from '../views/Likes.vue'
import Pairs from '../views/Pairs.vue'
import Game from '../views/Game.vue'
import Settings from '../views/Settings.vue'
import Rating from '../views/Rating.vue'
import Messages from '../views/Messages.vue'

import store from "../store";

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next({
    name: 'Near',
    replace: true
  })
};

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/invite/:referrer',
    props: true,
    name: 'Referrer',
    component: Referrer,
  },

  {
    path: '/users',
    props: true,
    meta: {title: "Поиск", requiresAuth: true},
    name: "Near",
    component: Near,
  },

  {
    path: '/favorites',
    props: true,
    meta: {title: "Избранные", requiresAuth: true},
    name: "Favorites",
    component: Favorites,
  },

  {
    path: '/guests',
    props: true,
    meta: {title: "Гости", requiresAuth: true},
    name: "Guests",
    component: Guests,
  },

  {
    path: '/likes',
    props: true,
    meta: {title: "Кому я нравлюсь", requiresAuth: true},
    name: "Likes",
    component: Likes,
  },

  {
    path: '/pairs',
    props: true,
    meta: {title: "Мои пары", requiresAuth: true},
    name: "Pairs",
    component: Pairs,
  },

  {
    path: '/users/me',
    props: true,
    meta: {title: "Мой профиль", requiresAuth: true},
    name: "ProfileMine",
    component: ProfileMine,
  },

  {
    path: '/users/:uid',
    props: true,
    meta: {title: "Профиль", requiresAuth: true},
    name: "Profile",
    component: Profile,
  },

  {
    path: '/messages/:uid?',
    props: true,
    meta: {title: "Сообщения", requiresAuth: true, disableRightAside: true},
    name: "Messages",
    component: Messages,
  },

  {
    path: '/game',
    props: true,
    meta: {title: "Игра", requiresAuth: true},
    name: "Game",
    component: Game,
  },
  {
    path: '/settings',
    props: true,
    meta: {title: "Настройки", requiresAuth: true},
    name: "Settings",
    component: Settings,
  },
  {
    path: '/rating',
    props: true,
    meta: {title: "Рейтинг", requiresAuth: true},
    name: "Rating",
    component: Rating,
  },

  // {
  //   path: '/terms',
  //   name: 'Terms',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to /*, from */) => {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: '/',
      // save the location we were at to come back later
      //query: { redirect: to.fullPath },
    }
  }
})

export default router
