<template>
  <div>
    <template v-for="(title, prop) in allProps" :key="prop">
      <div class="property" v-if="prop in properties && (properties[prop] || showEmpty)">
        <span>{{ title }}</span>
        <span :class="{'empty': !properties[prop]}">
          {{ propValue(prop, properties[prop]) }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
const format = require('string-format')

import LOCALIZATIONS from "@/localizations/ru";

export default {
  name: 'ProfileProperties',
  props: {
    "properties": {
      type: Object,
      default: null
    },
    "gender": {
      type: String,
      default: "male"
    },
    "showEmpty": {
      type: Boolean,
      default: false
    }
  },
  computed: {
    allProps: function() {
      return LOCALIZATIONS.properties.names
    }
  },
  data: function() {
    return {
      minHeight: 120,
      minWeight: 35
    }
  },
  methods: {
    propValue(prop, val) {
      if (prop == 'height' && val > 1) {
        return format(LOCALIZATIONS.n_sm, this.minHeight + val - 2)
      }
      if (prop == 'weight' && val > 1) {
        return format(LOCALIZATIONS.n_kilos, this.minWeight + val - 2)
      }
      return LOCALIZATIONS.properties[prop + "_values_" + this.gender][val]
    },
  }
}
</script>

<style scoped>
.property {
  display: flex;
  flex-direction: row;
  font-size: 1.1em;
  margin: .75em 0;
}
.property > span {
  display: inline-block;
}
.property > span:first-of-type,
.property > span.empty {
  color: #908f8f;
}
.property > span:last-of-type {
  flex: 1 1 auto;
  text-align: right;
  margin-left: .5rem;
}
</style>
