<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'Referrer',
  props: {
    "referrer": {
      type: String,
      required: true
    }
  },
  watch: {
    referrer: {
      handler(value) {
        localStorage.setItem("ref", value)
        this.$router.push('/')
      },
      immediate: true
    }
  },
}
</script>
