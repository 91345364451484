<template>
  <div class="add-interest" ref="container">
    <VueSelect
      class="grey-input" 
      v-model="interest"
      :options="interests"
      :clearable="false"
      label="name"
      placeholder="Начните вводить интерес"
      search-placeholder="Начните вводить интерес"
      :loading="interestsLoading"
      append-to-body
      :calculate-position="dropdownPosition"
      @search="onSearchChange"
      @option:selected="onSelected"
      ref="interestSelect">

      <template #option="{ name }">
        <div>{{ name }}</div>
      </template>

      <template #no-options="{ loading }">
        <div class="hint padding-half" v-show="!loading">Ничего не найдено. Начните вводить интерес или выберите из списка</div>
      </template>

      <template #search="{ attributes, events }">
      <div class="flex-row block-vcenter full-width">
        <i class="icon-search"></i>
        <input class="vs__search flex-expand" v-bind="attributes" v-on="events" />
      </div>
      </template>
    </VueSelect>
  </div>
</template>

<script>
import {apiCall, SERVER_ADDRESSES} from "@/utils/api";
import { createPopper } from '@popperjs/core'


export default {
  name: 'InterestPicker',
  props: {
    "uid": {
      type: String
    },
    "placement": {
      type: String,
      default: "bottom"
    }
  },
  data: function() {
    return {
      interestsLoading: false,
      interests: [],
      defaultInterests: [],
      interest: null
    }
  },
  mounted: function() {
    this.searchInterests('')
  },
  methods: {
    onSearchChange(query) {
      this.searchInterests(query)
    },
    searchInterests(query) {
      if (query == '' && this.defaultInterests.length) {
        this.interests = [...this.defaultInterests]
        this.interestsLoading = false
        return
      }
      this.interestsLoading = true
      apiCall({ url: SERVER_ADDRESSES.interests + "suggest?query="+query, method: "GET" })
        .then(resp => {
          this.interests = [...resp.interests]
          if (query == '' && this.defaultInterests.length == 0) {
            this.defaultInterests = [...resp.interests]
          }
          this.interestsLoading = false
        })
        .catch(err => {
          console.log(err)
          this.interestsLoading = false
        });
    },
    onSelected(e) {
      this.$emit('selected', e)
      this.$nextTick(() => {
        this.interest = null
        this.searchInterests('')
        // let input = this.$refs.interestSelect.$el.querySelector('.vue-input input')
        // input.value = ''
        // input.dispatchEvent(new Event('change'));
      })
    },
    dropdownPosition(dropdownList, component, {width /*, top, left */}) {
      // default implementation:
      // dropdownList.style.top = top;
      // dropdownList.style.left = left;
      // dropdownList.style.width = width;
     
      dropdownList.style.width = width
      const popper = createPopper(this.$refs.container, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    }
  }
}
</script>

<style scoped>
.add-interest {
  position: relative;
}
.add-interest i {
  position: relative;
  top: .15rem;
  font-size: 1.1rem;
  color: #8e8d8d;
}
</style>>
