<template>
  <div class="payment-modal">
    <button class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

    <CustomScrollbar class="modal-content-wrapper" style="max-height: calc(100vh - 2rem)">
      <Swiper class="flex-row block-vcenter txt-c header-modal" direction="horizontal" slides-per-view="auto">
        <SwiperSlide style="width: 180px">
          <button class="btn" :class="{ 'active': paymentType == 'card' }" @click="paymentType = 'card'">
            Оплата картой
          </button>
        </SwiperSlide>
        <SwiperSlide style="width: 80px">
          <button class="btn" :class="{ 'active': paymentType == 'qiwi' }" @click="paymentType = 'qiwi'">
            Qiwi
          </button>
        </SwiperSlide>
        <SwiperSlide style="width: 85px">
          <button class="btn" :class="{ 'active': paymentType == 'sms' }" @click="paymentType = 'sms'">
            СМС
          </button>
        </SwiperSlide>
        <SwiperSlide style="width: 190px">
          <button class="btn" :class="{ 'active': paymentType == 'invite' }" @click="paymentType = 'invite'">
            Другие способы
          </button>
        </SwiperSlide>
      </Swiper>

      <div v-show="paymentType != 'invite'">
        <h4 class="txt-c" v-if="timeout > 0">
          <span class="timer">{{ timer }}</span>
          - На сегодня все
        </h4>

        <p class="description txt-c" v-if="timeout > 0 && type == 'limit_dialogs'">
          Вы сможете создавать новые диалоги через указанное время или подключите Premium, чтобы убрать ограничения
        </p>

        <Swiper class="slider nav-dark" ref="swiper" :modules="swiperModules" :navigation="true" :initialSlide="0"
          :slides-per-view="1" :loop="true" v-if="type != 'push_top'">

          <SwiperSlide class="slide" v-for="(adv, index) in advantages" :key="'advantage_' + index">
            <div class="pure-g container block-vcenter">
              <img class="pure-u-1-3" :src="adv.icon" />
              <p class="pure-u-2-3" v-html="adv.html"></p>
            </div>
          </SwiperSlide>

        </Swiper>
      </div>

      <PaymentModalCard class="margin-v padding-t" v-show="paymentType == 'card'" :type="type" />

      <div v-show="paymentType == 'qiwi'">
        <h4>Оплата через QIWI</h4>
      </div>
      <div v-show="paymentType == 'sms'">
        <h4>Оплата через SMS</h4>
      </div>

      <PaymentModalInvite class="margin-v padding-t" v-show="paymentType == 'invite'" />

    </CustomScrollbar>

  </div>
</template>

<script>
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';

import 'swiper/swiper.min.css';
import "swiper/modules/navigation/navigation.min.css";

import iconWoman from '@/assets/img/empty/woman1.svg'
import iconMan from '@/assets/img/empty/man1.svg'
import iconCouple from '@/assets/img/empty/couple1.svg'
import iconHearts from '@/assets/img/empty/hearts1.svg'

import { popModal } from "jenesius-vue-modal"

import PaymentModalCard from '@/components/modals/payment/PaymentModalCard.vue'
import PaymentModalInvite from '@/components/modals/payment/PaymentModalInvite.vue'

export default {
  name: 'PaymentModal',
  components: {
    Swiper, SwiperSlide,
    PaymentModalCard,
    PaymentModalInvite
  },
  props: {
    type: {  // manual, limit_swipes, limit_dialogs, push_top
      type: String,
      default: 'manual'
    },
    tabDefault: {
      type: String,
      default: 'card'
    },
    timeout: {
      type: Number,
      default: 0
    }
  },
  computed: {
    timer() {
      return new Date(this.timerSeconds * 1000).toISOString().slice(11, 19);
    }
  },
  watch: {
    tabDefault: {
      immediate: true,
      handler(tab) {
        this.paymentType = tab
      }
    }
  },
  data() {
    return {
      swiperModules: [Navigation],
      isLoading: false,
      timerSeconds: 0,
      paymentType: 'card',
      advantages: [
        {
          icon: iconWoman,
          html: 'Общайтесь <b>без ограничений</b> в созданных диалогах. Пишите новые сообщения каждый день новым людям'
        },
        {
          icon: iconMan,
          html: 'Ставьте <b>больше лайков!</b> Чем больше поставите, тем больше взаимных получите!'
        },
        {
          icon: iconCouple,
          html: 'Узнайте, <b>кто смотрел вашу анкету</b> или <b>кто поставил вам лайк</b>. Ставьте взаимные и общайтесь!'
        },
        {
          icon: iconHearts,
          html: 'Скройте себя в гостях других. <b>Никто не узнает</b>, что вы смотрели их профиль'
        },
      ]
    }
  },
  mounted() {
    if (this.timeout) {
      this.timerSeconds = this.timeout
      setInterval(this.updateTimeout, 1000)
    }
  },
  methods: {
    updateTimeout() {
      this.timerSeconds -= 1
    },
    close() {
      popModal()
    },
  }
}
</script>


<style>
.swiper .swiper-wrapper {
  align-items: center;
}

@media screen and (min-width: 35.5em) {
  .header-modal .swiper-wrapper {
    justify-content: center;
  }
}

.modal-container .modal-item .modal-content-wrapper {
  padding-top: 0;
}

:root .payment-modal {
  --swiper-navigation-size: 20px;
}
</style>

<style scoped>
.payment-modal {
  padding-top: 0;
  width: 50rem;
}

h4 {
  padding-top: 0;
  font-weight: 600;
  font-size: 1.2rem;
}

.description {
  max-width: 30rem;
  margin: 2rem auto;
}

.header-modal .btn {
  font-weight: 400;
  padding-top: 1.75rem;
  font-size: 1.2rem;
  border-radius: 0;
}

.header-modal .btn:active {
  box-shadow: none;
}

.header-modal .btn.active {
  color: #359EFF;
}

.header-modal .btn.active:after {
  content: "";
  position: absolute;
  display: block;
  left: 20%;
  right: 20%;
  bottom: -1px;
  border-bottom: 2px solid;
}

.slider .slide .container {
  max-width: 30rem;
}

.slider .slide p {
  font-weight: 400;
  font-size: 1.1rem;
  color: #1A3B5A;
}
</style>